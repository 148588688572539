import React from "react";
import "./FareRulesBar.css";
import images from "../../../../../../Utils/importImages";

function FareRulesBar({ Theme, OpenThePopup, mode, headBarMode, Data }) {
  console.log("Data is : ", Data);

  const renderPlace = (connections, index) => {
    if (connections) {
      return connections[index].Segments[0].Origin.Airport.CityName;
    }
    if(Data[0].Segments[index][0]){
      return Data[0].Segments[index][0].Origin.Airport.CityName;
    }
    return Data[0].Segments[0].Origin.Airport.CityName;
  };

  const renderDestination = (connections, index) => {
    
    if (connections) {
      return connections[index].Segments[connections[index].Segments.length - 1].Destination.Airport.CityName;
    }
    
    if(Data[0].Segments[index][Data[0].Segments[index].length - 1]){
      return Data[0].Segments[index][Data[0].Segments[index].length - 1].Destination.Airport.CityName;
    }

    return Data[0].Segments[Data[0].Segments.length - 1].Destination.Airport.CityName;
  };

  const iconType =
    Theme === "proxima" ? "forward-blue.svg" : "arrow-sand-forward.svg";
  const iconRoundType =
    Theme === "proxima"
      ? "round-exchange-icon-dark.svg"
      : "round-exchange-icon-sand-storm.svg";
  const themeClass = `${Theme}_semibold color_${
    Theme === "proxima" ? "eerie-black" : "sand-storm"
  } font16`;

  const renderHeadBar = () => {
    switch (headBarMode) {
      case 1:
      case 3:
      case 4:
      case 5:
        return (
          
          <div className={`frame-24-2-2 frame-24-8 ${themeClass}`}>
            <div className="place">{renderPlace(Data.Connections, 0)}</div>
            <img src={images[iconType]} alt="forward-blue" />
            <div className="place">
              {renderDestination(Data.Connections, 0)}
            </div>
            {headBarMode >= 3 && (
              <>
                <div className="frame-244-3">
                  <div
                    className={`text-259 ${Theme}_light font16 color_${
                      Theme === "proxima" ? "eerie-black" : "sand-storm"
                    } text-1`}
                  >
                    |
                  </div>
                </div>
                <div className="place">{renderPlace(Data.Connections, 1)}</div>
                <img src={images[iconType]} alt="forward-blue" />
                <div className="place">
                  {renderDestination(Data.Connections, 1)}
                </div>
              </>
            )}
            {headBarMode >= 4 && (
              <>
                <div className="frame-244-3">
                  <div
                    className={`text-259 ${Theme}_light font16 color_${
                      Theme === "proxima" ? "eerie-black" : "sand-storm"
                    } text-1`}
                  >
                    |
                  </div>
                </div>
                <div className="place">{renderPlace(Data.Connections, 2)}</div>
                <img src={images[iconType]} alt="forward-blue" />
                <div className="place">
                  {renderDestination(Data.Connections, 2)}
                </div>
              </>
            )}
            {headBarMode === 5 && (
              <>
                <div className="frame-244-3">
                  <div
                    className={`text-259 ${Theme}_light font16 color_${
                      Theme === "proxima" ? "eerie-black" : "sand-storm"
                    } text-1`}
                  >
                    |
                  </div>
                </div>
                <div className="place">{renderPlace(Data.Connections, 3)}</div>
                <img src={images[iconType]} alt="forward-blue" />
                <div className="place">
                  {renderDestination(Data.Connections, 3)}
                </div>
              </>
            )}
          </div>
        );
      case 2:
        return (
          <div className={`frame-24-2-2 frame-24-8 ${themeClass}`}>
            <div className="place">{renderPlace(Data.Connections, 0)}</div>
            <img src={images[iconRoundType]} alt="exchange-icon" />
            <div className="place">
              {renderDestination(Data.Connections, 0)}
            </div>
          </div>
        );
 
      default:
        return null;
    }
  };

  return (
    <div className="fare-rules-bar">
      <div className={`frame-245-3 frame-245-4 ${Theme}`}>
        <div className={`frame-243-11 ${Theme}`}>
          <div className="frame-243-12">
            <div className="frame">{renderHeadBar()}</div>
          </div>
        </div>
        <div className="frame-243-13">
          <div className="frame-243-14">
            <div className="frame-24-2 frame-24-8">
              <div
                className={`ticket-cancellation ${Theme}_regular color_deep-ocean font16`}
              >
                Ticket Cancellation &amp; Rescheduling Rules
              </div>
            </div>
            <div className="frame-250-3" onClick={() => OpenThePopup(mode)}>
              <div className={`view-fare-rules ${Theme}_semibold font16`}>
                VIEW FARE RULES
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FareRulesBar;
