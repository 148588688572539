import React, { useEffect, useState } from "react";
import "./CouponSection.css";
import images from "../../../../../../Utils/importImages";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setappliedCouponFE } from "../../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function CouponSection({ Theme , DefaultappliedCoupon , coupons}) {

  console.log("DefaultappliedCoupon is : " , DefaultappliedCoupon.Code);
  
  let dispatch  =  useDispatch();

  let [appliedCoupon, setappliedCoupon] = useState(DefaultappliedCoupon.Code);
  let [appliedCouponManual, setappliedCouponManual] = useState("");
  let [couponText, setcouponText] = useState("");
  let [error, setError] = useState(false);

  console.log("appliedCoupon is : " , appliedCoupon);
  
  useEffect(() => {
    let timeoutId;

    if (error === true) {
      // Set a timeout to dispatch the action after 3 seconds
      timeoutId = setTimeout(() => {
        setError(false);
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [error]);



  // let coupons = [
  //   {
  //     Code: "STFDEALS",
  //     Text1: "Use this code to get",
  //     Text2: "instant dicount upto ",
  //     Val: 200,
  //   },
  //   {
  //     Code: "STF-SPECIAL",
  //     Text1: "Use this code to get",
  //     Text2: "instant dicount upto ",
  //     Val: 500,
  //   },
  // ];

  return (
    <div className="coupon-section">
      <div className="frame-241-3">
        <div className="frame-242-6">
          <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
          <div className={`have-a-coupon-code ${Theme}_semibold color_eerie-black font16`}>
            HAVE A COUPON CODE ?
          </div>
        </div>
      </div>
      <div className="frame-244-8">
        <div className="frame-2-15">
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel
              htmlFor="coupon-code-ref"
              sx={{
                borderColor: Theme == "averta" && "#C2A07C !important",
                color: Theme == "averta" && "#C2A07C !important",
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontweight: "400",
              }}
            >
              Enter Coupon Code
            </InputLabel>
            <OutlinedInput
              id="coupon-code-ref"
              value={couponText}
              onChange={(e) => {
                if (appliedCouponManual === "") {
                  setcouponText(e.target.value.toLocaleUpperCase());
                }
              }}
              sx={{
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" && "#C2A07C",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    color: Theme == "averta" ? "#C2A07C !important" : "#0057B7",
                  },
              }}
              endAdornment={
                couponText.length > 0 && (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (appliedCouponManual == "") {
                        // get is the coupon present

                        let isCouponAvailable = coupons.filter(
                          (item) => item.Code == couponText
                        );
                        if (isCouponAvailable.length > 0) {
                          setappliedCouponManual(couponText);
                          setappliedCoupon(couponText);
                          dispatch(setappliedCouponFE(isCouponAvailable[0]))
                        } else {
                          setError(true);
                        }
                      } else {
                        setappliedCouponManual("");
                        setappliedCoupon("");
                        dispatch(setappliedCouponFE({Code : ""}))
                      }
                    }}
                  >
                    <div
                      className={`${Theme}_semibold color_${
                        Theme == "proxima"
                          ? appliedCouponManual == ""
                            ? "endeavour"
                            : "carmine"
                          : appliedCouponManual == ""
                          ? "choclate"
                          : "carmine"
                      }`}
                    >
                      {appliedCouponManual == "" ? "APPLY" : "REMOVE"}
                    </div>
                  </InputAdornment>
                )
              }
              label="Enter Coupon Code"
            />
          </FormControl>
          {error && (
            <div className={`${Theme}_regular color_crimson font14`}>
              The Above PromoCode is not Applicable !
            </div>
          )}
        </div>
        {coupons?.map((item, id) => {
          return (
            <div className="frame-244-9">
              {appliedCoupon == item.Code && (
                <img
                  src={images["cross-coupon.svg"]}
                  alt="no img found"
                  className="cross-img"
                  onClick={() => {
                    setappliedCoupon("");
                    setappliedCouponManual("");
                    setcouponText("");
                    dispatch(setappliedCouponFE({Code : ""}))
                  }}
                />
              )}
              <div className="frame-173-2">
                <img
                  className="coupon-selector"
                  onClick={() => {
                    dispatch(setappliedCouponFE(item))
                    setappliedCoupon(item.Code);
                    setappliedCouponManual("");
                    setcouponText("");
                  }}
                  src={
                    images[
                      appliedCoupon == item.Code
                        ? Theme == "proxima"
                          ? "selected-ff.svg"
                          : "selected-ff-gold.svg"
                        : "coupon-not-selected.svg"
                    ]
                  }
                  alt=""
                />
                <div className="frame-2-14">
                  <div className="frame-2-9">
                    <div className="frame-2-11">
                      <div className={`stintl valign-text-middle ${Theme}_semibold color_dark-purple font16`}>
                        {item.Code}
                      </div>
                    </div>
                    <div className={`frame-289 ${Theme}_regular color_eerie-black font14`}>
                      <div className="use-this-code-to-get valign-text-middle">
                        {item.Text1}
                      </div>
                      <div className="instant-discount valign-text-middle">
                        {item.Text2}
                      </div>
                      <div className="frame-173-4">
                        <img
                          src={images["small-rupee-icon.svg"]}
                          alt="Frame 169"
                        />
                        <div className={`number-13 valign-text-middle number-18 ${Theme}_regular color_eerie-black font14`}>
                          {item.Val}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`terms-conditions valign-text-middle ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "choclate"} font14`}>
                    Terms &amp; conditions
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CouponSection;
