import React from "react";
import "./TitleTooltip.css";
import images from "../../../../../Utils/importImages";

function TitleTooltip({ type, Theme }) {
  return (
    <div className="title-tooltip">
      <div className={`${Theme}_semibold color_white font14`}>
        {type == "FName" ? "First / Given Name" : "Last / Family Name"}
      </div>
      <div className={`${Theme}_regular color_white font12`}>
        Enter as per mentioned on your passport
      </div>
      <img
        src={
          images[
            type == "FName"
              ? "passport-givenname-photo.svg"
              : "passport-sirname-photo.svg"
          ]
        }
        alt=""
      />
    </div>
  );
}

export default TitleTooltip;
