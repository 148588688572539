import React, { useEffect, useState } from "react";
import "./SeatsContentSection.css";
import Plane from "./Components/Plane";
import PlaneInfoRow from "./Components/PlaneInfoRow";
import TravellerRow from "../Components/TravellerRow";
import { useDispatch, useSelector } from "react-redux";
import { getSeatInfo } from "../../../../../Utils/Flight_review_func";
import { setnextAncellaryTraveller, setnextAncellaryTravellerOnLoad } from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import ErrorComponent from "../Components/ErrorComponent";

function SeatsContentSection({ Theme, currentSelectedTab, currentSeatData}) {
  
  let dispatch = useDispatch();
  const scrollToSection = (name) => {
    const wrapper = document.querySelector("." + name);
    if (wrapper) {
      wrapper.scrollIntoView({ behavior: "smooth", block: "center" }); // You can use 'auto' instead of 'smooth' for instant scrolling
    }
  };

  let TravellersData = useSelector(
    (state) => state.ReviewPageFlight.TravellersDataFilledUp
  );

  let nextAncellaryTraveller = useSelector(
    (state) => state.ReviewPageFlight.nextAncellaryTraveller
  );

  let selectedtheTraveller = (curTraveller) => {
    dispatch(setnextAncellaryTraveller(curTraveller))
  };
  
  useEffect(() => {
    // set the current unselected traveller on load of screen 
  dispatch(setnextAncellaryTravellerOnLoad(0))
  
  } , [currentSelectedTab])

  useEffect(() => {
    scrollToSection("isActiveSeat");
  } , [nextAncellaryTraveller])

  let GetMargintop = (val) => {
    return `${50 + (val) * 17}px`;
  };

  return (
    currentSeatData.error == true ? (
      <ErrorComponent Theme={Theme} Message={currentSeatData.errorMessage.Message} Heading={currentSeatData.errorMessage.heading} type="Seat" />
    ) : (
    <div className="seats-section">
          <div className="data-section">
            <div className="travellers-content">
              {TravellersData?.map((item, id) => {

                if(item.type === "INF") return null;
                return (
                  <TravellerRow
                    onTravellerSelection={selectedtheTraveller}
                    id={id}
                    item={item}
                    RequestText={"Please Select Your Seat"}
                    isActive={nextAncellaryTraveller == id}
                    name={`${item.firstName} ${item.lastName}`}
                    Theme={Theme}
                    dd={
                      item.ancellaries.seats[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].seatRequested
                    }
                    isSelected={
                      item.ancellaries.seats[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].seatRequested !== "" &&
                      item.ancellaries.seats[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].seatRequested !== null
                    }
                    Data={(item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested !== "" &&
                    item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested !== null) == true && getSeatInfo(item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested , false)}
                    
                    price={(item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested !== "" &&
                    item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested !== null)== true ? item.ancellaries.seats[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].seatRequested.Price : 0}
                  />
                );
              })}
            </div>

            <div className="plane-content">
              {currentSeatData.SeactionTabData.InformationSection.Free
                .hasFreeSeat == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"Free Seat"}
                  price={["0"]}
                  color={"#808080"}
                />
              )}
              {currentSeatData.SeactionTabData.InformationSection.Paid
                .hasPaidSeat == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"Paid Seat"}
                  price={
                    currentSeatData.SeactionTabData.InformationSection.Paid
                      .Min !==
                    currentSeatData.SeactionTabData.InformationSection.Paid.Max
                      ? [
                          currentSeatData.SeactionTabData.InformationSection
                            .Paid.Min,
                          currentSeatData.SeactionTabData.InformationSection
                            .Paid.Max,
                        ]
                      : [
                          currentSeatData.SeactionTabData.InformationSection
                            .Paid.Max,
                        ]
                  }
                  color={"#41A318"}
                />
              )}
              {currentSeatData.SeactionTabData.InformationSection.ExtraLegRoom
                .hasExtraLegRoom == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"ExtraLegRoom Seat"}
                  price={
                    currentSeatData.SeactionTabData.InformationSection
                      .ExtraLegRoom.Min !==
                    currentSeatData.SeactionTabData.InformationSection
                      .ExtraLegRoom.Max
                      ? [
                          currentSeatData.SeactionTabData.InformationSection
                            .ExtraLegRoom.Min,
                          currentSeatData.SeactionTabData.InformationSection
                            .ExtraLegRoom.Max,
                        ]
                      : [
                          currentSeatData.SeactionTabData.InformationSection
                            .ExtraLegRoom.Max,
                        ]
                  }
                  color={"#43A9C7"}
                />
              )}
              {currentSeatData.SeactionTabData.InformationSection.ExitRow
                .hasExitRow == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"ExitRow Seat"}
                  price={
                    currentSeatData.SeactionTabData.InformationSection.ExitRow
                      .Min !==
                    currentSeatData.SeactionTabData.InformationSection.ExitRow
                      .Max
                      ? [
                          currentSeatData.SeactionTabData.InformationSection
                            .ExitRow.Min,
                          currentSeatData.SeactionTabData.InformationSection
                            .ExitRow.Max,
                        ]
                      : [
                          currentSeatData.SeactionTabData.InformationSection
                            .ExitRow.Max,
                        ]
                  }
                  color={"#D2042D"}
                />
              )}
              {currentSeatData.SeactionTabData.InformationSection.Recline
                .hasRecline == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"Recline Seat"}
                  price={
                    currentSeatData.SeactionTabData.InformationSection.Recline
                      .Min !==
                    currentSeatData.SeactionTabData.InformationSection.Recline
                      .Max
                      ? [
                          currentSeatData.SeactionTabData.InformationSection
                            .Recline.Min,
                          currentSeatData.SeactionTabData.InformationSection
                            .Recline.Max,
                        ]
                      : [
                          currentSeatData.SeactionTabData.InformationSection
                            .Recline.Max,
                        ]
                  }
                  color={"orange"}
                />
              )}
              {currentSeatData.SeactionTabData.InformationSection.NonRecline
                .hasNonRecline == true && (
                <PlaneInfoRow
                  Theme={Theme}
                  name={"NonRecline Seat"}
                  price={
                    currentSeatData.SeactionTabData.InformationSection
                      .NonRecline.Min !==
                    currentSeatData.SeactionTabData.InformationSection
                      .NonRecline.Max
                      ? [
                          currentSeatData.SeactionTabData.InformationSection
                            .NonRecline.Min,
                          currentSeatData.SeactionTabData.InformationSection
                            .NonRecline.Max,
                        ]
                      : [
                          currentSeatData.SeactionTabData.InformationSection
                            .NonRecline.Max,
                        ]
                  }
                  color={"pink"}
                />
              )}
            </div>
          </div>
          <div className={`plane-section ${Theme}`}>
            <Plane
            currentPassenger={nextAncellaryTraveller}
            onChanegCurrentPassenger={selectedtheTraveller}
              Theme={Theme}
              TopRow={currentSeatData.SeactionTabData.AlphabetIdentiFiers}
              marginTop={GetMargintop(
                currentSeatData.SeactionTabData.AlphabetIdentiFiers.length
              )}
              Seats={currentSeatData.SeactionTabData.Data}
            />
          </div>
      
    </div>
    )
  );
}

export default SeatsContentSection;
