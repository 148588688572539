import React, { useRef, useState } from "react";

import "./TopBar4.css";
import images from "../../../../../../Utils/importImages";
import {
  formatTime,
  getTotalMinutes,
} from "../../../../../../Utils/Date_Time_Calendar_Functions";
function TopBar4({setselectedSection , selectedSection , data , ResultType , settopBarSelectedd , Theme}) {

  let [scrollIndex, setScrollIndex] = useState(0);

  const elements = [
    {
      id: 0,
      name: "Trip 1",
      time: formatTime(
        getTotalMinutes(
          ResultType == 2 ? data.Connections[0].Segments[0].Origin.DepTime : data[0].Segments[0].Origin.DepTime,
          ResultType == 2 ?  data.Connections[0].Segments[data.Connections[0].Segments.length - 1].Destination.ArrTime : data[0].Segments[data[0].Segments.length - 1].Destination.ArrTime
        )
      ),
      from: ResultType == 2 ? data.Connections[0].Segments[0].Origin.Airport.CityName : data[0].Segments[0].Origin.Airport.CityName,
      stops:

      ResultType == 2 ? (data.Connections[0].Segments.length === 1 ? "Non Stop" : data.Connections[0].Segments.length - 1 + " Stops") : (data[0].Segments.length === 1 ? "Non Stop" : data[0].Segments.length - 1 + " Stops") ,

      to: ResultType == 2 ? data.Connections[0].Segments[data.Connections[0].Segments.length - 1].Destination.Airport.CityName : data[0].Segments[data[0].Segments.length - 1].Destination.Airport.CityName
    },
    {
      id: 1,
      name: "Trip 2",
      time: formatTime(
        getTotalMinutes(
          ResultType == 2 ? data.Connections[1].Segments[0].Origin.DepTime : data[1].Segments[0].Origin.DepTime,
          ResultType == 2 ?  data.Connections[1].Segments[data.Connections[1].Segments.length - 1].Destination.ArrTime : data[1].Segments[data[1].Segments.length - 1].Destination.ArrTime
        )
      ),
      from: ResultType == 2 ? data.Connections[1].Segments[0].Origin.Airport.CityName : data[1].Segments[0].Origin.Airport.CityName,
      stops:
      ResultType == 2 ? (data.Connections[1].Segments.length === 1 ? "Non Stop" : data.Connections[1].Segments.length - 1 + " Stops") : (data[1].Segments.length === 1 ? "Non Stop" : data[1].Segments.length - 1 + " Stops") ,
      to:ResultType == 2 ? data.Connections[1].Segments[data.Connections[1].Segments.length - 1].Destination.Airport.CityName : data[1].Segments[data[1].Segments.length - 1].Destination.Airport.CityName
    },
    {
      id: 2,
      name: "Trip 3",
      time: formatTime(
        getTotalMinutes(
          ResultType == 2 ? data.Connections[2].Segments[0].Origin.DepTime : data[2].Segments[0].Origin.DepTime,
          ResultType == 2 ?  data.Connections[2].Segments[data.Connections[2].Segments.length - 1].Destination.ArrTime : data[2].Segments[data[2].Segments.length - 1].Destination.ArrTime
        )
      ),
      from: ResultType == 2 ? data.Connections[2].Segments[0].Origin.Airport.CityName : data[2].Segments[0].Origin.Airport.CityName,
      stops:
      ResultType == 2 ? (data.Connections[2].Segments.length === 1 ? "Non Stop" : data.Connections[2].Segments.length - 1 + " Stops") : (data[2].Segments.length === 1 ? "Non Stop" : data[2].Segments.length - 1 + " Stops") ,
      to: ResultType == 2 ? data.Connections[2].Segments[data.Connections[2].Segments.length - 1].Destination.Airport.CityName : data[2].Segments[data[2].Segments.length - 1].Destination.Airport.CityName
    },
    {
      id: 3,
      name: "Trip 4",
      time: formatTime(
        getTotalMinutes(
          ResultType == 2 ? data.Connections[3].Segments[0].Origin.DepTime : data[3].Segments[0].Origin.DepTime,
          ResultType == 2 ?  data.Connections[3].Segments[data.Connections[3].Segments.length - 1].Destination.ArrTime : data[3].Segments[data[3].Segments.length - 1].Destination.ArrTime
        )
      ),
      from: ResultType == 2 ? data.Connections[3].Segments[0].Origin.Airport.CityName : data[3].Segments[0].Origin.Airport.CityName,
      stops:
      ResultType == 2 ? (data.Connections[3].Segments.length === 1 ? "Non Stop" : data.Connections[3].Segments.length - 1 + " Stops") : (data[3].Segments.length === 1 ? "Non Stop" : data[3].Segments.length - 1 + " Stops") ,
      to: ResultType == 2 ? data.Connections[3].Segments[data.Connections[3].Segments.length - 1].Destination.Airport.CityName : data[3].Segments[data[3].Segments.length - 1].Destination.Airport.CityName
    },

    { id: 4, name: "View Cancellation Rules", isView: true },
    { id: 5, name: "View Rescheduling Rules", isView: true },
  ];

  const containerRef = useRef(null);

  const handleForward = () => {
    console.log("scrollIndex is : ", scrollIndex);
    console.log("elements.length is : ", elements.length);
    if (scrollIndex < elements.length - 1) {
      setScrollIndex(scrollIndex + 1);
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const handleBack = () => {
    if (scrollIndex > 0) {
      setScrollIndex(scrollIndex - 1);
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="topBarParent">
        <img
          src={images["back-circle-arrow-button.svg"]}
          alt="arrow-bton"
          className="back-arrow"
          onClick={() => handleBack()}
        />
        <div className={`topBar4 frame-243-6 ${Theme}`}>
          <div className="frame-237-3">
            <div className="topBar4 frame-243-6">
              {elements && (
                <div
                  className="frame-237-3"
                  style={{ display: "flex", overflowX: "hidden" }}
                  ref={containerRef}
                >
                  {elements?.map((element, index) => (
                    <div
                      key={element.id}
                      className={`frame-179 ${
                        selectedSection === index
                          ? `bg-dark-purple ${Theme}`
                          : "bg-white"
                      }`}
                      onClick={() => {setselectedSection(index); settopBarSelectedd(index)}}
                      style={{ minWidth: "calc(100% / 4.5)" }}
                    >
                      {!element.isView ? (
                        <>
                          <div className="frame-238-5">
                            <div className="frame">
                              <div className="frame-2">
                                <div
                                  className={`flight-details ${
                                    selectedSection === index
                                      ? `${Theme}_regular color_white font11`
                                      : `${Theme}_regular color_black font11`
                                  }`}
                                >
                                  {element.name}
                                </div>
                              </div>
                            </div>
                            <div className="frame-202">
                              <img
                                src={
                                  images[
                                    selectedSection === index
                                      ? "clock-sign-white.svg"
                                      : "clock-sign-black.svg"
                                  ]
                                }
                                alt="Rectangle 72"
                              />
                              <div
                                className={`x2h-40m-1 ${
                                  selectedSection === index
                                    ? `${Theme}_regular color_white font15`
                                    : `${Theme}_regular color_eerie-black font15`
                                }`}
                              >
                                {element.time}
                              </div>
                            </div>
                          </div>
                          <div className="frame-239">
                            <div className="frame-238-6">
                              <div
                                className={`frame-2 ${
                                  selectedSection == index
                                    ? `${Theme}_regular color_white font16`
                                    : `${Theme}_regular color_eerie-black font16`
                                }`}
                              >
                                <div className="place">{element.from}</div>
                                <div className="frame-170-1 frame-170-4">
                                  <div
                                    className={
                                      selectedSection == index
                                        ? "line-between-white"
                                        : "line-between-eerie-black"
                                    }
                                  ></div>
                                  <div className="frame-202">
                                    <div
                                      className={
                                        selectedSection == index
                                          ? `address ${Theme}_regular color_white font12`
                                          : `address ${Theme}_regular color_eerie-black font12`
                                      }
                                    >
                                      {element.stops}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      selectedSection == index
                                        ? "line-between-white"
                                        : "line-between-eerie-black"
                                    }
                                  ></div>
                                </div>
                                <div className="place-1">{element.to}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={`frame-176-1 ${
                            selectedSection == index
                              ? `bg-dark-purple ${Theme}`
                              : "bg-white"
                          }`}
                          onClick={() => setselectedSection(index)}
                        >
                          <div className="frame-170-2 frame-170-4">
                            <div
                              className={`view ${
                                selectedSection == index
                                  ? `${Theme}_regular color_white font16`
                                  : `${Theme}_regular color_eerie-black font16`
                              }`}
                            >
                              {element.name}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <img
          src={images["forward-circle-arrow-button.svg"]}
          alt="arrow-bton"
          className="forward-arrow"
          onClick={() => handleForward()}
        />
      </div>
    </>
  );
}

export default TopBar4;
