import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FlightsResultPageErrorMain,
  FlightsResultPageErrorMainDescription,
} from "../../../Constants/ConstantsFlights";
import { getLocalStorageJSON, setLocalStorageJSON } from "../../../Utils/localStorageFunc";
import {
  callAncellaries,
  callContinue1,
  callContinue2,
  callProceedToPay,
  GetTheFlight,
  HoldTheFlight,
  SellTheFlightAPI,
} from "../../Apis/Flight-Apis";
import CryptoJS from "crypto-js";
import {
  convertNullsToEmptyStrings,
  getancellariesStructure,
  getBagBarData,
  getBagsDataStruc,
  getBarData,
  getMealsDataStruc,
  getSeatInformation,
  getSeatsDataStruc,
  rulesMakerTravellers,
} from "../../../Utils/Flight_review_func";
import { MAIN_PAYMENT_URL } from "../../../Configs/Urls";

// Api Callings

let isInternationalTrip = (cc1, cc2) => {
  return cc1 !== cc2;
};

let getTheResultType = (itrData) => {
  console.log("itr passsed is : ", itrData);

  // Oneway domestic = 1
  // Oneway International = 2
  // Round domestic = 3
  // Round International = 4
  // Multi domestic 2 Row= 5
  // Multi International/Hybgrid 2 Row= 6
  // Multi domestic 3 Row= 7
  // Multi International/Hybgrid 3 Row= 8
  // Multi domestic 4 Row= 9
  // Multi International/Hybgrid 4 Row= 10

  if (itrData?.length === 1) {
    let isInternational = isInternationalTrip(
      itrData[0].substring(19, 21),
      itrData[0].substring(22, 25)
    );

    if (isInternational === true) {
      return 2;
    }
    return 1;
  } else if (itrData?.length === 2) {
    console.log("ehre whrn 2");

    if (
      itrData[0].substring(0, 3) === itrData[1].substring(4, 7) &&
      itrData[0].substring(4, 7) === itrData[1].substring(0, 3)
    ) {
      let isInternational = isInternationalTrip(
        itrData[0].substring(19, 21),
        itrData[0].substring(22, 25)
      );

      if (isInternational === true) {
        return 4;
      }
      return 3;
    } else {
      let isInternational1 = isInternationalTrip(
        itrData[0].substring(19, 21),
        itrData[0].substring(22, 25)
      );
      let isInternational2 = isInternationalTrip(
        itrData[1].substring(19, 21),
        itrData[1].substring(22, 25)
      );

      if (isInternational1 === true || isInternational2 === true) {
        return 6;
      }
      return 5;
    }
  } else if (itrData?.length === 3) {
    let isInternational1 = isInternationalTrip(
      itrData[0].substring(19, 21),
      itrData[0].substring(22, 25)
    );
    let isInternational2 = isInternationalTrip(
      itrData[1].substring(19, 21),
      itrData[1].substring(22, 25)
    );
    let isInternational3 = isInternationalTrip(
      itrData[2].substring(19, 21),
      itrData[2].substring(22, 25)
    );

    if (
      isInternational1 === true ||
      isInternational2 === true ||
      isInternational3 === true
    ) {
      return 8;
    }
    return 7;
  } else if (itrData?.length === 4) {
    let isInternational1 = isInternationalTrip(
      itrData[0].substring(19, 21),
      itrData[0].substring(22, 25)
    );
    let isInternational2 = isInternationalTrip(
      itrData[1].substring(19, 21),
      itrData[1].substring(22, 25)
    );
    let isInternational3 = isInternationalTrip(
      itrData[2].substring(19, 21),
      itrData[2].substring(22, 25)
    );
    let isInternational4 = isInternationalTrip(
      itrData[3].substring(19, 21),
      itrData[3].substring(22, 25)
    );

    if (
      isInternational1 === true ||
      isInternational2 === true ||
      isInternational3 === true ||
      isInternational4 === true
    ) {
      return 10;
    }
    return 9;
  }
};

export const InitializePopup = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    if (
      state.SearchResultType == 1 ||
      state.SearchResultType == 2 ||
      state.SearchResultType == 3 ||
      state.SearchResultType == 4 ||
      state.SearchResultType == 5 ||
      state.SearchResultType == 6
    ) {
      if (state.FlightDetail[0] == undefined) {
        if (
          state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[1].SellResponse.IsPriceChanged == true
        ) {
          let OriginalPriceEarlier1 =
              state.FlightDetail.Connections[0].Fare.BaseFare +
              state.FlightDetail.Connections[0].Fare.Tax,
            OriginalPriceEarlier2 =
              state.FlightDetail.Connections[1].Fare.BaseFare +
              state.FlightDetail.Connections[1].Fare.Tax;

          if (
            state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[0].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[0].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier1 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier1 - priceChanged
                : OriginalPriceEarlier1 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[1].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[1].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[1].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier2 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier2 - priceChanged
                : OriginalPriceEarlier2 + priceChanged;
          }

          let combinedSum =
            state.FlightDetail.Connections[0].Fare.BaseFare +
            state.FlightDetail.Connections[0].Fare.Tax +
            state.FlightDetail.Connections[1].Fare.BaseFare +
            state.FlightDetail.Connections[1].Fare.Tax;

          let isPriceChanged =
            combinedSum != OriginalPriceEarlier1 + OriginalPriceEarlier2;

          let priceChanged =
            combinedSum > OriginalPriceEarlier1 + OriginalPriceEarlier2
              ? combinedSum - OriginalPriceEarlier1 + OriginalPriceEarlier2
              : OriginalPriceEarlier1 + OriginalPriceEarlier2 - combinedSum;

          let changeIdentifier =
            combinedSum < OriginalPriceEarlier1 + OriginalPriceEarlier2
              ? "Decreased"
              : "Increased";

          if (isPriceChanged == true) {
            dispatch(setOpenPopupMini(true));
            dispatch(setOpenPopupMiniType("Price_Changed"));
            dispatch(
              setOpenPopupData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
            dispatch(setisPriceChanged(true));
            dispatch(
              setPriceChangedData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
          }
        }
      } else {
        if (state.FlightDetail[0].SellResponse.IsPriceChanged == true) {
          dispatch(setOpenPopupMini(true));
          dispatch(setOpenPopupMiniType("Price_Changed"));
          dispatch(
            setOpenPopupData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
          dispatch(setisPriceChanged(true));
          dispatch(
            setPriceChangedData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
        }
      }
    } else if (state.SearchResultType == 7 || state.SearchResultType == 8) {
      if (state.FlightDetail[0] == undefined) {
        if (
          state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[1].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[2].SellResponse.IsPriceChanged == true
        ) {
          let OriginalPriceEarlier1 =
              state.FlightDetail.Connections[0].Fare.BaseFare +
              state.FlightDetail.Connections[0].Fare.Tax,
            OriginalPriceEarlier2 =
              state.FlightDetail.Connections[1].Fare.BaseFare +
              state.FlightDetail.Connections[1].Fare.Tax,
            OriginalPriceEarlier3 =
              state.FlightDetail.Connections[2].Fare.BaseFare +
              state.FlightDetail.Connections[2].Fare.Tax;

          if (
            state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[0].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[0].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier1 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier1 - priceChanged
                : OriginalPriceEarlier1 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[1].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[1].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[1].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier2 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier2 - priceChanged
                : OriginalPriceEarlier2 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[2].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[2].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[2].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier3 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier3 - priceChanged
                : OriginalPriceEarlier3 + priceChanged;
          }

          let combinedSum =
            state.FlightDetail.Connections[0].Fare.BaseFare +
            state.FlightDetail.Connections[0].Fare.Tax +
            state.FlightDetail.Connections[1].Fare.BaseFare +
            state.FlightDetail.Connections[1].Fare.Tax +
            state.FlightDetail.Connections[2].Fare.BaseFare +
            state.FlightDetail.Connections[2].Fare.Tax;

          let isPriceChanged =
            combinedSum !=
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3;

          let priceChanged =
            combinedSum >
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3
              ? combinedSum -
                OriginalPriceEarlier1 +
                OriginalPriceEarlier2 +
                OriginalPriceEarlier3
              : OriginalPriceEarlier1 +
                OriginalPriceEarlier2 +
                OriginalPriceEarlier3 -
                combinedSum;

          let changeIdentifier =
            combinedSum <
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3
              ? "Decreased"
              : "Increased";

          if (isPriceChanged == true) {
            dispatch(setOpenPopupMini(true));
            dispatch(setOpenPopupMiniType("Price_Changed"));
            dispatch(
              setOpenPopupData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
            dispatch(setisPriceChanged(true));
            dispatch(
              setPriceChangedData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
          }
        }
      } else {
        if (state.FlightDetail[0].SellResponse.IsPriceChanged == true) {
          dispatch(setOpenPopupMini(true));
          dispatch(setOpenPopupMiniType("Price_Changed"));
          dispatch(
            setOpenPopupData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
          dispatch(setisPriceChanged(true));
          dispatch(
            setPriceChangedData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
        }
      }
    } else if (state.SearchResultType == 9) {
      if (state.FlightDetail[0] == undefined) {
        if (
          state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[1].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[2].SellResponse.IsPriceChanged ==
            true ||
          state.FlightDetail.Connections[3].SellResponse.IsPriceChanged == true
        ) {
          let OriginalPriceEarlier1 =
              state.FlightDetail.Connections[0].Fare.BaseFare +
              state.FlightDetail.Connections[0].Fare.Tax,
            OriginalPriceEarlier2 =
              state.FlightDetail.Connections[1].Fare.BaseFare +
              state.FlightDetail.Connections[1].Fare.Tax,
            OriginalPriceEarlier3 =
              state.FlightDetail.Connections[2].Fare.BaseFare +
              state.FlightDetail.Connections[2].Fare.Tax,
            OriginalPriceEarlier4 =
              state.FlightDetail.Connections[3].Fare.BaseFare +
              state.FlightDetail.Connections[3].Fare.Tax;

          if (
            state.FlightDetail.Connections[0].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[0].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[0].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier1 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier1 - priceChanged
                : OriginalPriceEarlier1 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[1].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[1].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[1].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier2 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier2 - priceChanged
                : OriginalPriceEarlier2 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[2].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[2].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[2].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier3 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier3 - priceChanged
                : OriginalPriceEarlier3 + priceChanged;
          }

          if (
            state.FlightDetail.Connections[3].SellResponse.IsPriceChanged ==
            true
          ) {
            let priceChanged =
              state.FlightDetail.Connections[3].SellResponse.AmountChanged;
            let changeIdentifier =
              state.FlightDetail.Connections[3].SellResponse
                .priceChangeIdentifier;

            OriginalPriceEarlier4 =
              changeIdentifier == "Increased"
                ? OriginalPriceEarlier4 - priceChanged
                : OriginalPriceEarlier4 + priceChanged;
          }

          let combinedSum =
            state.FlightDetail.Connections[0].Fare.BaseFare +
            state.FlightDetail.Connections[0].Fare.Tax +
            state.FlightDetail.Connections[1].Fare.BaseFare +
            state.FlightDetail.Connections[1].Fare.Tax +
            state.FlightDetail.Connections[2].Fare.BaseFare +
            state.FlightDetail.Connections[2].Fare.Tax +
            state.FlightDetail.Connections[3].Fare.BaseFare +
            state.FlightDetail.Connections[3].Fare.Tax;

          let isPriceChanged =
            combinedSum !=
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3 +
              OriginalPriceEarlier4;

          let priceChanged =
            combinedSum >
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3 +
              OriginalPriceEarlier4
              ? combinedSum -
                OriginalPriceEarlier1 +
                OriginalPriceEarlier2 +
                OriginalPriceEarlier3 +
                OriginalPriceEarlier4
              : OriginalPriceEarlier1 +
                OriginalPriceEarlier2 +
                OriginalPriceEarlier3 +
                OriginalPriceEarlier4 -
                combinedSum;

          let changeIdentifier =
            combinedSum <
            OriginalPriceEarlier1 +
              OriginalPriceEarlier2 +
              OriginalPriceEarlier3 +
              OriginalPriceEarlier4
              ? "Decreased"
              : "Increased";

          if (isPriceChanged == true) {
            dispatch(setOpenPopupMini(true));
            dispatch(setOpenPopupMiniType("Price_Changed"));
            dispatch(
              setOpenPopupData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
            dispatch(setisPriceChanged(true));
            dispatch(
              setPriceChangedData({
                Message: `The Price of this Flight is ${changeIdentifier} by Rs. ${priceChanged}`,
              })
            );
          }
        }
      } else {
        if (state.FlightDetail[0].SellResponse.IsPriceChanged == true) {
          dispatch(setOpenPopupMini(true));
          dispatch(setOpenPopupMiniType("Price_Changed"));
          dispatch(
            setOpenPopupData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
          dispatch(setisPriceChanged(true));
          dispatch(
            setPriceChangedData({
              Message: `The Price of this Flight is ${state.FlightDetail[0].SellResponse.priceChangeIdentifier} by Rs. ${state.FlightDetail[0].SellResponse.AmountChanged}`,
            })
          );
        }
      }
    }
  };
};

export const SellTheFlight = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    const urlParams = new URLSearchParams(window.location.search);

    const params = {};

    let LengthOfParams = 0;
    for (const [key, value] of urlParams) {
      LengthOfParams++;
      params[key] = value;
    }

    try {
      
      // when flight is not sold yet

      if (LengthOfParams == 3) {
        if (params["dti"] == undefined) {
          throw new Error(`HTTP error! Status`);
        }

        // Decrypt the data
        const secretKey = "your-secret-key"; // Replace with your own secret key
        const ThemeData = JSON.parse(
          CryptoJS.AES.decrypt(
            decodeURIComponent(params["dti"]),
            secretKey
          ).toString(CryptoJS.enc.Utf8)
        );

        // get the  frt from the string searchkey = "?tp=ow&a=1&c=0&i=0&frc=Economy&frt=Senior%20Citizen&itr=DEL-BOM-27-09-2024-IN-IN"

        // Remove the "?" and split the query string by "&"

        // try {
        //   console.log("ThemeData.searchkey is : " , ThemeData.searchkey);
          
        //   let queryParams = ThemeData?.searchKey?.substring(1).split("&");
        //   console.log("queryParams are : " , queryParams);

        // } catch (e){
        //   console.error("the queyr erro : " , e);
          
        // }

// const urlParams = new URLSearchParams(ThemeData.searchkey);

// const params = {};
// for (const [key, value] of urlParams) {
//   params[key] = value;
// }

// console.log("params is : " ,  params);

// // Find the 'frt' parameter in the array
// let frtParam = queryParams.find(param => param.startsWith("frt="));

// // Extract the value of 'frt' and decode the URI component
// let frtValue = decodeURIComponent(frtParam.split("=")[1]);

// dispatch(setfareType(frtValue))

        dispatch(setsearchKey(ThemeData.searchkey));
        dispatch(setTheme(ThemeData.Theme));
        dispatch(setitrCode(ThemeData.itrCode));

        dispatch(
          setSearchResultType(getTheResultType(ThemeData.itrCode.split("|")))
        );

        if (
          params["itr"] == undefined ||
          params["sri"] == undefined ||
          params["dti"] == undefined
        ) {
          throw new Error(`HTTP error! Status`);
        }

        dispatch(setReviewPageLoadingPercentage("30%"));

        // Call Sell Api

        try {
          let soldParams = {
            ItenaryID: params["itr"],
            SearchId: params["sri"],
          };

          let soldData = await SellTheFlightAPI(soldParams);
          console.log("soldData is : ", soldData);

          const secretKey = "your-secret-key"; // Replace with your own secret key
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(ThemeData),
            secretKey
          ).toString();

          window.location.replace(
            `/flights/review-details?itr=${soldData.data.itr}&sri=${
              soldData.data.srid
            }&bki=${soldData.data.bid}&dti=${encodeURIComponent(encryptedData)}`
          );
        } catch (error) {
          console.log("The Cathed error is : ", error);

          dispatch(setReviewPageLoadingPercentage("100%"));
          dispatch(setReviewPageLoading("idle"));

          dispatch(setReviewPageError(true));
          dispatch(
            setReviewPageErrroName(
              error?.response?.data?.code == undefined
                ? FlightsResultPageErrorMain.IVL
                : FlightsResultPageErrorMain[error?.response?.data?.code]
            )
          );
          dispatch(
            setReviewPageErrorDiscription(
              error?.response?.data?.code == undefined
                ? FlightsResultPageErrorMainDescription.IVLD
                : FlightsResultPageErrorMainDescription[
                    `${error?.response?.data?.code}D`
                  ]
            )
          );

          dispatch(setisResearchQuery(true));
          dispatch(setResearchQueryURL(`/flights/results${state.searchKey}`));
        }
      }
      // when flight is sold
      else if (LengthOfParams == 4) {

        if (params["dti"] == undefined) {
          throw new Error(`HTTP error! Status`);
        }

        const secretKey = "your-secret-key"; // Replace with your own secret key
        const ThemeData = JSON.parse(
          CryptoJS.AES.decrypt(
            decodeURIComponent(params["dti"]),
            secretKey
          ).toString(CryptoJS.enc.Utf8)
        );

        console.log("ThemeData is : ", ThemeData);

        

        // Get the value of 'frt'
// Remove the "?" and split the query string by "&"
// let queryParams = ThemeData.searchKey.substring(1).split("&");
// console.log("queryParams are : " , queryParams);


// const urlParams = new URLSearchParams(ThemeData.searchkey);

// const params = {};
// for (const [key, value] of urlParams) {
//   params[key] = value;
// }

// console.log("params is : " ,  params);


        // dispatch(setfareType(frtValue))

        
        const urlParamss = new URLSearchParams(ThemeData.searchkey);

        const paramss = {};
        for (const [key, value] of urlParamss) {
          paramss[key] = value;
        }
        
        console.log("paramss is : " ,  paramss);

        dispatch(setfareType(paramss.frt));
        dispatch(setsearchKey(ThemeData.searchkey));
        dispatch(setTheme(ThemeData.Theme));
        dispatch(setitrCode(ThemeData.itrCode));

        console.log(
          "when calling : ",
          getTheResultType(ThemeData.itrCode.split("|"))
        );

        dispatch(
          setSearchResultType(getTheResultType(ThemeData.itrCode.split("|")))
        );

        if (
          params["itr"] == undefined ||
          params["sri"] == undefined ||
          params["bki"] == undefined ||
          params["dti"] == undefined
        ) {
          throw new Error(`HTTP error! Status`);
        }

        dispatch(setReviewPageLoadingPercentage("60%"));

        // get the Booking

        console.log("here to get the booking derauls ");

        try {
          let flightData = await GetTheFlight(params["bki"]);

          dispatch(setFlightData(flightData.data));
          dispatch(setFlightDetail(JSON.parse(flightData.data.flight_data)));
          dispatch(
            setTravellersData(JSON.parse(flightData.data.traveller_data))
          );
          dispatch(
            setappliedCouponFE(
              flightData.data.coupons_and_discounts_used
                ? JSON.parse(flightData.data.coupons_and_discounts_used)
                : {
                    Code: "",
                  }
            )
          );

          dispatch(setReviewPageLoadingPercentage("100%"));
          dispatch(setReviewPageLoading("idle"));
          console.log("Data of flight is : ", flightData);
        } catch (error) {
          console.log("The errior here is : ", error);

          dispatch(setReviewPageLoadingPercentage("100%"));
          dispatch(setReviewPageLoading("idle"));

          dispatch(setReviewPageError(true));
          dispatch(
            setReviewPageErrroName(
              error?.response?.data?.code == undefined
                ? FlightsResultPageErrorMain.IVL
                : FlightsResultPageErrorMain[error?.response?.data?.code]
            )
          );
          dispatch(
            setReviewPageErrorDiscription(
              error?.response?.data?.code == undefined
                ? FlightsResultPageErrorMainDescription.IVLD
                : FlightsResultPageErrorMainDescription[
                    `${error?.response?.data?.code}D`
                  ]
            )
          );

          dispatch(setisResearchQuery(true));
          dispatch(setResearchQueryURL(`/flights/results${state.searchKey}`));
        }
      } else {
        throw new Error(`HTTP error! Status`);
      }
    } catch (e) {
      dispatch(setReviewPageLoadingPercentage("100%"));
      dispatch(setReviewPageLoading("idle"));

      dispatch(setReviewPageError(true));
      dispatch(setReviewPageErrroName(FlightsResultPageErrorMain.IVL));
      dispatch(
        setReviewPageErrorDiscription(
          FlightsResultPageErrorMainDescription.IVLD
        )
      );

      dispatch(setisResearchQuery(true));
      dispatch(setResearchQueryURL(`/flights/results${state.searchKey}`));
    }
  };
};

export const FareUpgrader = (Data, FFSelected, Theme) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    let apiParams = {};

    console.log("the dtaa w3e pased is  : ", Data);

    let itenaryId = state.FlightData.itenary_id;
    let search_id = state.FlightData.search_id;

    dispatch(setupgradeLoading(true));
    if (state.SearchResultType === 1 || state.SearchResultType === 2) {
      switch (Data.DataFormat) {
        case "DOM-OW":
        case "DOM-OW-NR":
        case "INT-OW":
        case "INT-OW-NR":
        case "INT-OW-VIA":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [Data.FareFamilies.FFList[FFSelected[0]]],
          };

          console.log("apiParams is : ", apiParams);

          break;

        case "DOM-OW-CA":
        case "INT-OW-CA":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
            ],
          };

          break;
      }
    } else if (state.SearchResultType === 3) {
      apiParams = {
        SearchId: search_id,
        Mode: "up",
        itenaryId: itenaryId,
        FareFamilyChoosed: [
          Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
          Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
        ],
      };
    } else if (state.SearchResultType === 4) {
      switch (Data.DataFormat) {
        case "INT-RD":
        case "INT-RD-NR":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [Data.FareFamilies.FFList[FFSelected[0]]],
          };
          break;

        case "INT-RD-OWOW":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
            ],
          };
          break;

        default:
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
            ],
          };

          break;
      }
    } else if (state.SearchResultType === 5) {
      apiParams = {
        SearchId: search_id,
        Mode: "up",
        itenaryId: itenaryId,
        FareFamilyChoosed: [
          Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
          Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
        ],
      };
    } else if (state.SearchResultType === 7) {
      apiParams = {
        SearchId: search_id,
        Mode: "up",
        itenaryId: itenaryId,
        FareFamilyChoosed: [
          Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
          Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
          Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
        ],
      };
    } else if (state.SearchResultType === 9) {
      apiParams = {
        SearchId: search_id,
        Mode: "up",
        itenaryId: itenaryId,
        FareFamilyChoosed: [
          Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
          Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
          Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
          Data.Connections[3].FareFamilies.FFList[FFSelected[3]],
        ],
      };
    } else if (state.SearchResultType === 6) {
      switch (Data.DataFormat) {
        case "INT-ML-2" || "HYB-ML-2":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [Data.FareFamilies.FFList[FFSelected[0]]],
          };
          break;

        case "INT-ML-OWOW-2" || "HYB-ML-OWOW-2":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
            ],
          };
          break;
        default:
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
            ],
          };

          break;
      }
    } else if (state.SearchResultType === 8) {
      switch (Data.DataFormat) {
        case "INT-ML-3" || "HYB-ML-3":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [Data.FareFamilies.FFList[FFSelected[0]]],
          };
          break;

        case "INT-ML-OWOW-3" || "HYB-ML-OWOW-3":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
            ],
          };
          break;

        default:
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
            ],
          };

          break;
      }
    } else if (state.SearchResultType === 10) {
      switch (Data.DataFormat) {
        case "INT-ML-4" || "HYB-ML-4":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [Data.FareFamilies.FFList[FFSelected[0]]],
          };
          break;

        case "INT-ML-OWOW-4" || "HYB-ML-OWOW-4":
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
              Data.Connections[3].FareFamilies.FFList[FFSelected[3]],
            ],
          };
          break;

        default:
          apiParams = {
            SearchId: search_id,
            Mode: "up",
            itenaryId: itenaryId,
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]],
              Data.Connections[3].FareFamilies.FFList[FFSelected[3]],
            ],
          };

          break;
      }
    }

    try {
      let HoldParas = await HoldTheFlight(apiParams);

      dispatch(setReviewPageLoading("loading"));
      dispatch(setReviewPageLoadingPercentage("0%"));

      console.log("HoldParas is : ", HoldParas);
      const urlParams = new URLSearchParams(window.location.search);

      const params = {};
      let LengthOfParams = 0;
      for (const [key, value] of urlParams) {
        LengthOfParams++;
        params[key] = value;
      }

      if (params["dti"] == undefined) {
        throw new Error(`HTTP error! Status`);
      }

      // Decrypt the data
      const secretKey = "your-secret-key"; // Replace with your own secret key
      const ThemeData = JSON.parse(
        CryptoJS.AES.decrypt(
          decodeURIComponent(params["dti"]),
          secretKey
        ).toString(CryptoJS.enc.Utf8)
      );

        // Use URLSearchParams to parse the query string
     
        // Remove the "?" and split the query string by "&"
        // let queryParams = ThemeData.searchKey.substring(1).split("&");
        // console.log("queryParams are : " , queryParams);

        // dispatch(setfareType(frtValue))

        const urlParamss = new URLSearchParams(ThemeData.searchkey);

        const paramss = {};
        for (const [key, value] of urlParamss) {
          paramss[key] = value;
        }
        
        console.log("paramss is : " ,  paramss);

        dispatch(setfareType(paramss.frt));
      dispatch(setsearchKey(ThemeData.searchkey));
      dispatch(setTheme(ThemeData.Theme));
      dispatch(setitrCode(ThemeData.itrCode));

      dispatch(
        setSearchResultType(getTheResultType(ThemeData.itrCode.split("|")))
      );

      if (
        params["itr"] == undefined ||
        params["sri"] == undefined ||
        params["dti"] == undefined
      ) {
        throw new Error(`HTTP error! Status`);
      }

      dispatch(setReviewPageLoadingPercentage("30%"));

      // Call Sell Api

      try {
        let soldParams = {
          ItenaryID: params["itr"],
          SearchId: params["sri"],
        };

        let soldData = await SellTheFlightAPI(soldParams);
        console.log("soldData is : ", soldData);

        const secretKey = "your-secret-key"; // Replace with your own secret key
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(ThemeData),
          secretKey
        ).toString();

        window.location.replace(
          `/flights/review-details?itr=${soldData.data.itr}&sri=${
            soldData.data.srid
          }&bki=${soldData.data.bid}&dti=${encodeURIComponent(encryptedData)}`
        );
      } catch (error) {
        console.log("The Cathed error is : ", error);

        dispatch(setReviewPageLoadingPercentage("100%"));
        dispatch(setReviewPageLoading("idle"));

        dispatch(setReviewPageError(true));
        dispatch(
          setReviewPageErrroName(
            error?.response?.data?.code == undefined
              ? FlightsResultPageErrorMain.IVL
              : FlightsResultPageErrorMain[error?.response?.data?.code]
          )
        );
        dispatch(
          setReviewPageErrorDiscription(
            error?.response?.data?.code == undefined
              ? FlightsResultPageErrorMainDescription.IVLD
              : FlightsResultPageErrorMainDescription[
                  `${error?.response?.data?.code}D`
                ]
          )
        );

        dispatch(setisResearchQuery(true));
        dispatch(setResearchQueryURL(`/flights/results${state.searchKey}`));
      }
    } catch (e) {
      console.log("the erorr at the hold upgrade is  : ", e);

      dispatch(setupgradeLoading(false));
      dispatch(setupgradeLoadingError(true));
    }

    console.log("the api params are  : ", apiParams);
  };
};

export const SetTheTravellersInitial = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    // set the rules for traveller forms

    let Rules = {};

    if (state?.SearchResultType === 1 || state?.SearchResultType === 2) {
      Rules = await rulesMakerTravellers(
        state?.FlightDetail?.Connections == undefined
          ? state?.FlightDetail
          : state?.FlightDetail?.Connections,
        state?.FlightDetail?.Connections == undefined ? 1 : 2
      );
    } else if (state?.SearchResultType === 3) {
      Rules = await rulesMakerTravellers(state?.FlightDetail?.Connections, 2);
    } else if (state?.SearchResultType === 4) {
      Rules = await rulesMakerTravellers(
        state?.FlightDetail?.Connections == undefined
          ? state?.FlightDetail
          : state?.FlightDetail?.Connections,
        state?.FlightDetail?.Connections == undefined ? 1 : 2
      );
    } else if (state?.SearchResultType === 5) {
      Rules = await rulesMakerTravellers(state?.FlightDetail?.Connections, 2);
    } else if (state?.SearchResultType === 7) {
      Rules = await rulesMakerTravellers(state?.FlightDetail?.Connections, 3);
    } else if (state?.SearchResultType === 9) {
      Rules = await rulesMakerTravellers(state?.FlightDetail?.Connections, 4);
    } else if (state?.SearchResultType === 6) {
      Rules = await rulesMakerTravellers(
        state?.FlightDetail?.Connections == undefined
          ? state?.FlightDetail
          : state?.FlightDetail?.Connections,
        state?.FlightDetail?.Connections == undefined ? 1 : 2
      );
    } else if (state?.SearchResultType === 8) {
      Rules = await rulesMakerTravellers(
        state?.FlightDetail?.Connections == undefined
          ? state?.FlightDetail
          : state?.FlightDetail?.Connections,
        state?.FlightDetail?.Connections == undefined ? 1 : 3
      );
    } else if (state?.SearchResultType === 10) {
      Rules = await rulesMakerTravellers(
        state?.FlightDetail?.Connections == undefined
          ? state?.FlightDetail
          : state?.FlightDetail?.Connections,
        state?.FlightDetail?.Connections == undefined ? 1 : 4
      );
    }

    dispatch(setfirstNameLimit(Rules.fnl));
    dispatch(setlastNameLimit(Rules.lnl));
    dispatch(setairlineNameFormat(Rules.anf));
    dispatch(setIsPassportRequired(Rules.pr));

    // set the traveller structure

    // if( state.TravellersData.saved or state.TravellersData.registered) are there then prefill the travellers automatically
    // else create the structure along with ancellaries structure as null values   ( later svae it as saved key in backend)

    if (
      state.TravellersData.saved !== undefined ||
      state.TravellersData.registered
    ) {
      if (state.TravellersData.saved !== undefined) {
        const cleanedData = convertNullsToEmptyStrings(
          state.TravellersData.saved
        );

        dispatch(setTravellersDataFilledUp(cleanedData));

        let GstName =
          cleanedData[0].gstDetails.GSTCompanyName == null
            ? ""
            : cleanedData[0].gstDetails.GSTCompanyName;
        let GstNumber =
          cleanedData[0].gstDetails.GSTNumber == null
            ? ""
            : cleanedData[0].gstDetails.GSTNumber;

        //  console.log("GstName is : " , GstName , " , GstNumber is : " , GstNumber);

        dispatch(setgstAvailable(GstName !== "" || GstNumber !== ""));
        dispatch(setgstCompany(GstName));
        dispatch(setgstNumber(GstNumber));

        let ancellaryPrices = {
          seat : 0,
          meal : 0,
          bag : 0,
        }

        // update the ancellaries prices if there 
        if(state.TravellersData.ancellariesCodes.Seats.length !== 0){
          state.TravellersData.ancellariesCodes.Seats.forEach(element => {
            ancellaryPrices.seat += element.Price
          });
        }
        if(state.TravellersData.ancellariesCodes.Meals.length !== 0){
          state.TravellersData.ancellariesCodes.Meals.forEach(element => {
            ancellaryPrices.meal += element.Price
          });
        }
        if(state.TravellersData.ancellariesCodes.Bags.length !== 0){
          state.TravellersData.ancellariesCodes.Bags.forEach(element => {
            ancellaryPrices.bag += element.Price
          });
        }
        
        dispatch(setAncellariesPriceBulk({seat : ancellaryPrices.seat, meal :  ancellaryPrices.meal , bag : ancellaryPrices.bag }));

        dispatch(setselectedAncellaries({name : "Seats" , value : state.TravellersData.ancellariesCodes.Seats}))
        dispatch(setselectedAncellaries({name : "Meals" , value : state.TravellersData.ancellariesCodes.Meals}))
        dispatch(setselectedAncellaries({name : "Bags" , value : state.TravellersData.ancellariesCodes.Bags}))

      } else if (state.TravellersData.registered !== undefined) {

        const cleanedData = convertNullsToEmptyStrings(
          state.TravellersData.registered
        );

        dispatch(setTravellersDataFilledUp(cleanedData));

        let GstName =
          cleanedData[0].gstDetails.GSTCompanyName == null
            ? ""
            : cleanedData[0].gstDetails.GSTCompanyName;
        let GstNumber =
          cleanedData[0].gstDetails.GSTNumber == null
            ? ""
            : cleanedData[0].gstDetails.GSTNumber;

        console.log("GstName is : ", GstName, " , GstNumber is : ", GstNumber);

        dispatch(setgstAvailable(GstName !== "" || GstNumber !== ""));
        dispatch(setgstCompany(GstName));
        dispatch(setgstNumber(GstNumber));

        let ancellaryPrices = {
          seat : 0,
          meal : 0,
          bag : 0,
        }
        // update the ancellaries prices if there 
        if(state.TravellersData.ancellariesCodes.Seats.length !== 0){
          state.TravellersData.ancellariesCodes.Seats.forEach(element => {
            ancellaryPrices.seat += element.Price
          });
        }
        if(state.TravellersData.ancellariesCodes.Meals.length !== 0){
          state.TravellersData.ancellariesCodes.Meals.forEach(element => {
            ancellaryPrices.meal += element.Price
          });
        }
        if(state.TravellersData.ancellariesCodes.Bags.length !== 0){
          state.TravellersData.ancellariesCodes.Bags.forEach(element => {
            ancellaryPrices.bag += element.Price
          });
        }
        
        dispatch(setAncellariesPriceBulk({seat : ancellaryPrices.seat, meal :  ancellaryPrices.meal , bag : ancellaryPrices.bag }));

        dispatch(setselectedAncellaries({name : "Seats" , value : state.TravellersData.ancellariesCodes.Seats}))
        dispatch(setselectedAncellaries({name : "Meals" , value : state.TravellersData.ancellariesCodes.Meals}))
        dispatch(setselectedAncellaries({name : "Bags" , value : state.TravellersData.ancellariesCodes.Bags}))
      }
    } else {

      let travellersStructure = [];

      let ancellariesStructrure = {};
      if (state?.SearchResultType === 1 || state?.SearchResultType === 2) {
        
        ancellariesStructrure = await getancellariesStructure(
          state?.FlightDetail?.Connections == undefined ? 1 : 2,
          
            state?.FlightDetail?.Connections == undefined
              ? [state?.FlightDetail[0].Segments.length]
              : [state?.FlightDetail?.Connections[0].Segments.length,
                state?.FlightDetail?.Connections[1].Segments.length]
          
        );
      } else if (state?.SearchResultType === 3) {

        ancellariesStructrure = await getancellariesStructure(2, [
          state?.FlightDetail?.Connections[0].Segments.length,
          state?.FlightDetail?.Connections[1].Segments.length,
        ]);

      } else if (state?.SearchResultType === 4) {
        ancellariesStructrure = await getancellariesStructure(2,
          state?.FlightDetail?.Connections == undefined
            ? [state?.FlightDetail[0].Segments[0].length,
              state?.FlightDetail[0].Segments[1].length]
            : [state?.FlightDetail?.Connections[0].Segments.length,
              state?.FlightDetail?.Connections[1].Segments.length]
        );
      } else if (state?.SearchResultType === 5) {
        ancellariesStructrure = await getancellariesStructure(2, [
          state?.FlightDetail?.Connections[0].Segments.length,
          state?.FlightDetail?.Connections[1].Segments.length,
        ]);
      } else if (state?.SearchResultType === 7) {
        ancellariesStructrure = await getancellariesStructure(3, [
          state?.FlightDetail?.Connections[0].Segments.length,
          state?.FlightDetail?.Connections[1].Segments.length,
          state?.FlightDetail?.Connections[2].Segments.length,
        ]);
      } else if (state?.SearchResultType === 9) {
        ancellariesStructrure = await getancellariesStructure(4, [
          state?.FlightDetail?.Connections[0].Segments.length,
          state?.FlightDetail?.Connections[1].Segments.length,
          state?.FlightDetail?.Connections[2].Segments.length,
          state?.FlightDetail?.Connections[3].Segments.length,
        ]);
      } else if (state?.SearchResultType === 6) {
        ancellariesStructrure = await getancellariesStructure(2, 
          state?.FlightDetail?.Connections == undefined
            ? [(state?.FlightDetail[0].Segments[0].length,
              state?.FlightDetail[0].Segments[1].length)]
            : [(state?.FlightDetail?.Connections[0].Segments.length,
              state?.FlightDetail?.Connections[1].Segments.length)]
        );
      } else if (state?.SearchResultType === 8) {
        ancellariesStructrure = await getancellariesStructure(3, 
          state?.FlightDetail?.Connections == undefined
            ? [state?.FlightDetail[0].Segments[0].length,
              state?.FlightDetail[0].Segments[1].length,
              state?.FlightDetail[0].Segments[2].length]
            : [state?.FlightDetail?.Connections[0].Segments.length,
              state?.FlightDetail?.Connections[1].Segments.length,
              state?.FlightDetail?.Connections[2].Segments.length]
        );
      } else if (state?.SearchResultType === 10) {
        ancellariesStructrure = await getancellariesStructure(4, 
          state?.FlightDetail?.Connections == undefined
            ? [state?.FlightDetail[0].Segments[0].length,
              state?.FlightDetail[0].Segments[1].length,
              state?.FlightDetail[0].Segments[2].length,
              state?.FlightDetail[0].Segments[3].length]
            : [state?.FlightDetail?.Connections[0].Segments.length,
              state?.FlightDetail?.Connections[1].Segments.length,
              state?.FlightDetail?.Connections[2].Segments.length,
              state?.FlightDetail?.Connections[3].Segments.length]
        );
      }

      let noOfAdults = state.TravellersData.main.Adults;
      let noOfChilds = state.TravellersData.main.Childs;
      let noOfInfants = state.TravellersData.main.Infrants;

      if (noOfAdults > 0) {
        for (let i = 0; i < noOfAdults; i++) {
          travellersStructure.push({
            type: "ADT",
            title: "",
            firstName: "",
            lastName: "",
            dob: "Date-Month-Year",
            gender: "",
            city: "",
            countryCode: "",
            countryName: "",
            nationality: "",
            ancellaries: ancellariesStructrure,
            ancellariesCodes : {
              Seats : [],
              Meals : [],
              Bags : [],
            },
            gstDetails: {
              GSTCompanyName: "",
              GSTNumber: "",
            },
            passportDetails: {
              PassportNo: "",
              PassportExpiry: "Date-Month-Year",
              PassportIssueDate: "Date-Month-Year",
              PassportIssuingCountry: "",
            },
            frequentFlyerDetails: {
              available: false,
              airline: "",
              number: "",
            },
            wheelChairRequested: false,
          });
        }
      }
      if (noOfChilds > 0) {
        for (let i = 0; i < noOfChilds; i++) {
          travellersStructure.push({
            type: "CHD",
            title: "",
            firstName: "",
            lastName: "",
            dob: "Date-Month-Year",
            gender: "",
            city: "",
            countryCode: "",
            countryName: "",
            nationality: "",
            ancellaries: ancellariesStructrure,
            ancellariesCodes : {
              Seats : [],
              Meals : [],
              Bags : [],
            },
            gstDetails: {
              CompanyAddress: "",
              CompanyContactNumber: "",
              GSTCompanyName: "",
              GSTNumber: "",
              GSTCompanyEmail: "",
            },

            passportDetails: {
              PassportNo: "",
              PassportExpiry: "Date-Month-Year",
              PassportIssueDate: "Date-Month-Year",
              PassportIssuingCountry: "",
            },
            frequentFlyerDetails: {
              available: false,
              airline: "",
              number: "",
            },
            wheelChairRequested: false,
          });
        }
      }
      if (noOfInfants > 0) {
        for (let i = 0; i < noOfInfants; i++) {
          travellersStructure.push({
            type: "INF",
            title: "",
            firstName: "",
            lastName: "",
            dob: "Date-Month-Year",
            gender: "",
            city: "",
            countryCode: "",
            countryName: "",
            nationality: "",
            ancellaries: ancellariesStructrure,
            ancellariesCodes : {
              Seats : [],
              Meals : [],
              Bags : [],
            },
            gstDetails: {
              CompanyAddress: "",
              CompanyContactNumber: "",
              GSTCompanyName: "",
              GSTNumber: "",
              GSTCompanyEmail: "",
            },
            passportDetails: {
              PassportNo: "",
              PassportExpiry: "Date-Month-Year",
              PassportIssueDate: "Date-Month-Year",
              PassportIssuingCountry: "",
            },
            frequentFlyerDetails: {
              available: false,
              airline: "",
              number: "",
            },
            wheelChairRequested: false,
          });
        }
      }

      dispatch(setTravellersDataFilledUp(travellersStructure));

      let GstName =
        travellersStructure[0].gstDetails.GSTCompanyName == null
          ? ""
          : travellersStructure[0].gstDetails.GSTCompanyName;
      let GstNumber =
        travellersStructure[0].gstDetails.GSTNumber == null
          ? ""
          : travellersStructure[0].gstDetails.GSTNumber;
      console.log("GstName is : ", GstName, " , GstNumber is : ", GstNumber);

      dispatch(setgstAvailable(GstName !== "" || GstNumber !== ""));
      dispatch(setgstCompany(GstName));
      dispatch(setgstNumber(GstNumber));
    }

    // set default / entered mail and phone

    let phoneno =
      state.FlightData.main_traveller_phone == null
        ? ""
        : state.FlightData.main_traveller_phone;
    if (phoneno !== "") {
      dispatch(setmainDialCode(phoneno.split("-")[0]));
      dispatch(setmainPhone(phoneno.split("-")[1]));
    }

    dispatch(
      setmainMail(
        state.FlightData.main_traveller_mail == null
          ? ""
          : state.FlightData.main_traveller_mail
      )
    );
  };
};

export const getMainAncellariesStructure = (state , AncellaryData) => {

  try {

    if (state?.SearchResultType === 1 || state?.SearchResultType === 2) {
      
      if (state?.FlightDetail?.Connections == undefined) {
       
        // normal flight

        let headingVals = ["Oneway"];
        
        let BarData = getBarData(state?.FlightDetail , 1 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);

        // Bags Settings 
        let BagsBarData = [];
    
        let bagAvailableForAdd = [];

        let i = 0;

        console.log("AncellaryData here is : " , AncellaryData);
        
        AncellaryData.forEach(element => {

          BagsBarData[i] = getBagBarData(state.FlightDetail , 1 , 1 , headingVals)

          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
            bagAvailableForAdd[i] = true
          } else {
            bagAvailableForAdd[i] = false
          }
          i++;
          
        });

        console.log("BagsBarData is : " , BagsBarData);
        
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData,
       
        }

      } else {

        //conencttions flight ow

        let headingVals = ["Oneway Connection 1", "Oneway Connection 2"];
        
        let BarData = getBarData(state?.FlightDetail.Connections , 2 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);

        let BagsBarData = [];
        let bagAvailableForAdd = [];

        console.log("BarData here is : " , BarData);
  
        let i = 0;

        AncellaryData.forEach(element => {

          BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
      
            bagAvailableForAdd[i] = true
     
          }  else {

            bagAvailableForAdd[i] = false

          }
          i++;
          
        });
        
        console.log("BagsBarData here is : " , BagsBarData);
        // Bags Settings
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      }

    } else if (state?.SearchResultType === 3) {

      let headingVals = ["Onwards", "Return"];
        
      let BarData = getBarData(state?.FlightDetail.Connections , 2 , 1 , headingVals);
      
      // Seats Settings 
      let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
      console.log("SeatTabData is : " , SeatTabData);
      
      // Meals Settings 
      let MealTabData = getMealsDataStruc(AncellaryData , null , BarData, state.selectedAncellaries);
      console.log("MealTabData is : " , MealTabData);

      let BagsBarData = [];
  
      let bagAvailableForAdd = [];

      let i = 0;

      AncellaryData.forEach(element => {

        BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
        if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
     
          bagAvailableForAdd[i] = true
   
        }   else {

          bagAvailableForAdd[i] = false

        }
        i++;
        
      });
      
      // Bags Settings
      let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
      console.log("BagsTabData is : " , BagsTabData);

      return {
        Status : true,
        Headings : headingVals,
        SeatTabData,
        MealTabData,
        BagsTabData
      }

    } else if (state?.SearchResultType === 4) {

      if(state.FlightDetail.Connections == undefined){
        
        // pure data

        let headingVals = ["Onwards", "Return"];
        
        let BarData = getBarData(state?.FlightDetail , 1 , 2 , headingVals);
        
        AncellaryData = AncellaryData[0]
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData, 2 , BarData);
        console.log("SeatTabData is : " , SeatTabData);

        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , 2 , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);

        let BagsBarData = [];
    
        let bagAvailableForAdd = [];

          for(let i = 0; i < 2; i++){

            BagsBarData[i] = getBagBarData(state?.FlightDetail[0] , 1 , 2 , headingVals , i)
            if(AncellaryData.data.Bags[i].BagsAvailable == false || AncellaryData.data.Bags[i].isBagForFullJourney == true){
         

              bagAvailableForAdd[i] = true

            } else {

              bagAvailableForAdd[i] = false

            }
         
          }
        
        // Bags Settings
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , 2 , bagAvailableForAdd  , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      } else {

        let headingVals = ["Onwards", "Return"];
        
        let BarData = getBarData(state?.FlightDetail.Connections , 2 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);
  
        let BagsBarData = [];
    
        let bagAvailableForAdd = [];

        let i = 0;
  
        AncellaryData.forEach(element => {
  
          BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
       
            bagAvailableForAdd[i] = true
     
          }   else {

            bagAvailableForAdd[i] = false

          }
          i++;
          
        });
        
        // Bags Settings
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      }

    } else if (state?.SearchResultType === 5) {
      let headingVals = ["Trip 1", "Trip 2"];
        
      let BarData = getBarData(state?.FlightDetail.Connections , 2 , 1 , headingVals);
      
      // Seats Settings 
      let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
      console.log("SeatTabData is : " , SeatTabData);
      
      // Meals Settings 
      let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
      console.log("MealTabData is : " , MealTabData);

      let BagsBarData = [];
  
      let bagAvailableForAdd = [];

      let i = 0;

      AncellaryData.forEach(element => {

        BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
        if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
     
   
          bagAvailableForAdd[i] = true
        } else {

          bagAvailableForAdd[i] = false

        }
        i++;
        
      });
      
      // Bags Settings
      let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData ,  null , bagAvailableForAdd  , state.selectedAncellaries);
      console.log("BagsTabData is : " , BagsTabData);

      return {
        Status : true,
        Headings : headingVals,
        SeatTabData,
        MealTabData,
        BagsTabData
      }

    } else if (state?.SearchResultType === 7) {

      let headingVals = ["Trip 1", "Trip 2" , "Trip 3"];
        
      let BarData = getBarData(state?.FlightDetail.Connections , 3 , 1 , headingVals);
      
      // Seats Settings 
      let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
      console.log("SeatTabData is : " , SeatTabData);
      
      // Meals Settings 
      let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
      console.log("MealTabData is : " , MealTabData);

      let BagsBarData = [];
      let bagAvailableForAdd = [];

      let i = 0;

      AncellaryData.forEach(element => {

        BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
        if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
     
   
          bagAvailableForAdd[i] = true

        } else {

          bagAvailableForAdd[i] = false

        }
        i++;
        
      });
      
      // Bags Settings
      let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
      console.log("BagsTabData is : " , BagsTabData);

      return {
        Status : true,
        Headings : headingVals,
        SeatTabData,
        MealTabData,
        BagsTabData
      }

    } else if (state?.SearchResultType === 9) {

      let headingVals = ["Trip 1", "Trip 2" , "Trip 3" , "Trip 4"];
        
      let BarData = getBarData(state?.FlightDetail.Connections , 4 , 1 , headingVals);
      
      // Seats Settings 
      let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
      console.log("SeatTabData is : " , SeatTabData);
      
      // Meals Settings 
      let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
      console.log("MealTabData is : " , MealTabData);

      let BagsBarData = [];
  let bagAvailableForAdd = []
      let i = 0;

      AncellaryData.forEach(element => {

        BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
        if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
     
          bagAvailableForAdd[i] = true
   
        }  else {

          bagAvailableForAdd[i] = false

        }
        i++;
        
      });
      
      // Bags Settings
      let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
      console.log("BagsTabData is : " , BagsTabData);

      return {
        Status : true,
        Headings : headingVals,
        SeatTabData,
        MealTabData,
        BagsTabData
      }

    } else if (state?.SearchResultType === 6) {

      if(state?.FlightDetail?.Connections == undefined) {

              // pure data

              let headingVals = ["Trip 1", "Trip 2"];
        
              let BarData = getBarData(state?.FlightDetail , 1 , 2 , headingVals);
              
              AncellaryData = AncellaryData[0]
              // Seats Settings 
              let SeatTabData = getSeatsDataStruc(AncellaryData, 2 , BarData);
              console.log("SeatTabData is : " , SeatTabData);
      
              // Meals Settings 
              let MealTabData = getMealsDataStruc(AncellaryData , 2 , BarData , state.selectedAncellaries);
              console.log("MealTabData is : " , MealTabData);
      
              let BagsBarData = [];
          
              let bagAvailableForAdd = [];
      
              for(let i = 0; i < 2; i++){
      
                BagsBarData[i] = getBagBarData(state?.FlightDetail[0] , 1 , 2 , headingVals , i)
                  if(AncellaryData.data.Bags[i].BagsAvailable == false || AncellaryData.data.Bags[i].isBagForFullJourney == true){
               
      
                    bagAvailableForAdd[i] = true
      
                  } else {
      
                    bagAvailableForAdd[i] = false
      
                  }
               
              }
              
              // Bags Settings
              let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , 2 , bagAvailableForAdd  , state.selectedAncellaries);
              console.log("BagsTabData is : " , BagsTabData);
              return {
                Status : true,
                Headings : headingVals,
                SeatTabData,
                MealTabData,
                BagsTabData
              }


      } else {

        let headingVals = ["Trip 1", "Trip 2"];
        
        let BarData = getBarData(state?.FlightDetail.Connections , 4 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);
  
        let BagsBarData = [];
        let bagAvailableForAdd = []
        let i = 0;
  
        AncellaryData.forEach(element => {
  
          BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
       
            bagAvailableForAdd[i] = true
     
          }  else {

            bagAvailableForAdd[i] = false
  
          }
          i++;
          
        });
        
        // Bags Settings
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      }

    } else if (state?.SearchResultType === 8) {
      if(state?.FlightDetail?.Connections == undefined) {

                  // pure data

                  let headingVals = ["Trip 1", "Trip 2" , "Trip 3"];
        
                  let BarData = getBarData(state?.FlightDetail , 1 , 2 , headingVals);
                  
                  AncellaryData = AncellaryData[0]
                  // Seats Settings 
                  let SeatTabData = getSeatsDataStruc(AncellaryData, 3 , BarData);
                  console.log("SeatTabData is : " , SeatTabData);
          
                  // Meals Settings 
                  let MealTabData = getMealsDataStruc(AncellaryData , 3 , BarData , state.selectedAncellaries);
                  console.log("MealTabData is : " , MealTabData);
          
                  let BagsBarData = [];
              
                  let bagAvailableForAdd = [];
          
                  for(let i = 0; i < 3; i++){
          
                    BagsBarData[i] = getBagBarData(state?.FlightDetail[0] , 1 , 2 , headingVals , i)
                      if(AncellaryData.data.Bags[i].BagsAvailable == false || AncellaryData.data.Bags[i].isBagForFullJourney == true){
                   
          
                        bagAvailableForAdd[i] = true
          
                      } else {
          
                        bagAvailableForAdd[i] = false
          
                      }
                   
                  }
                  
                  // Bags Settings
                  let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , 3 , bagAvailableForAdd  , state.selectedAncellaries);
                  console.log("BagsTabData is : " , BagsTabData);
                  return {
                    Status : true,
                    Headings : headingVals,
                    SeatTabData,
                    MealTabData,
                    BagsTabData
                  }

      } else {

        let headingVals = ["Trip 1", "Trip 2" , "Trip 3"];
        
        let BarData = getBarData(state?.FlightDetail.Connections , 3 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);
  
        let BagsBarData = [];
  
        let bagAvailableForAdd = [];
        let i = 0;
  
        AncellaryData.forEach(element => {
  
          BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
       
     
            bagAvailableForAdd[i] = true
          }  else {

            bagAvailableForAdd[i] = false
  
          }
          i++;
          
        });
        
        // Bags Settings
        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      }
    } else if (state?.SearchResultType === 10) {

      if(state?.FlightDetail?.Connections == undefined) {

       // pure data

       let headingVals = ["Trip 1", "Trip 2" , "Trip 3" , "Trip 4"];
        
       let BarData = getBarData(state?.FlightDetail , 1 , 2 , headingVals);
       
       AncellaryData = AncellaryData[0]
       // Seats Settings 
       let SeatTabData = getSeatsDataStruc(AncellaryData, 4 , BarData);
       console.log("SeatTabData is : " , SeatTabData);

       // Meals Settings 
       let MealTabData = getMealsDataStruc(AncellaryData , 4 , BarData , state.selectedAncellaries);
       console.log("MealTabData is : " , MealTabData);

       let BagsBarData = [];
   
       let bagAvailableForAdd = [];

       for(let i = 0; i < 4; i++){

         BagsBarData[i] = getBagBarData(state?.FlightDetail[0] , 1 , 2 , headingVals , i)
           if(AncellaryData.data.Bags[i].BagsAvailable == false || AncellaryData.data.Bags[i].isBagForFullJourney == true){
        

             bagAvailableForAdd[i] = true

           } else {

             bagAvailableForAdd[i] = false

           }
        
       }
       
       // Bags Settings
       let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , 4 , bagAvailableForAdd  , state.selectedAncellaries);
       console.log("BagsTabData is : " , BagsTabData);

       return {
        Status : true,
        Headings : headingVals,
        SeatTabData,
        MealTabData,
        BagsTabData
      }

      } else {

        let headingVals = ["Trip 1", "Trip 2" , "Trip 3" , "Trip 4"];
        
        let BarData = getBarData(state?.FlightDetail.Connections , 4 , 1 , headingVals);
        
        // Seats Settings 
        let SeatTabData = getSeatsDataStruc(AncellaryData , null , BarData);
        console.log("SeatTabData is : " , SeatTabData);
        
        // Meals Settings 
        let MealTabData = getMealsDataStruc(AncellaryData , null , BarData , state.selectedAncellaries);
        console.log("MealTabData is : " , MealTabData);
  
        let BagsBarData = [];
    let bagAvailableForAdd = [];

        let i = 0;
  
        AncellaryData.forEach(element => {
  
          BagsBarData[i] = getBagBarData([state.FlightDetail.Connections[i]] , 1 , 1 , headingVals[i])
          if(element.data.Bags[0].BagsAvailable == false || element.data.Bags[0].isBagForFullJourney == true){
       
            bagAvailableForAdd[i] = true
     
          }  else {

            bagAvailableForAdd[i] = false
  
          }
          i++;
          
        });
        
        // Bags Settings

        let BagsTabData = getBagsDataStruc(AncellaryData , BagsBarData , null , bagAvailableForAdd  , state.selectedAncellaries);
       
        console.log("BagsTabData is : " , BagsTabData);

        return {
          Status : true,
          Headings : headingVals,
          SeatTabData,
          MealTabData,
          BagsTabData
        }

      }

    }
  } catch (e){
    console.log("e is : " , e);
    return {
      Status : false,
      Headings : "",
      SeatTabData : [],
      MealTabData : [],
      BagsTabData : []
    }
    
  }

};


// Continue Button 1 ->  save the coupon code to db

export const Call_Continue1 = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    dispatch(setcontinueButtonLoading(true));

    let params = {
      CC: state.appliedCoupon.Code == "" ? null : state.appliedCoupon,
      Booking_id: state.FlightData.booking_id_ref,
    };

    try {
      let data = await callContinue1(params);
      console.log("The continue1 dtaa is : ", data);

      dispatch(setcontinueButtonLoading(false));
        dispatch(setcurrentSection(2));
      
    } catch (e) {
      dispatch(setcontinueButtonLoading(false));
      dispatch(
        setcontinueButtonError("Technical Error ! Please Try Again Later")
      );
      console.log("the continue1 erorr is : ", e);
    }
  };
};

// Continue Button 2 -> svae the traveller data + coupon code to db

export const Call_Continue2 = (typeOfBtn) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    dispatch(setravellerTheme())

    if(typeOfBtn == 1) {
      dispatch(setcontinueButtonLoading(true));
      
      let params = {
        CC: state.appliedCoupon.Code == "" ? null : state.appliedCoupon,
        Booking_id: state.FlightData.booking_id_ref,
        main: state.TravellersData.main,
        travellers: state.TravellersDataFilledUp,
        email: state.mainMail,
        phone: `${state.mainDialCode}-${state.mainPhone}`,
        ancellariesCodes : state.selectedAncellaries,
        saveKey : state.TravellersData.registered == undefined ? 'saved' : 'registered'
      };

      try {

        let data = await callContinue2(params);
        console.log("The continue2 dtaa is : ", data);
          dispatch(setcontinueButtonLoading(false));
          dispatch(setcurrentSection(3));
  
      } catch (e) {
    
          dispatch(setcontinueButtonLoading(false));
          dispatch(
            setcontinueButtonError("Technical Error ! Please Try Again Later")
          );
       
        console.log("the continue2 erorr is : ", e);
      }

    } else {

      dispatch(setOpenPopupMini(true));
      dispatch(setOpenPopupMiniType("Loading"));
      dispatch(setOpenPopupData(null));

      if(state.TravellersData.registered == undefined && state.TravellersData.saved == undefined){

        let params = {
          CC: state.appliedCoupon.Code == "" ? null : state.appliedCoupon,
          Booking_id: state.FlightData.booking_id_ref,
          main: state.TravellersData.main,
          travellers: state.TravellersDataFilledUp,
          email: state.mainMail,
          phone: `${state.mainDialCode}-${state.mainPhone}`,
          ancellariesCodes : state.selectedAncellaries,
          saveKey : state.TravellersData.registered == undefined ? 'saved' : 'registered'
        };
  
        try {
  
          await callContinue2(params);
         
         
        
          let proceedParams = {
            BookingRefId : state.FlightData.booking_id_ref,
            SearchId : state.FlightData.search_id,
            travellers : {
              data : state.TravellersDataFilledUp,
              contactInfo : {
              email: state.mainMail,
              phone: `${state.mainDialCode}-${state.mainPhone}`
              }
            },
            discountCoupon: {
                isAdded: false
            },
            zeroCancellation: {
                isAdded: false
            },
            insurance: {
                isAdded: false
            }
          }
      
          console.log("proceedParams is : " , proceedParams);
          
          try {
  
            // call proceed to pay api and handle it all here 
            let proceededData = await callProceedToPay(proceedParams)
      
            console.log("proceededData is : " , proceededData);
            proceededData = proceededData.data;
      
            if(proceededData.redirectToPaymentPage == false){
              // show that reason 
      
              if(proceededData.code == "IVL" || proceededData.code == "FNA"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : "Link expired",  Data : "You can start your search again and complete your booking" , showContinue : false }));
              } if(proceededData.code == "SE"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : "Session expired",  Data : "You can start your search again and complete your booking" , showContinue : false}));
              } else if(proceededData.code == "TE" || proceededData.code == "TVA"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later" , showContinue : true , fetchFlight : true}));
              } else if(proceededData.code == "TVM"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : "Invalid Travellers",  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
              } else if(proceededData.code == "RBTC"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
              } else if(proceededData.code === "ANCE"){
                dispatch(setOpenPopupMiniType("Error"));
                dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : false , ProceedToPayName: 
                  proceededData.CodeName.includes("Bag") ? "Bags" :
                  proceededData.CodeName.includes("Seat") ? "Seats" :
                  proceededData.CodeName.includes("Meal") ? "Meals" : proceededData.CodeName}));
              }  else if(proceededData.code === "PC"){
                dispatch(setOpenPopupMiniType("Price_Changed"));
                dispatch(
                  setOpenPopupData(
                    {Heading : proceededData.CodeName,  Message : `The Price of this Flight is ${proceededData.Message.priceStatus} . Old Price was Rs . ${proceededData.Message.oldPrice} , Current Price of the Flight is Rs . ${proceededData.Message.newPrice}` , showContinue : true,  fetchFlight : true}
                  )
                );
              } 
      
            } else {
            
              // redirect to payment page 
              
              dispatch(setOpenPopupMiniType("Message"));
              dispatch(setOpenPopupData({Heading : "Flight Selected Successfully",  Data : "Redirecting You to Payment Page"}));
      
              // get the payment id and redirect it to the payment page 
              let paymentId = proceededData.Message.payment_id;
              console.log("Payment Id is : " , paymentId);

              window.location.href = `${MAIN_PAYMENT_URL}id=${paymentId}`;
      
            }
            
          } catch (e) {
      
          console.log("the erorro proceed toi pay here is : " , e);
          
          dispatch(setOpenPopupMiniType("Error"));
          dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
      
          }
          
    
        } catch (e) {
      
            console.log("the erorro proceed toi pay here is : " , e);
        
        dispatch(setOpenPopupMiniType("Error"));
        dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
         
          console.log("the continue2 erorr is : ", e);
        }

      } else {

        let proceedParams = {
          BookingRefId : state.FlightData.booking_id_ref,
          SearchId : state.FlightData.search_id,
          travellers : {
            data : state.TravellersDataFilledUp,
            contactInfo : {
            email: state.mainMail,
            phone: `${state.mainDialCode}-${state.mainPhone}`
            }
          },
          discountCoupon: {
              isAdded: false
          },
          zeroCancellation: {
              isAdded: false
          },
          insurance: {
              isAdded: false
          }
        }
    
        console.log("proceedParams is : " , proceedParams);
        
        try {

          // call proceed to pay api and handle it all here 
          let proceededData = await callProceedToPay(proceedParams)
    
          console.log("proceededData is : " , proceededData);
          proceededData = proceededData.data;
    
          if(proceededData.redirectToPaymentPage == false){
            // show that reason 
    
            if(proceededData.code == "IVL" || proceededData.code == "FNA"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : "Link expired",  Data : "You can start your search again and complete your booking" , showContinue : false }));
            } if(proceededData.code == "SE"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : "Session expired",  Data : "You can start your search again and complete your booking" , showContinue : false}));
            } else if(proceededData.code == "TE" || proceededData.code == "TVA"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later" , showContinue : true , fetchFlight : true}));
            } else if(proceededData.code == "TVM"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : "Invalid Travellers",  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
            } else if(proceededData.code == "RBTC"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
            } else if(proceededData.code === "ANCE"){
              dispatch(setOpenPopupMiniType("Error"));
              dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : false , ProceedToPayName: 
                proceededData.CodeName.includes("Bag") ? "Bags" :
                proceededData.CodeName.includes("Seat") ? "Seats" :
                proceededData.CodeName.includes("Meal") ? "Meals" : proceededData.CodeName}));
            }  else if(proceededData.code === "PC"){
              dispatch(setOpenPopupMiniType("Price_Changed"));
              dispatch(
                setOpenPopupData(
                  {Heading : proceededData.CodeName,  Message : `The Price of this Flight is ${proceededData.Message.priceStatus} . Old Price was Rs . ${proceededData.Message.oldPrice} , Current Price of the Flight is Rs . ${proceededData.Message.newPrice}` , showContinue : true,  fetchFlight : true}
                )
              );
            } 
    
          } else {
          
            // redirect to payment page 
            
            dispatch(setOpenPopupMiniType("Message"));
            dispatch(setOpenPopupData({Heading : "Flight Selected Successfully",  Data : "Redirecting You to Payment Page"}));
    
            // get the payment id and redirect it to the payment page 
            let paymentId = proceededData.Message.payment_id;
    
            window.location.href = `${MAIN_PAYMENT_URL}id=${paymentId}`;

            console.log("Payment Id is : " , paymentId);
            
          }
          
        } catch (e) {
    
        console.log("the erorro proceed toi pay here is : " , e);
        
        dispatch(setOpenPopupMiniType("Error"));
        dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
    
        }
      }
        
      
    }

  };
};

// Continue Button 3 -> move to payment page by proceed to pay button

export const Call_Continue3 = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    dispatch(setOpenPopupMini(true));
    dispatch(setOpenPopupMiniType("Loading"));
    dispatch(setOpenPopupData(null));

    dispatch(setravellerTheme())

    let state = { ...ReviewPageFlight };

    let params = {
      CC: state.appliedCoupon.Code == "" ? null : state.appliedCoupon,
      Booking_id: state.FlightData.booking_id_ref,
    };

    try {
      let data = await callContinue1(params);
      console.log("The continue1 dtaa is : ", data);


      let proceedParams = {
        BookingRefId : state.FlightData.booking_id_ref,
        SearchId : state.FlightData.search_id,
        travellers : {
          data : state.TravellersDataFilledUp,
          contactInfo : {
          email: state.mainMail,
          phone: `${state.mainDialCode}-${state.mainPhone}`
          }
        },
        discountCoupon: {
            isAdded: false
        },
        zeroCancellation: {
            isAdded: false
        },
        insurance: {
            isAdded: false
        }
      }
  
      console.log("proceedParams is : " , proceedParams);
      
      try {

        // call proceed to pay api and handle it all here 
        let proceededData = await callProceedToPay(proceedParams)
  
        console.log("proceededData is : " , proceededData);
        proceededData = proceededData.data;
  
        if(proceededData.redirectToPaymentPage == false){
          // show that reason 
  
          if(proceededData.code == "IVL" || proceededData.code == "FNA"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Link expired",  Data : "You can start your search again and complete your booking" , showContinue : false }));
          } if(proceededData.code == "SE"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Session expired",  Data : "You can start your search again and complete your booking" , showContinue : false}));
          } else if(proceededData.code == "TE" || proceededData.code == "TVA"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later" , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code == "TVM"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Invalid Travellers",  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code == "RBTC"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code === "ANCE"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : false , ProceedToPayName: 
              proceededData.CodeName.includes("Bag") ? "Bags" :
              proceededData.CodeName.includes("Seat") ? "Seats" :
              proceededData.CodeName.includes("Meal") ? "Meals" : proceededData.CodeName}));
          }  else if(proceededData.code === "PC"){
            dispatch(setOpenPopupMiniType("Price_Changed"));
            dispatch(
              setOpenPopupData(
                {Heading : proceededData.CodeName,  Message : `The Price of this Flight is ${proceededData.Message.priceStatus} . Old Price was Rs . ${proceededData.Message.oldPrice} , Current Price of the Flight is Rs . ${proceededData.Message.newPrice}` , showContinue : true,  fetchFlight : true}
              )
            );
          } 
  
        } else {
        
          // redirect to payment page 
          
          dispatch(setOpenPopupMiniType("Message"));
          dispatch(setOpenPopupData({Heading : "Flight Selected Successfully",  Data : "Redirecting You to Payment Page"}));
  
          // get the payment id and redirect it to the payment page 
          let paymentId = proceededData.Message.payment_id;
  
          window.location.href = `${MAIN_PAYMENT_URL}id=${paymentId}`;

          console.log("Payment Id is : " , paymentId);
          
        }
        
      } catch (e) {
  
      console.log("the erorro proceed toi pay here is : " , e);
      
      dispatch(setOpenPopupMiniType("Error"));
      dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
  
      }
      
    } catch (e) {

      dispatch(setOpenPopupMiniType("Error"));
      dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
    }

  };
};

export const CallTheAncellaries = () => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ReviewPageFlight } = statesData;

    let state = { ...ReviewPageFlight };

    let params = {
      BookingRefId: state.FlightData.booking_id_ref,
      SearchId: state.FlightData.search_id,
    };

    try {
      let data = await callAncellaries(params);

      // check if the data is empty
      if (data.data == undefined) {

        dispatch(setAncellariesDownloaded(true));
        dispatch(setAncellariesLoading(false));

      } else {

        let AncellaryStructuredData = await getMainAncellariesStructure(state , data.data.data == undefined ? data.data : [data.data]);

        console.log("AT FINAL AncellaryStructuredDataIS : " , AncellaryStructuredData);
        
        dispatch(setAncellariesTabsHeadings(AncellaryStructuredData.Headings))

        let hasSeatsData = AncellaryStructuredData.SeatTabData.some(mainElement => {
          return mainElement.some(element => {
            return element?.error === false || element?.SeactionTabData?.Data?.length > 0;
          });
        });

        dispatch(sethasSeatsData(hasSeatsData))

        dispatch(setAncellariesTabs({name : "SeatTabs", value : AncellaryStructuredData.SeatTabData}))

        let hasMealsData = AncellaryStructuredData.MealTabData.some(mainElement => {
          return mainElement.some(element => {
            return element?.error === false || element?.SeactionTabData?.length > 0;
          });
        });

        dispatch(sethasMealsData(hasMealsData))

        dispatch(setAncellariesTabs({name : "MealTabs", value : AncellaryStructuredData.MealTabData}))

        let hasBagsData = AncellaryStructuredData.BagsTabData.some(mainElement => {
          return mainElement.some(element => {
            return element?.error === false || element.SeactionTabData?.Data?.length > 0;
          });
        });

        console.log("hasBagsData is : " , hasBagsData);
        

        dispatch(sethasBagsData(hasBagsData))

        dispatch(setAncellariesTabs({name : "BagTabs", value : AncellaryStructuredData.BagsTabData}))
          
        let toPut = {
           Message: AncellaryStructuredData.Status == true ? "ancellaries found successfully" : null,
        };

        // set active ancellary tab 

        if(hasSeatsData == true){
          dispatch(setAncellarySectionActive("Seats"))
        } else {
          if(hasMealsData == true){
            dispatch(setAncellarySectionActive("Meals"))
          } else {
            dispatch(setAncellarySectionActive("Baggage"))

          }

        }
        
  
        dispatch(setAncellariesApiData(toPut));

        dispatch(setAncellariesDownloaded(true));
        dispatch(setAncellariesLoading(false));
      }
    } catch (e) {
      // acathc the proper errors sent by bakcend

      dispatch(setAncellariesDownloaded(true));
      dispatch(setAncellariesLoading(false));
    }
  };
};

const initialState = {
  isOffline: false,

  ReviewPageLoading: "loading",
  ReviewPageLoadingPercentage: "0%",

  ReviewPageError: false,
  ReviewPageErrroName: "",
  ReviewPageErrorDiscription: "",
  isResearchQuery: false,
  ResearchQueryURL: "",

  Theme: "proxima",
  fareType : "",
  searchKey: "",
  itrCode: "",

  SearchResultType: 1,
  currentSection: 1,
  startButtonShaking: false,

  FlightData: {},
  FlightDetail: {},
  TravellersData: {},
  SellResponse: {},

  appliedCoupon: {
    Code: "",
  },

  upgradeLoading: false,
  upgradeLoadingError: false,

  OpenPopupMini: false,
  OpenPopupMiniType: "", // Price_Changed  ,  Loading
  OpenPopupData: {},

  openMainPopup: false,

  isPriceChanged: false,
  PriceChangedData: {},

  currentFarePrice: 0,

  firstNameLimit: { val: 100, airline: "" },
  lastNameLimit: { val: 100, airline: "" },
  airlineNameFormat: [""],
  airlineNameFormatAccepted: false,
  IsPassportRequired: false,
  TravellersDataFilledUp: [{}],

  mainDialCode: "",
  mainPhone: "",
  mainMail: "",
  gstAvailable: false,
  gstNumber: "",
  gstCompany: "",

  continueButtonLoading: false,
  continueButtonError: "",

  // Addons Variables
  AncellariesApiData: {
    Message: null,
  },
  AncellariesLoading: true,
  AncellariesDownloaded: false,
  AncellarySectionActive: "",

  currentAncellariesTab: [[0,0], [0,0], [0,0]],
  AncellariesTabs: {
    MealTabs: null, // it will consit data as [{BarData : "" , TabValue : , Seactiondata : }]
    BagTabs: null, // it will consit data as [{BarData : "" , TabValue : , Seactiondata : }]
    SeatTabs: null, // it will consit data as [{BarData : "" , TabValue : , Seactiondata : }]
  },

  AncellariesTabsHeadings: [],

  AncellariesPrice : {
    Seats : 0,
    Meals : 0,
    Baggage : 0,
  },
  selectedAncellaries : {
    Seats : [],
    Meals : [],
    Bags : [],
  },
  hasSeatsData : false,
  hasMealsData : false,
  hasBagsData : false,
  
  nextAncellaryTraveller : 0
};

const ReviewPageFlightsSlice = createSlice({
  name: "ResultPageFlightsFlight",
  initialState,
  reducers: {
    setReviewPageLoading: (state, action) => {
      state.ReviewPageLoading = action.payload;
    },
    setReviewPageLoadingPercentage: (state, action) => {
      state.ReviewPageLoadingPercentage = action.payload;
    },
    setsearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
    setfareType: (state, action) => {
      state.fareType = action.payload;
    },
    setTheme: (state, action) => {
      state.Theme = action.payload;
    },
    setitrCode: (state, action) => {
      state.itrCode = action.payload;
    },
    setReviewPageError: (state, action) => {
      state.ReviewPageError = action.payload;
    },
    setSearchResultType: (state, action) => {
      state.SearchResultType = action.payload;
    },
    setReviewPageErrroName: (state, action) => {
      state.ReviewPageErrroName = action.payload;
    },
    setReviewPageErrorDiscription: (state, action) => {
      state.ReviewPageErrorDiscription = action.payload;
    },
    setisResearchQuery: (state, action) => {
      state.isResearchQuery = action.payload;
    },
    setResearchQueryURL: (state, action) => {
      state.ResearchQueryURL = action.payload;
    },
    setFlightData: (state, action) => {
      state.FlightData = action.payload;
    },
    setFlightDetail: (state, action) => {
      state.FlightDetail = action.payload;
    },
    setTravellersData: (state, action) => {
      state.TravellersData = action.payload;
    },
    setSellResponse: (state, action) => {
      state.SellResponse = action.payload;
    },
    setappliedCouponFE: (state, action) => {
      state.appliedCoupon = action.payload;
    },
    setOpenPopupMini: (state, action) => {
      state.OpenPopupMini = action.payload;
    },
    setOpenPopupMiniType: (state, action) => {
      state.OpenPopupMiniType = action.payload;
    },
    setOpenPopupData: (state, action) => {
      state.OpenPopupData = action.payload;
    },
    setisPriceChanged: (state, action) => {
      state.isPriceChanged = action.payload;
    },
    setPriceChangedData: (state, action) => {
      state.PriceChangedData = action.payload;
    },
    setopenMainPopup: (state, action) => {
      state.openMainPopup = action.payload;
    },
    setupgradeLoading: (state, action) => {
      state.upgradeLoading = action.payload;
    },
    setupgradeLoadingError: (state, action) => {
      state.upgradeLoadingError = action.payload;
    },
    setcurrentFarePrice: (state, action) => {
      state.currentFarePrice = action.payload;
    },
    setcurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setstartButtonShaking: (state, action) => {
      state.startButtonShaking = action.payload;
    },
    setfirstNameLimit: (state, action) => {
      state.firstNameLimit = action.payload;
    },
    setlastNameLimit: (state, action) => {
      state.lastNameLimit = action.payload;
    },
    setairlineNameFormat: (state, action) => {
      state.airlineNameFormat = action.payload;
    },
    setairlineNameFormatAccepted: (state, action) => {
      state.airlineNameFormatAccepted = action.payload;
    },
    setIsPassportRequired: (state, action) => {
      state.IsPassportRequired = action.payload;
    },
    setTravellersDataFilledUp: (state, action) => {
      state.TravellersDataFilledUp = action.payload;
    },
    setmainDialCode: (state, action) => {
      state.mainDialCode = action.payload;
    },
    setmainPhone: (state, action) => {
      state.mainPhone = action.payload;
    },
    setmainMail: (state, action) => {
      state.mainMail = action.payload;
    },

    setgstAvailable: (state, action) => {
      state.gstAvailable = action.payload;
    },
    setgstNumber: (state, action) => {
      state.gstNumber = action.payload;
    },
    setgstCompany: (state, action) => {
      state.gstCompany = action.payload;
    },
    setcontinueButtonLoading: (state, action) => {
      state.continueButtonLoading = action.payload;
    },
    setcontinueButtonError: (state, action) => {
      state.continueButtonError = action.payload;
    },

    // Travellers Data Setters

    setTravellerTitle: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].title =
        action.payload.data;
    },
    setTravellerFirstName: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].firstName =
        action.payload.data;
    },
    setTravellerLastName: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].lastName =
        action.payload.data;
    },
    setTravellerDob: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].dob =
        action.payload.data;
    },
    setTravellerGender: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].gender =
        action.payload.data;
    },
    setTravellerCountryDetails: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].city =
        action.payload.data.city;
      state.TravellersDataFilledUp[action.payload.index].countryCode =
        action.payload.data.countryCode;
      state.TravellersDataFilledUp[action.payload.index].countryName =
        action.payload.data.countryName;
      state.TravellersDataFilledUp[action.payload.index].nationality =
        action.payload.data.nationality;
    },
    setTravellerPassportNumber: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].passportDetails.PassportNo = action.payload.data;
    },
    setTravellerPassportExpiryDate: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].passportDetails.PassportExpiry = action.payload.data;
    },
    setTravellerPassportIssueDate: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].passportDetails.PassportIssueDate = action.payload.data;
    },
    setTravellerPassportIssuingCountry: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].passportDetails.PassportIssuingCountry = action.payload.data;
    },
    setTravellerWheelChairRequested: (state, action) => {
      state.TravellersDataFilledUp[action.payload.index].wheelChairRequested =
        action.payload.data;
    },
    setTravellerfrequentFlyerAvailability: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].frequentFlyerDetails.available = action.payload.data;
    },
    setTravellerfrequentFlyerAirline: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].frequentFlyerDetails.airline = action.payload.data;
    },
    setTravellerfrequentFlyerNumber: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].frequentFlyerDetails.number = action.payload.data;
    },
    setTravellerGstData: (state, action) => {
      state.TravellersDataFilledUp[
        action.payload.index
      ].gstDetails.GSTCompanyName = action.payload.data.name;
      state.TravellersDataFilledUp[action.payload.index].gstDetails.GSTNumber =
        action.payload.data.number;
    },

    setAncellariesPriceBulk : (state , action) => {

      state.AncellariesPrice.Seats = action.payload.seat
      state.AncellariesPrice.Meals = action.payload.meal
      state.AncellariesPrice.Baggage = action.payload.bag

    },
    setravellerTheme : (state , action) => {
      state.TravellersDataFilledUp[0].Theme = state.Theme
    },
    sethasSeatsData : (state , action) => {
      state.hasSeatsData = action.payload
    },
    sethasMealsData : (state , action) => {
      state.hasMealsData = action.payload
    },
    sethasBagsData : (state , action) => {
      state.hasBagsData = action.payload
    },
    setTravellersAncellary : (state , action) => {

      let {travellersIndex , ancellaryHead , id1 , id2 , data} = action.payload;

      state.TravellersDataFilledUp[travellersIndex].ancellaries[ancellaryHead][id1][id2] = data;
    
    },
    setSelectTheSeat: (state, action) => {
      let { travellersIndex, data } = action.payload;
      let currentSeatTabInfo = state.currentAncellariesTab[0];
    
      // Generate the seat key and initials for the selected seat
      let seatKey = `${data.Code}-${currentSeatTabInfo[0]}-${currentSeatTabInfo[1]}`;
      let trKey = `${currentSeatTabInfo[0]}-${currentSeatTabInfo[1]}-${travellersIndex}`;
      
      let travellerInitials = `${state.TravellersDataFilledUp[travellersIndex].firstName[0]}${state.TravellersDataFilledUp[travellersIndex].lastName ? state.TravellersDataFilledUp[travellersIndex].lastName[0] : ""}`;
    
      let selectedSeats = state.selectedAncellaries.Seats.find(seat => seat.seatKey === seatKey);
    
      console.log("selectedSeats is : " , selectedSeats);
      
      if (selectedSeats === undefined) {

        // check if this traveller has already seat added  ? 
        let selectedSeatsTr = state.selectedAncellaries.Seats.filter(seat => seat.TrKey === trKey);

        if(selectedSeatsTr.length !== 0) {
          
          // Remove the seat
          let Data = {
            seatRequested: null
          };

          // Remove the seat from TravellersDataFilledUp
          state.TravellersDataFilledUp[selectedSeatsTr[0].TrNo].ancellaries.seats[currentSeatTabInfo[0]][currentSeatTabInfo[1]] = Data;

          // Deduct the seat price from AncellariesPrice
          state.AncellariesPrice.Seats -= data.Price;

          // Remove the seat from selectedAncellaries.Seats
          state.selectedAncellaries.Seats = state.selectedAncellaries.Seats.filter(seat => seat.TrKey !== trKey);
          
        }

        // Add the seat
        let Data = {
          seatRequested: data
        };
    
        state.TravellersDataFilledUp[travellersIndex].ancellaries.seats[currentSeatTabInfo[0]][currentSeatTabInfo[1]] = Data;
    
        state.AncellariesPrice.Seats += data.Price;
    
        // Add seatKey with initials to selectedAncellaries.Seats
        state.selectedAncellaries.Seats.push({ seatKey: seatKey, initials: travellerInitials  , TrNo : travellersIndex , TrKey : trKey , Price : data.Price});
        
      } else {

        // Remove the seat
        let Data = {
          seatRequested: null
        };
    
        // Remove the seat from TravellersDataFilledUp
        state.TravellersDataFilledUp[selectedSeats.TrNo].ancellaries.seats[currentSeatTabInfo[0]][currentSeatTabInfo[1]] = Data;
    
        // Deduct the seat price from AncellariesPrice
        state.AncellariesPrice.Seats -= data.Price;
    
        // Remove the seat from selectedAncellaries.Seats
        state.selectedAncellaries.Seats = state.selectedAncellaries.Seats.filter(seat => seat.seatKey !== seatKey);

      }

      let travellerNo = state.TravellersDataFilledUp.filter(item => item.type !== "INF").findIndex(
        item => item.ancellaries.seats[currentSeatTabInfo[0]][
          currentSeatTabInfo[1]
        ].seatRequested == null || item.ancellaries.seats[currentSeatTabInfo[0]][
          currentSeatTabInfo[1]
        ].seatRequested == ""
      );
  
      
      if(travellerNo == -1){
        state.nextAncellaryTraveller = state.nextAncellaryTraveller;
      } else {
        state.nextAncellaryTraveller = travellerNo
      }

    },
    setSelectTheMeal: (state, action) => {

      let { data, travellersIndex , Keyaction , mealIndex , keyname} = action.payload;
      let currentMealTabInfo = state.currentAncellariesTab[1];
      
      if (Keyaction === "+") {

        // check is this traveller has already any other meal added ? if added then remove the previous added and add this new meal to it 

        if(state.TravellersDataFilledUp[travellersIndex].ancellaries.meals[currentMealTabInfo[0]][currentMealTabInfo[1]].mealRequested !== "" && state.TravellersDataFilledUp[travellersIndex].ancellaries.meals[currentMealTabInfo[0]][currentMealTabInfo[1]].mealRequested !== null){

          // already a meal is added for this traveller so remove it first
          
          let theSelectedMeal = state.TravellersDataFilledUp[travellersIndex].ancellaries.meals[currentMealTabInfo[0]][currentMealTabInfo[1]].mealRequested;

          console.log("the prev  meal  :  "  , theSelectedMeal);
          
          // Deduct the meal price from AncellariesPrice
          state.AncellariesPrice.Meals -= theSelectedMeal.Price;

          // Initialize mealid to -1
          let mealid = state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData.findIndex(
            element => element.Code === theSelectedMeal.Code
          );
          
          let allTravellers = state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealid].Traveller

          // the current travelelr and the traveller which added this meal are not same so remvoe this meal for that travelelr which selected it 
  
          let thatTravellerId = allTravellers[allTravellers.length - 1];

          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealid].Quantity--;

          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealid].Traveller.pop();
       
            // Filter out the meal from selectedAncellaries.Meals based on the current meal info
            let filteredData = state.selectedAncellaries.Meals.filter((item, id) => {
              return !(item.id1 === currentMealTabInfo[0] && item.id2 === currentMealTabInfo[1] && item.Code === theSelectedMeal.Code && item.Traveller == thatTravellerId);
            });

            // Assign the filtered data back to the state
            state.selectedAncellaries.Meals = filteredData;

        }

        // FINAL ADD THE Meal

        let { Traveller, ...restData } = data; 
        let Data = {
          mealRequested: {
            ...restData,
            Quantity: 0
          }
        };

        if(keyname !== undefined || keyname !== null ){
          Data.keyname = keyname
        }

        state.TravellersDataFilledUp[travellersIndex].ancellaries.meals[currentMealTabInfo[0]][currentMealTabInfo[1]] = Data;
    
        state.AncellariesPrice.Meals += data.Price;

        state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Quantity++;

        // Ensure the Traveller array exists before pushing

        let allTravellers = state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller;

        if(allTravellers && allTravellers[allTravellers.length - 1] == travellersIndex){
          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller.pop();
        }

        if (!allTravellers) {
          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller = [];
        }

        state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller.push(travellersIndex);

          state.selectedAncellaries.Meals.push({ 
            id1: currentMealTabInfo[0], 
            id2 : currentMealTabInfo[1], 
            Quantity : 1, 
            Code : Data.mealRequested.Code,
            Price : data.Price,
            Traveller : travellersIndex
          });

        
      } else {

        // check is the "-" was clicked for same travelelr id ? 
        let allTravellers = state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller

        // the current travelelr and the traveller which added this meal are not same so remvoe this meal for that travelelr which selected it 

          let thatTravellerId = allTravellers[allTravellers.length - 1];

          // Remove the seat
          let Data = {
            mealRequested: null
          };

          // Remove the meal from TravellersDataFilledUp
          state.TravellersDataFilledUp[thatTravellerId].ancellaries.meals[currentMealTabInfo[0]][currentMealTabInfo[1]] = Data;

          // Deduct the meal price from AncellariesPrice
          state.AncellariesPrice.Meals -= data.Price;

          // Remove the meal from selectedAncellaries.Meals

          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Quantity--;
          state.AncellariesTabs.MealTabs[currentMealTabInfo[0]][currentMealTabInfo[1]].SeactionTabData[mealIndex].Traveller.pop()
        
            // Filter out the meal from selectedAncellaries.Meals based on the current meal info
            let filteredData = state.selectedAncellaries.Meals.filter((item, id) => {
              return !(item.id1 === currentMealTabInfo[0] && item.id2 === currentMealTabInfo[1] && item.Code === data.Code && item.Traveller == thatTravellerId);
            });

            // Assign the filtered data back to the state
            state.selectedAncellaries.Meals = filteredData;
      }

      let travellerNo = state.TravellersDataFilledUp.filter(item => item.type !== "INF").findIndex(
        item => item.ancellaries.meals[currentMealTabInfo[0]][
          currentMealTabInfo[1]
        ].mealRequested == null || item.ancellaries.meals[currentMealTabInfo[0]][
          currentMealTabInfo[1]
        ].mealRequested == ""
      );
  
      console.log("travellerNo is : "  , travellerNo);
      
      if(travellerNo == -1){
        state.nextAncellaryTraveller = state.nextAncellaryTraveller;
      } else {
        state.nextAncellaryTraveller = travellerNo
      } 

    },
    setSelectTheBag: (state, action) => {

      let { data, travellersIndex , Keyaction , bagIndex } = action.payload;
      let currentBagTabInfo = state.currentAncellariesTab[2];
      
      if (Keyaction === "+") {

        // check is this traveller has already any other bag added ? if added then remove the previous added and add this new bag to it 

        if(state.TravellersDataFilledUp[travellersIndex].ancellaries.bags[currentBagTabInfo[0]][currentBagTabInfo[1]].bagRequested !== "" && state.TravellersDataFilledUp[travellersIndex].ancellaries.bags[currentBagTabInfo[0]][currentBagTabInfo[1]].bagRequested !== null){

          // already a bag is added for this traveller so remove it first
          
          let theSelectedBag = state.TravellersDataFilledUp[travellersIndex].ancellaries.bags[currentBagTabInfo[0]][currentBagTabInfo[1]].bagRequested;

          console.log("the prev bag : "  , theSelectedBag);
          
          // Deduct the meal price from AncellariesPrice
          state.AncellariesPrice.Baggage -= theSelectedBag.Price;

          // Initialize bagid to -1
          let bagid = state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data.findIndex(
            element => element.Weight === theSelectedBag.Weight
          );

               
          let allTravellers = state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagid].Traveller

          // the current travelelr and the traveller which added this meal are not same so remvoe this meal for that travelelr which selected it 
  
          let thatTravellerId = allTravellers[allTravellers.length - 1];

          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagid].Quantity--;

          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagid].Traveller.pop();
       
            // Filter out the meal from selectedAncellaries.Bags based on the current meal info
            let filteredData = state.selectedAncellaries.Bags.filter((item, id) => {
              return !(item.id1 === currentBagTabInfo[0] && item.id2 === currentBagTabInfo[1] && item.Weight === theSelectedBag.Weight && item.Traveller === thatTravellerId);
            });


            console.log("filteredData here when adding : " , filteredData);
            
           // Assign the filtered data back to the state
         state.selectedAncellaries.Bags = filteredData;

        }

        // FINAL ADD THE bag

        let { Traveller, ...restData } = data; 
        let Data = {
          bagRequested: {
            ...restData,
            Quantity: 0
          }
        };
    
        state.TravellersDataFilledUp[travellersIndex].ancellaries.bags[currentBagTabInfo[0]][currentBagTabInfo[1]] = Data;
    
        state.AncellariesPrice.Baggage += data.Price;

        state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Quantity++;

        // Ensure the Traveller array exists before pushing
        let allTravellers = state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller;

        if(allTravellers && allTravellers[allTravellers.length - 1] == travellersIndex){
          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller.pop();
        }

        if (!allTravellers) {
          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller = [];
        }

        state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller.push(travellersIndex);
        
        state.selectedAncellaries.Bags.push({ 
          id1: currentBagTabInfo[0], 
          id2 : currentBagTabInfo[1], 
          Quantity : 1, 
          Weight : Data.bagRequested.Weight,
          Price : data.Price,
          Traveller : travellersIndex
        });

      } else {

        // check is the "-" was clicked for same travelelr id ? 
        let allTravellers = state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller

        // the current travelelr and the traveller which added this meal are not same so remvoe this meal for that travelelr which selected it 

          let thatTravellerId = allTravellers[allTravellers.length - 1];

          // Remove the bag
          let Data = {
            bagRequested: null
          };

          // Remove the bag from TravellersDataFilledUp
          state.TravellersDataFilledUp[thatTravellerId].ancellaries.bags[currentBagTabInfo[0]][currentBagTabInfo[1]] = Data;

          // Deduct the bag price from AncellariesPrice
          state.AncellariesPrice.Baggage -= data.Price;

          // Remove the bag from selectedAncellaries.Meals

          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Quantity--;

          state.AncellariesTabs.BagTabs[currentBagTabInfo[0]][currentBagTabInfo[1]].SeactionTabData.Data[bagIndex].Traveller.pop()

          // Filter out the meal from selectedAncellaries.Bags based on the current bag info
          let filteredData = state.selectedAncellaries.Bags.filter((item, id) => {
            return !(item.id1 === currentBagTabInfo[0] && item.id2 === currentBagTabInfo[1] && item.Weight === data.Weight && item.Traveller == thatTravellerId);
          });
  
              // Assign the filtered data back to the state
              state.selectedAncellaries.Bags = filteredData;
      }

      let travellerNo = state.TravellersDataFilledUp.filter(item => item.type !== "INF").findIndex(
        item => item.ancellaries.bags[currentBagTabInfo[0]][
          currentBagTabInfo[1]
        ].bagRequested == null || item.ancellaries.bags[currentBagTabInfo[0]][
          currentBagTabInfo[1]
        ].bagRequested == ""
      );
  
      console.log("travellerNo is : "  , travellerNo);

      if(travellerNo == -1){
        state.nextAncellaryTraveller = state.nextAncellaryTraveller;
      } else {
        state.nextAncellaryTraveller = travellerNo
      }

    },
    removeTheAncellaries : (state  ,  action) => {

      if(action.payload === "Seats"){
        state.selectedAncellaries.Seats = []
        state.AncellariesPrice.Seats = 0

        state.TravellersDataFilledUp.forEach(element1 => {
          
          element1.ancellariesCodes.Seats = [];

          element1.ancellaries.seats.forEach(element2 => {
            element2.forEach(element3 => {
              element3.seatRequested = null
            });
          });

        });

      } else  if(action.payload === "Meals"){
        state.selectedAncellaries.Meals = []
        state.AncellariesPrice.Meals = 0

        state.TravellersDataFilledUp.forEach(element1 => {
          
          element1.ancellariesCodes.Meals = [];

          element1.ancellaries.meals.forEach(element2 => {
            element2.forEach(element3 => {
              element3.mealRequested = null
            });
          });

        });


      } else  if(action.payload === "Bags"){
        state.selectedAncellaries.Bags = []
        state.AncellariesPrice.Baggage = 0;

        state.TravellersDataFilledUp.forEach(element1 => {
          
          element1.ancellariesCodes.Bags = [];

          element1.ancellaries.bags.forEach(element2 => {
            element2.forEach(element3 => {
              element3.bagRequested = null
            });
          });

        });

      }

    // call proceed to Pay
  
    },
    setnextAncellaryTraveller: (state  ,  action) => {
      state.nextAncellaryTraveller = action.payload;
    },
    setnextAncellaryTravellerOnLoad: (state  ,  action) => {

      let currentSelectedTabInfo = state.currentAncellariesTab[action.payload];

      let travellerNo = -1;

      if(action.payload == 0){

        travellerNo = state.TravellersDataFilledUp.filter(item => item.type !== "INF").findIndex(
          item => item.ancellaries.seats[currentSelectedTabInfo[0]][
            currentSelectedTabInfo[1]
          ].seatRequested == null || item.ancellaries.seats[currentSelectedTabInfo[0]][
            currentSelectedTabInfo[1]
          ].seatRequested == ""
        );
      } else if(action.payload == 1){

        travellerNo = state.TravellersDataFilledUp.filter(item => item.type !== "INF").findIndex(
          item => item.ancellaries.meals[currentSelectedTabInfo[0]][
            currentSelectedTabInfo[1]
          ].mealRequested == null || item.ancellaries.meals[currentSelectedTabInfo[0]][
            currentSelectedTabInfo[1]
          ].mealRequested == ""
        );
      }
  
      console.log("travellerNo is : "  , travellerNo);
      
      if(travellerNo == -1){
        state.nextAncellaryTraveller = state.nextAncellaryTraveller;
      } else {
        state.nextAncellaryTraveller = travellerNo
      }
    },

    setAncellarySectionActive: (state, action) => {
      state.AncellarySectionActive = action.payload;
    },
    setAncellariesApiData: (state, action) => {
      state.AncellariesApiData = action.payload;
    },
    setAncellariesLoading: (state, action) => {
      state.AncellariesLoading = action.payload;
    },
    setAncellariesDownloaded: (state, action) => {
      state.AncellariesDownloaded = action.payload;
    },
    setCurrentAncellariesTab: (state, action) => {
      state.currentAncellariesTab[action.payload.index] = action.payload.value;
    },
    setAncellariesTabs: (state, action) => {
      state.AncellariesTabs[action.payload.name] = action.payload.value;
    },
    setselectedAncellaries: (state, action) => {
      state.selectedAncellaries[action.payload.name] = action.payload.value;
    },
    setAncellariesTabsHeadings: (state, action) => {
      state.AncellariesTabsHeadings = action.payload;
    },
    setAncellariesPrice: (state, action) => {
      state.AncellariesPrice[action.payload.name] = action.payload.price;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setReviewPageLoading,
  setReviewPageLoadingPercentage,
  setsearchKey,
  setTheme,
  setitrCode,
  setReviewPageError,
  setReviewPageErrroName,
  setReviewPageErrorDiscription,
  setisResearchQuery,
  setResearchQueryURL,

  setFlightData,
  setFlightDetail,
  setTravellersData,
  setSellResponse,

  setcurrentSection,
  setSearchResultType,

  setappliedCouponFE,
  setOpenPopupMini,
  setOpenPopupMiniType,
  setOpenPopupData,

  setupgradeLoading,
  setupgradeLoadingError,
  setisPriceChanged,
  setPriceChangedData,
  setopenMainPopup,

  setcurrentFarePrice,

  ProceedNext,
  setstartButtonShaking,

  setfirstNameLimit,
  setlastNameLimit,
  setairlineNameFormat,
  setairlineNameFormatAccepted,
  setIsPassportRequired,
  setTravellersDataFilledUp,

  setmainDialCode,
  setmainPhone,
  setmainMail,
  setgstAvailable,
  setgstNumber,
  setgstCompany,
  setcontinueButtonLoading,
  setcontinueButtonError,

  setTravellerTitle,
  setTravellerFirstName,
  setTravellerLastName,
  setTravellerDob,
  setTravellerGender,
  setTravellerCountryDetails,
  setravellerTheme,

  setTravellerPassportNumber,
  setTravellerPassportExpiryDate,
  setTravellerPassportIssueDate,
  setTravellerPassportIssuingCountry,

  setTravellerWheelChairRequested,

  setTravellerfrequentFlyerAvailability,
  setTravellerfrequentFlyerAirline,
  setTravellerfrequentFlyerNumber,
  setTravellerGstData,

  setAncellariesPriceBulk,
  setAncellarySectionActive,
  setAncellariesApiData,
  setAncellariesLoading,
  setAncellariesDownloaded,
  setCurrentAncellariesTab,
  setAncellariesTabs,
  setAncellariesTabsHeadings,

  setAncellariesPrice,
  setTravellersAncellary,
  setSelectTheSeat,
  setSelectTheMeal,
  setSelectTheBag,
  removeTheAncellaries,
  setselectedAncellaries,
  setnextAncellaryTraveller,
  setnextAncellaryTravellerOnLoad,

  sethasSeatsData,
  sethasMealsData,
  sethasBagsData,
  setfareType

} = ReviewPageFlightsSlice.actions;

export default ReviewPageFlightsSlice.reducer;
