import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUrlParams,
  PopularAirprotsFetcher,
} from "../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import LoadingScreen from "./LoadingPages/LoadingScreen";
import { SellTheFlight } from "../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import ErrorResultPage from "../../ErrorPages/ErrorResultPages/ErrorResultPage";
import MainReviewPage from "./MainReviewPage/MainReviewPage";

function ReviewPage() {
  let dispatch = useDispatch();

  let userLocationData = useSelector((state) => state.user);

  useEffect(() => {
    if (userLocationData?.status === "idle") {
      // Send the Url params to the Function
      dispatch(getUrlParams(userLocationData?.UserCurrentLocationData));
      dispatch(PopularAirprotsFetcher());
    }
  }, [userLocationData]);

  useEffect(() => {
    dispatch(SellTheFlight());
  }, []);

    // Disable forward navigation
    useEffect(() => {
      // Push current state to the history stack
      window.history.pushState(null, null, window.location.href);
  
      // Disable the forward button by listening to popstate
      const handlePopState = (event) => {
        window.history.pushState(null, null, window.location.href);
      };
  
      // Add the event listener
      window.addEventListener("popstate", handlePopState);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, []);
    
  let ReviewPageData = useSelector((state) => state.ReviewPageFlight);

  console.log("The ReviewPageData is : " , ReviewPageData);
  
  return (
    <div>
      {ReviewPageData.ReviewPageLoading == "loading" && (
        <LoadingScreen Theme={ReviewPageData.Theme} Message={`Review Page Loading Screen  :  ${ReviewPageData.ReviewPageLoadingPercentage}`}/>
      )}
      {ReviewPageData.ReviewPageLoading == "idle" &&
        ReviewPageData.ReviewPageError == true && (
          <ErrorResultPage
            Theme={ReviewPageData.Theme}
            mainmessage={ReviewPageData.ReviewPageErrroName}
            descriptionmessage={ReviewPageData.ReviewPageErrorDiscription}
            showSingleButton={ReviewPageData.searchKey !== "" && ReviewPageData.isResearchQuery == true}
            singleButtonData={ReviewPageData.ResearchQueryURL}
          />
        )}
      {ReviewPageData.ReviewPageLoading == "idle" &&
        ReviewPageData.ReviewPageError == false && <MainReviewPage Theme={ReviewPageData.Theme}/>}
    </div>
  );
}

export default ReviewPage;
