import React from "react";
import AirlinesLogContainer from "../../../../Components/AirlinesLogContainer";
import { getAllAirlinesWithindex11 } from "../../../../../../../Utils/Flight_results_func";
import Header from "./Header";
import DataBar from "./DataBar";
import ReactJson from "react-json-view";
import TopBar from "./TopBar";

function TableContainer({
  Data,
  ffIndex,
  index,
  ResultType,
  airlineLogos,
  topBarMode,
}) {
  let allAirlines1, allAirlines2, allAirlines3, allAirlines4;

  if (index == undefined) {
    allAirlines1 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos);
  } else if (index == 1) {
    allAirlines1 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 0);
    allAirlines2 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 1);
  } else if (index == 2) {
    allAirlines1 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 0);
    allAirlines2 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 1);
    allAirlines3 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 2);
  } else if (index == 3) {
    allAirlines1 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 0);
    allAirlines2 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 1);
    allAirlines3 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 2);
    allAirlines4 = getAllAirlinesWithindex11(Data, ResultType, airlineLogos, 3);
  }

  console.log("Dtaa her einside the tab;e is  :  " , Data);
  // round-arrow-dark

  return (
    <div className="frame-242-5-cc">
      {index == undefined && (
        <TopBar
          showTripType={false}
          TripVal={""}
          allAirlines={allAirlines1}
          Origin={Data.Segments[0].Origin.Airport.CityName}
          Destination={
            Data.Segments[Data.Segments.length - 1].Destination.Airport.CityName
          }
          showFF={true}
          FFName={
            Data.FareFamilies.FFList[ffIndex].Name.heading == undefined
              ? Data.FareFamilies.FFList[ffIndex].Name
              : Data.FareFamilies.FFList[ffIndex].Name.heading
          }
        />
      )}

      {index == 1 && (
        <>
          <TopBar
            showTripType={true}
            TripVal={topBarMode == 1.5 ? "ONWARD" : "TRIP 1"}
            allAirlines={allAirlines1}
            Origin={Data.Segments[0][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[0][Data.Segments[0].length - 1].Destination.Airport
                .CityName
            }
            showFF={true}
            FFName={
              Data.FareFamilies.FFList[ffIndex].Name.heading == undefined
                ? Data.FareFamilies.FFList[ffIndex].Name
                : Data.FareFamilies.FFList[ffIndex].Name.heading
            }
          />

          <TopBar
            showTripType={true}
            TripVal={topBarMode == 1.5 ? "RETURN" : "TRIP 2"}
            allAirlines={allAirlines2}
            Origin={Data.Segments[1][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[1][Data.Segments[1].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />
        </>
      )}

      {index == 2 && (
        <>
          <TopBar
            showTripType={true}
            TripVal={"TRIP 1"}
            allAirlines={allAirlines1}
            Origin={Data.Segments[0][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[0][Data.Segments[0].length - 1].Destination.Airport
                .CityName
            }
            showFF={true}
            FFName={
              Data.FareFamilies.FFList[ffIndex].Name.heading == undefined
                ? Data.FareFamilies.FFList[ffIndex].Name
                : Data.FareFamilies.FFList[ffIndex].Name.heading
            }
          />

          <TopBar
            showTripType={true}
            TripVal={"TRIP 2"}
            allAirlines={allAirlines2}
            Origin={Data.Segments[1][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[1][Data.Segments[1].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />

          <TopBar
            showTripType={true}
            TripVal={"TRIP 3"}
            allAirlines={allAirlines3}
            Origin={Data.Segments[2][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[2][Data.Segments[2].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />
        </>
      )}

      {index == 3 && (
        <>
          <TopBar
            showTripType={true}
            TripVal={"TRIP 1"}
            allAirlines={allAirlines1}
            Origin={Data.Segments[0][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[0][Data.Segments[0].length - 1].Destination.Airport
                .CityName
            }
            showFF={true}
            FFName={
              Data.FareFamilies.FFList[ffIndex].Name.heading == undefined
                ? Data.FareFamilies.FFList[ffIndex].Name
                : Data.FareFamilies.FFList[ffIndex].Name.heading
            }
          />

          <TopBar
            showTripType={true}
            TripVal={"TRIP 2"}
            allAirlines={allAirlines2}
            Origin={Data.Segments[1][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[1][Data.Segments[1].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />

          <TopBar
            showTripType={true}
            TripVal={"TRIP 3"}
            allAirlines={allAirlines3}
            Origin={Data.Segments[2][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[2][Data.Segments[2].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />

          <TopBar
            showTripType={true}
            TripVal={"TRIP 4"}
            allAirlines={allAirlines4}
            Origin={Data.Segments[3][0].Origin.Airport.CityName}
            Destination={
              Data.Segments[3][Data.Segments[3].length - 1].Destination.Airport
                .CityName
            }
            showFF={false}
            FFName={""}
          />
        </>
      )}

      <div className="frame-242-7-cc">
        <Header />
        <br />
        <small>
          Need the Following's Meaning along with adults , child and infant
          values ,,, This Rule is from :{" "}
          {Data.Source.Company + "_" + Data.Source.Identifier}
        </small>
        <br />
        <ReactJson src={Data.FareFamilies.FFList[ffIndex].MiniRules} />

        <br />
        <small>Design 🔽</small>
        <br />
        <DataBar bbn={false} />
        {/* <DataBar bbn={true} /> */}
      </div>
    </div>
  );
}

export default TableContainer;
