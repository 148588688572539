import React, { useState } from "react";
import FareFamilyContent from "../../Components/FFData/FareFamilyContent";
import images from "../../../../../../Utils/importImages";

function DataBar({ Theme, From, Stops, Refundability, FFName, To }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="frame-24">
      <div className="frame-250">
        <div className={`frame-243 ${Theme}_semibold color_white font16`}>
          <div className="place">{From}</div>
          <div className="frame-170">
            <div className="line-between"></div>
            <div className="frame-202">
              <div className={`address ${Theme}_regular color_white font14`}>
                {Stops}
              </div>
            </div>
            <div className="line-between"></div>
          </div>
          <div className="place">{To}</div>
        </div>
      </div>
      <div className="frame-268">
        <div className="frame-243-1">
          <div
            className={`partially-refundable ${Theme}_semibold color_${
              Refundability === "Refundable" ? "robins-egg-blue" : "cherry"
            } font16`}
          >
            {Refundability}
          </div>
        </div>
        <div className="frame-3">
          <div className={`flexi-economy ${Theme}_semibold color_white font16`}>
            {FFName.Name.heading === undefined
              ? FFName.Name
              : FFName.Name.heading}
          </div>
          <div
            className="ff-inf-tooltip"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={images["ff-info-tooltip-white.svg"]} alt="" />
            {tooltipVisible && (
              <FareFamilyContent
                Theme={Theme}
                topBarSelected={true}
                data={FFName}
                No={0}
                selected={true}
                onSelect={null}
              />
              // <div className="custom-tooltip">
              //  </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataBar;
