import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import images from "../../../../../Utils/importImages";
import "./GstDetails.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setgstAvailable,
  setgstCompany,
  setgstNumber,
} from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function GstDetails({ Theme , hasError}) {
  // let [gstAvailable, changeGstAvailable] = useState(false);

  let dispatch = useDispatch();
  let gstAvailable = useSelector(
    (state) => state.ReviewPageFlight.gstAvailable
  );

  let changeGstAvailable = (value) => {
    dispatch(setgstAvailable(value));
  };

  let gstNumber = useSelector((state) => state.ReviewPageFlight.gstNumber);

  let [gstNumberError, setgstNumberError] = useState("");

  let onChangesetgstNumber = (info) => {
    setgstNumberError("");
    dispatch(setgstNumber(info.target.value));
  };

  const validateGSTNumber = () => {
    if (gstAvailable == true) {
      if (!gstNumber) {
        setgstNumberError("Gst Number is Required");
      } else if (gstNumber.length > 100) {
        setgstNumberError("This Number Can't Exceed 100 Characters");
      } else {
        setgstNumberError("");
      }
    }
  };

  let gstCompany = useSelector((state) => state.ReviewPageFlight.gstCompany);

  let [gstCompanyError, setgstCompanyError] = useState("");

  let onChangesetgstCompany = (info) => {
    setgstCompanyError("");
    dispatch(setgstCompany(info.target.value));
  };

  const validateGSTCompanyName = () => {
    if (gstAvailable == true) {
      if (!gstCompany) {
        setgstCompanyError("Company name is Required");
      } else if (gstCompany.length > 150) {
        setgstCompanyError("This Number Can't Exceed 150 Characters");
      } else {
        setgstCompanyError("");
      }
    }
  };

  useEffect(() => {

    if(hasError == true){
      validateGSTCompanyName()
      validateGSTNumber()
    }
  } , [hasError])

  return (
    <div className={`gst-details ${hasError == true && "shake"}`}>
      <div className="gst-details-bar">
        {" "}
        <img
          className="gst-check"
          src={
            images[
              gstAvailable == true
                ? "travellers-checkbox-checked-blue.svg"
                : "travellers-checkbox-unchecked-blue.svg"
            ]
          }
          alt=""
          onClick={() => changeGstAvailable(!gstAvailable)}
        />
        <div
          className={`${Theme}_semibold color_rhino font16`}
          onClick={() => changeGstAvailable(!gstAvailable)}
        >
          GST Number
        </div>
        <div className={`${Theme}_regular color_light-gray-black font14`}>
          ( Optional )
        </div>
      </div>
      {gstAvailable && (
        <div className="gst-details-container">
          <div className="wrapper">
            <div className="gst">
              <TextField
                onBlur={validateGSTCompanyName} // Validate on blur
                error={!!gstCompanyError}
                helperText={gstCompanyError}
                value={gstCompany}
                onChange={onChangesetgstCompany}
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                sx={{
                  width: "50%",
                  "& .MuiInputLabel-root": {
                    fontSize: "16px",
                    fontFamily: `var(--font-family-${
                      Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                    })`,
                    fontStyle: "normal",
                    fontWeight: "400",
                    "&.Mui-focused": {
                      color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                  },
                }}
              />

              <TextField
                onBlur={validateGSTNumber} // Validate on blur
                error={!!gstNumberError}
                helperText={gstNumberError}
                value={gstNumber}
                onChange={onChangesetgstNumber}
                id="outlined-basic"
                label="GST Number"
                variant="outlined"
                sx={{
                  width: "50%",
                  "& .MuiInputLabel-root": {
                    fontSize: "16px",
                    fontFamily: `var(--font-family-${
                      Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                    })`,
                    fontStyle: "normal",
                    fontWeight: "400",
                    "&.Mui-focused": {
                      color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GstDetails;
