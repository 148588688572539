import React, { useEffect, useState } from "react";

import "./TopBar1.css";
import images from "../../../../../../Utils/importImages";
import { useSelector } from "react-redux";
function TopBar1({setselectedSection , selectedSection , isRound, Mode, ResultType, data , Theme}) {

  let PopupData = useSelector((state) => state.ResultPageFlightsFlight);

  let isOWdd = Mode == 1;

  return (
    <div className={`topbar-wrapper ${Theme}`}>
      <div className="topBar1 frame-243-6">
        <div className="frame-237-3">
          <div
            className={`frame-179 ${
              selectedSection == 0 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => setselectedSection(0)}
          >
            <div className="frame-238-5">
              <div className="frame">
                <div className="frame-2">
                  <div
                    className={`flight-details ${
                      selectedSection == 0
                        ? `${Theme}_regular color_white font11`
                        : `${Theme}_regular color_black font11`
                    }`}
                  >
                    UPGRADE FARE
                  </div>
                </div>
              </div>
             
            </div>
            <div className={`frame-239 ${Theme}`}>
              <div className="frame-238-6">
                {Mode == 1 ? (
                  <div
                    className={`frame-2 ${
                      selectedSection == 0
                        ? `${Theme}_regular color_white font16`
                        : `${Theme}_regular color_eerie-black font16`
                    }`}
                  >
                    <div className="place">
                      {isOWdd == true
                        ? ResultType === 1
                          ? data.Segments[0].Origin.Airport.CityName
                          : data.Connections[0].Segments[0].Origin.Airport
                              .CityName
                        : ResultType === 1
                        ? data.Segments[0][0].Origin.DepTime.substring(11, 16)
                        : data.Connections[0].Segments[0].Origin.DepTime.substring(
                            11,
                            16
                          )}
                    </div>
                    <div className="frame-170-1 frame-170-4">
                      <div
                        className={
                          selectedSection == 0
                            ? "line-between-white"
                            : "line-between-eerie-black"
                        }
                      ></div>
                      <div className="frame-202">
                        <div
                          className={
                            selectedSection === 0
                              ? `address ${Theme}_regular color_white font12`
                              : `address ${Theme}_regular color_eerie-black font12`
                          }
                        >
                          {isOWdd &&
                            (ResultType === 1
                              ? data.Segments.length === 1
                                ? "Non Stop"
                                : data.Segments.length - 1 + " Stops"
                              : (() => {
                                  const totalStops =
                                    data.Connections[0].Segments.length +
                                    data.Connections[1].Segments.length -
                                    2;
                                  return totalStops === 0
                                    ? "1 Stop"
                                    : totalStops + " Stops";
                                })())}
                        </div>
                      </div>
                      <div
                        className={
                          selectedSection == 0
                            ? "line-between-white"
                            : "line-between-eerie-black"
                        }
                      ></div>
                    </div>
                    <div className="place-1">
                      {isOWdd == true
                        ? ResultType === 1
                          ? data.Segments[data.Segments.length - 1].Destination
                              .Airport.CityName
                          : data.Connections[1].Segments[
                              data.Connections[1].Segments.length - 1
                            ].Destination.Airport.CityName
                        : ResultType === 1
                        ? data.Segments[1][data.Segments[1].length - 1]
                            .Destination.Airport.CityName
                        : data.Connections[1].Segments[
                            data.Connections[1].Segments.length - 1
                          ].Destination.Airport.CityName}
                    </div>
                  </div>
                ) : Mode == 1.5 ? (
                  <div
                    className={`frame-2 ${
                      selectedSection == 0
                        ? `${Theme}_regular color_white font16`
                        : `${Theme}_regular color_eerie-black font16`
                    }`}
                  >
                    <div className="place">
                      {data.Segments[0][0].Origin.Airport.CityName}
                    </div>
                    <img
                      src={
                        images[
                          selectedSection == 0
                            ? "round-exchange-icon-light.svg"
                            : "round-exchange-icon-dark.svg"
                        ]
                      }
                      alt="Rectangle 72"
                    />
                    <div className="place-1">
                      {
                        data.Segments[0][data.Segments[0].length - 1]
                          .Destination.Airport.CityName
                      }
                    </div>
                  
                  </div>
                ) : (
                  <div
                    className={`frame-2 ${
                      selectedSection == 0
                        ? `${Theme}_regular color_white font16`
                        : `${Theme}_regular color_eerie-black font16`
                    }`}
                  >
                    <div className="place">
                      {data.Segments[0][0].Origin.Airport.CityName}
                    </div>
                    <img
                      src={
                        images[
                          selectedSection == 0
                            ? "forward-arrow-white.svg"
                            : "forward-arrow-dark.svg"
                        ]
                      }
                      alt="Rectangle 72"
                    />
                    <div className="place-1">
                      {
                        data.Segments[1][data.Segments[1].length - 1]
                          .Destination.Airport.CityName
                      }
                    </div>

                    <div
                      className={`flight-details ${
                        selectedSection == 0
                          ? `${Theme}_regular color_pumice font14`
                          : `${Theme}_regular color_black font14`
                      }`}
                    >
                      Via{" "}
                      {data.Segments.length == 2
                        ? data.Segments[0][data.Segments[0].length - 1]
                            .Destination.Airport.CityName
                        : data.Segments.length == 3
                        ? data.Segments[0][data.Segments[0].length - 1]
                            .Destination.Airport.CityName +
                          " , " +
                          data.Segments[1][data.Segments[1].length - 1]
                            .Destination.Airport.CityName
                        : data.Segments[0][data.Segments[0].length - 1]
                            .Destination.Airport.CityName +
                          " , " +
                          data.Segments[1][data.Segments[1].length - 1]
                            .Destination.Airport.CityName +
                          " , " +
                          data.Segments[2][data.Segments[2].length - 1]
                            .Destination.Airport.CityName}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`frame-176-1 ${
              selectedSection == 1 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => setselectedSection(1)}
          >
            <div className="frame-170-2 frame-170-4">
              <div
                className={`view ${
                  selectedSection == 1
                    ? `${Theme}_regular color_white font16`
                    : `${Theme}_regular color_eerie-black font16`
                }`}
              >
                View Cancellation Rules
              </div>
            </div>
          </div>
          <div
            className={`frame-177 ${
              selectedSection == 2 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => setselectedSection(2)}
          >
            <div className="frame-170-3 frame-170-4">
              <div
                className={`view ${
                  selectedSection == 2
                    ? `${Theme}_regular color_white font16`
                    : `${Theme}_regular color_eerie-black font16`
                }`}
              >
                View Rescheduling Rules
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="topBar1-1">
        <div className="frame-237-33">
          <div>
            {selectedSection == 0 && <div className="down-arrow"></div>}
          </div>
          <div>
            {selectedSection == 1 && <div className="down-arrow"></div>}
          </div>
          <div>
            {selectedSection == 2 && <div className="down-arrow"></div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar1;
