import React, { useEffect, useRef, useState } from "react";
import "./Common.css";
import AirlinesLogContainer from "../../../Components/AirlinesLogContainer";
import images from "../../../../../../Utils/importImages";
import Header from "./Components/Header";
import DataBar from "./Components/DataBar";
import Rules from "./Components/Rules";

function DateChangeContent({Theme}) {
  let allAirlines = [{ logo: "", name: "airindia" }];

  const resultsRef = useRef(null);

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  const checkScrollButtons = () => {
    if (resultsRef.current) {
      setShowScrollButtons(
        resultsRef.current.scrollHeight > resultsRef.current.clientHeight
      );
    }
  };

  const scrollUp = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkScrollButtons();
    if (resultsRef.current) {
      resultsRef.current.addEventListener("scroll", checkScrollButtons);
    }
    return () => {
      if (resultsRef.current) {
        resultsRef.current.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  return (
    <div className="content">
      <div className="frame-242-3-cc">
        {showScrollButtons && (
          <>
            <button onClick={scrollUp} className="scroll-button up-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
            <button onClick={scrollDown} className="scroll-button down-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
          </>
        )}

        <div className={`results-cc ${Theme}`} ref={resultsRef}>
          <div className="frame-238-3-cc">
            <div className="frame-242-4-cc">
              <div className="results-1-cc">
                <div className="frame-242-5-cc">
                  <div className="frame-242-6-cc">
                    <div className="frame-257-cc">
                      <div className="frame-2-cc">
                        <AirlinesLogContainer allAirlines={allAirlines} />
                        <div className="air-india-vistara-lufthansa-cc proxima_regular color_magic-potion font15">
                          Air India, Vistara, Lufthansa
                        </div>
                      </div>
                      <div className="text-362-cc text-cc proxima_regular color_magic-potion font16">
                        |
                      </div>
                      <div className="frame-252-cc">
                        <div className="proxima_semibold color_eerie-black font16 frame-666-cc">
                          <div className="place-4-cc">New Delhi</div>
                          <img
                            src={images["forward-arrow-dark.svg"]}
                            alt="exchange-alt"
                          />
                          <div className="place-4-cc">Melbourne</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-24-cc">
                      <div className="fare-cc proxima_regular color_eerie-black font14">
                        FARE TYPE :
                      </div>
                      <div className="frame-24-cc">
                        <div className="flexi-economy-cc proxima_semibold color_endeavour font16">
                          Flexi Economy
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-242-7-cc">
                  <Header />
                  <DataBar bbn={false}/>
                  <DataBar bbn={true}/>
                  </div>
                </div>
              </div>

             <Rules />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateChangeContent;
