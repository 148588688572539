import React from "react";

import images from "../../../../Utils/importImages";
import { useNavigate } from "react-router-dom";
import { setRecentSearchOnClick } from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";
import { useDispatch } from "react-redux";

function RecentSearches({ data }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const handleSearch = (item) => {
    dispatch(setRecentSearchOnClick(item));
    navigate(`/flights/results?${item.url}`);
  };

  return (
    <div className="frame-43-1">
      <div className="recent-searches proxima_regular color_white font15">
        Recent Searches
      </div>

      {data.map((item, id) => {
        let { url, type, city, date } = item;

        return type === "ow" ? (
          <div
            className="frame-4-1"
            key={item}
            onClick={() => handleSearch(item)}
          >
            <div className="frame-103-12">
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="frame-103-1 proxima_regular color_white font14">
                  <div className="place">{city[0]}</div>
                </div>
                <div className="fri-6-jan-24 proxima_light color_white font12">
                  {date}
                </div>
              </div>

              <div>
                <div className="frame-103-1 proxima_regular color_white font14">
                  <img
                    className="long-arrow-alt-right"
                    src={images["long-arrow-alt-right-6.svg"]}
                    alt="long-arrow-alt-right"
                  />
                </div>
              </div>

              <div>
                <div className="frame-103-1 proxima_regular color_white font14">
                  <div className="place">{city[1]}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="frame-45"
            key={item}
            onClick={() => handleSearch(item)}
          >
            <div className="frame-103-2">
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="frame-103-13 proxima_regular color_white font14">
                  <div className="place-5">{city[0]}</div>
                </div>
                <div
                  className="frame-205-3 frame-205-4 proxima_light color_white font12"
                  style={{
                    paddingTop: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="fri-6-jan-24-1">{date[0]}</div>
                </div>
              </div>
              <div>
                <img
                  className="frame-205-4"
                  src={images["frame-205-4.svg"]}
                  alt="Frame 205"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="frame-103-13 proxima_regular color_white font14">
                  <div className="place-5">{city[1]}</div>
                </div>
                <div
                  className="frame-205-3 frame-205-4 proxima_light color_white font12"
                  style={{
                    paddingTop: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="fri-6-jan-24-1">{date[1]}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RecentSearches;
