import React from "react";
import images from "../../../../../Utils/importImages";
import "./LogoContainer.css";

function LogoContainer({ allAirlines }) {
  return (
    <div>
      {allAirlines?.length === 4 && (
        <div className="four-container">
          <div style={{ display: "flex" }}>
            <div className="logo-4">
              <img
                className="logo-4-img"
                src={
                  allAirlines?.[0].logo === "" ||
                  allAirlines?.[0].logo === undefined
                    ? images["default-airline.png"]
                    : allAirlines?.[0].logo
                }
                alt={allAirlines?.[0].name}
              />
            </div>
            <div className="logo-4">
              <img
                className="logo-4-img"
                src={
                  allAirlines?.[1].logo === "" ||
                  allAirlines?.[1].logo === undefined
                    ? images["default-airline.png"]
                    : allAirlines?.[1].logo
                }
                alt={allAirlines?.[1].name}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="logo-4">
              <img
                className="logo-4-img"
                src={
                  allAirlines?.[2].logo === "" ||
                  allAirlines?.[2].logo === undefined
                    ? images["default-airline.png"]
                    : allAirlines?.[2].logo
                }
                alt={allAirlines?.[2].name}
              />
            </div>
            <div className="logo-4">
              <img
                className="logo-4-img"
                src={
                  allAirlines?.[3].logo === "" ||
                  allAirlines?.[3].logo === undefined
                    ? images["default-airline.png"]
                    : allAirlines?.[3].logo
                }
                alt={allAirlines?.[3].name}
              />
            </div>
          </div>
        </div>
      )}

      {allAirlines?.length === 3 && (
        <div className="three-container">
          <img
            className="logo-3-img-1"
            src={
              allAirlines?.[0].logo === "" ||
              allAirlines?.[0].logo === undefined
                ? images["default-airline.png"]
                : allAirlines?.[0].logo
            }
            alt={allAirlines?.[0].name}
          />

          <img
            className="logo-3-img-2"
            src={
              allAirlines?.[1].logo === "" ||
              allAirlines?.[1].logo === undefined
                ? images["default-airline.png"]
                : allAirlines?.[1].logo
            }
            alt={allAirlines?.[1].name}
          />

          <img
            className="logo-3-img-1"
            src={
              allAirlines?.[2].logo === "" ||
              allAirlines?.[2].logo === undefined
                ? images["default-airline.png"]
                : allAirlines?.[2].logo
            }
            alt={allAirlines?.[2].name}
          />
        </div>
      )}

      {allAirlines?.length === 2 && (
        <div className="two-container">
          <div className="logo-2-1">
            <img
              className="logo-2-img"
              src={
                allAirlines?.[0].logo === "" ||
                allAirlines?.[0].logo === undefined
                  ? images["default-airline.png"]
                  : allAirlines?.[0].logo
              }
              alt={allAirlines?.[0].name}
            />
          </div>
          <div className="logo-2-2">
            <img
              className="logo-2-img"
              src={
                allAirlines?.[1].logo === "" ||
                allAirlines?.[1].logo === undefined
                  ? images["default-airline.png"]
                  : allAirlines?.[1].logo
              }
              alt={allAirlines?.[1].name}
            />
          </div>
        </div>
      )}
      {allAirlines?.length === 1 && (
        <div className="img-container">
          <img
            src={images[allAirlines[0].logo == "" ? "default-airline.png" : ""]}
            alt="logo"
          />
        </div>
      )}
    </div>
  );
}

export default LogoContainer;
