import React, { useState } from "react";
import "./tripChooser.css";
import images from "../../../../../../../../../Utils/importImages";

function TripChooser({ Data, onBarChange, selectedBar , Theme}) {
  console.log("Data is : ", Data);
  return (
    <div>
      <div className={`tripSliderTwo`}>
        <div
          className={`frame-179 ${
            selectedBar == 0 ? `bg-dark-purple ${Theme}` : "bg-white"
          }`}
          onClick={() => onBarChange(0)}
        >
          <div className="frame-238-5">
            <div className="frame">
              <div className="frame-2">
                <div
                  className={`flight-details ${
                    selectedBar == 0
                      ? `${Theme}_regular color_white font11`
                      : `${Theme}_regular color_black font11`
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="frame-239">
            <div className="frame-238-6">
              <div
                className={`frame-2 ${
                  selectedBar == 0
                    ? `${Theme}_regular color_white font14`
                    : `${Theme}_regular color_eerie-black font14`
                }`}
              >
                <div className="place">
                  {Data.Connections[0].Segments[0].Origin.Airport.CityName}
                </div>
                <div className="frame-170-1 frame-170-4">
                  <img
                    src={
                      images[
                        selectedBar == 0
                          ? "forward-arrow-white.svg"
                          : "forward-arrow-dark.svg"
                      ]
                    }
                  />
                </div>
                <div className="place-1">
                  {
                    Data.Connections[0].Segments[
                      Data.Connections[0].Segments.length - 1
                    ].Destination.Airport.CityName
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`frame-179 ${
            selectedBar == 1 ? `bg-dark-purple ${Theme}` : "bg-white"
          }`}
          onClick={() => onBarChange(1)}
        >
          <div className="frame-238-5">
            <div className="frame">
              <div className="frame-2">
                <div
                  className={`flight-details ${
                    selectedBar == 1
                      ? `${Theme}_regular color_white font11`
                      : `${Theme}_regular color_black font11`
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="frame-239">
            <div className="frame-238-6">
              <div
                className={`frame-2 ${
                  selectedBar == 1
                    ? `${Theme}_regular color_white font14`
                    : `${Theme}_regular color_eerie-black font14`
                }`}
              >
                <div className="place">
                  {Data.Connections[1].Segments[0].Origin.Airport.CityName}
                </div>
                <div className="frame-170-1 frame-170-4">
                  <img
                    src={
                      images[
                        selectedBar == 1
                          ? "forward-arrow-white.svg"
                          : "forward-arrow-dark.svg"
                      ]
                    }
                  />
                </div>
                <div className="place-1">
                  {
                    Data.Connections[1].Segments[
                      Data.Connections[1].Segments.length - 1
                    ].Destination.Airport.CityName
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tripSliderTwo-1">
        <div className="sliderBar">
          {selectedBar == 0 && <div className="down-arrow"></div>}
        </div>
        <div className="sliderBar">
          {selectedBar == 1 && <div className="down-arrow"></div>}
        </div>
      </div>
    </div>
  );
}

export default TripChooser;
