import React from 'react'
import AirlinesLogContainer from '../../../../Components/AirlinesLogContainer'
import images from '../../../../../../../Utils/importImages'

function TopBar({allAirlines , Origin , Destination , showFF , FFName , showTripType , TripVal}) {
  return (
<div className="frame-242-6-cc">
        <div className="frame-257-cc">
            {
                showTripType == true &&
        <div className="frame-24-cc">
          <div className="fare-cc proxima_regular color_eerie-black font14" style={{minWidth:"65px"}}>
            {TripVal} :
          </div>
          </div>
            }

          <div className="frame-2-cc">
          
            <AirlinesLogContainer allAirlines={allAirlines} />
            <div className="air-india-vistara-lufthansa-cc proxima_regular color_magic-potion font15">
              {allAirlines?.map((item, index) => (
                <span key={index}>
                  {item.name}
                  {index < allAirlines?.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
                
       
          </div>
          <div className="text-362-cc text-cc proxima_regular color_magic-potion font16">
            |
          </div>
          <div className="frame-252-cc">
            <div className="proxima_semibold color_eerie-black font16 frame-666-cc">
              <div className="place-4-cc">
                {
                  Origin
                }    
              </div>
              <img src={images["forward-arrow-dark.svg"]} alt="exchange-alt" />
              <div className="place-4-cc">
                {
                 Destination
                }
              </div>
            </div>
          </div>
        </div>
        {
            showFF == true && 
        <div className="frame-24-cc">
          <div className="fare-cc proxima_regular color_eerie-black font14">
            FARE TYPE :
          </div>
          <div className="frame-24-cc">
            <div className="flexi-economy-cc proxima_semibold color_endeavour font16">
             {FFName}
            </div>
          </div>
        </div>
        }
      </div>
  )
}

export default TopBar
