import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./index.css";
import images from "../../../../../Utils/importImages";
import FlightDetailsIndicator from "../Components/FlightDetailsIndicator/FlightDetailsIndicator";
import FligthDetails from "../Components/FligthDetails";
import {
  formatTime,
  getTotalMinutes,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import TagsContainer from "../../../../ResultPages/Flights/ResultSection/MainPopup/Components/MainContent/Components/TagsContainer";
import InformationSection from "../Components/InformationSection/InformationSection";
import FareRulesBar from "../Components/FareRulesBar/FareRulesBar";
import UpgradeFareBar from "../Components/UpgradeFareBar/UpgradeFareBar";
import AddCancellationBar from "../Components/AddCancellationBar/AddCancellationBar";
import InsuranceSection from "../Components/InsuranceSection/InsuranceSection";
import MainPopup from "../../MainPopup/MainPopup";
import { useDispatch, useSelector } from "react-redux";
import { Call_Continue1, setcurrentSection } from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import ErrorMessageBar from "../../Components/ErrorMessageBar";

function RoundwayDom({ Theme, Data , startButtonShaking , SearchResultType}) {
  // alert("here");

  let dispatch = useDispatch();

  let FlightRelatedData = Data?.FlightDetail;

  let [OpenPopup, setOpenPopup] = useState(false);

  let [selectedSection, setselectedSection] = useState(0);

  console.log("OpenPopup is : ", OpenPopup);

  let OpenThePopup = (num) => {
    setOpenPopup(true);
    setselectedSection(num);
  };
  let CloseThePopup = () => {
    setOpenPopup(false);
  };

  let [informationChecked , setInformationChecked] = useState(false);
  let [informationCheckedError , setinformationCheckedError] = useState(false);
  let [startShake , setStartShake] = useState(false)

  useEffect(() => {

    let timeoutId;

    if (startShake === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        setStartShake(false);
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);

  } , [startShake])

  const scrollToSection = (name) => {
    const contactWrapper = document.querySelector("."+name);
    if (contactWrapper) {
      contactWrapper.scrollIntoView({ behavior: 'smooth' , block: 'center'}); // You can use 'auto' instead of 'smooth' for instant scrolling
      setinformationCheckedError(true)
      setStartShake(true)
    }
  };

  let continueButtonLoading = useSelector(
    (state) => state.ReviewPageFlight.continueButtonLoading
  );
  
  let continueButtonError = useSelector(
    (state) => state.ReviewPageFlight.continueButtonError
  );
  
  let CheckandProceed = () => {

    if(informationChecked == false){
      scrollToSection("informationCheckError");
    } else {
      dispatch(Call_Continue1())
    }

  }

  return (
    <>
      <div className="main-section-wrapper">

        <div className="main-data-section">
          <div
            className={`flight-details-1 ${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "optera-galaxy"
            } font16`}
          >
            FLIGHT DETAILS
          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={FlightRelatedData}
            isReturn={true}
            isTrip={false}
            TripName={"ONWARDS"}
            Refundability={FlightRelatedData.Connections[0].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    FlightRelatedData.Connections[0].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    FlightRelatedData.Connections[0].Segments[
                      FlightRelatedData.Connections[0].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    FlightRelatedData.Connections[0].Segments.length === 1
                      ? "Non Stop"
                      : FlightRelatedData.Connections[0].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      FlightRelatedData.Connections[0].Segments[0].Origin
                        .DepTime,
                      FlightRelatedData.Connections[0].Segments[
                        FlightRelatedData.Connections[0].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={FlightRelatedData.Connections[0].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(FlightRelatedData.Connections[0].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={FlightRelatedData.Connections[0].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={FlightRelatedData.Connections[0].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(FlightRelatedData.Connections[0].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[0].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[0].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    FlightRelatedData.Connections !== undefined
                      ? FlightRelatedData.Connections[0].Segments[1]
                      : FlightRelatedData[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[0].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(FlightRelatedData.Connections[0].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[0].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[0].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[0].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[0].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[0].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[0].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={FlightRelatedData}
            isReturn={true}
            isTrip={false}
            TripName={"RETURN"}
            Refundability={FlightRelatedData.Connections[1].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    FlightRelatedData.Connections[1].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    FlightRelatedData.Connections[1].Segments[
                      FlightRelatedData.Connections[1].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    FlightRelatedData.Connections[1].Segments.length === 1
                      ? "Non Stop"
                      : FlightRelatedData.Connections[1].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      FlightRelatedData.Connections[1].Segments[0].Origin
                        .DepTime,
                      FlightRelatedData.Connections[1].Segments[
                        FlightRelatedData.Connections[1].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={FlightRelatedData.Connections[1].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(FlightRelatedData.Connections[1].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={FlightRelatedData.Connections[1].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={FlightRelatedData.Connections[1].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(FlightRelatedData.Connections[1].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[1].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    FlightRelatedData.Connections !== undefined
                      ? FlightRelatedData.Connections[1].Segments[1]
                      : FlightRelatedData[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[1].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(FlightRelatedData.Connections[1].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[1].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[1].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[1].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={FlightRelatedData.Connections[1].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={FlightRelatedData.Connections[1].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>


          <div>
            <div
              className={`flight-details-1-1 ${Theme}_semibold color_${
                Theme == "proxima" ? "magic-potion" : "optera-galaxy"
              } font16`}
            >
              FARE RULES
            </div>

            <FareRulesBar
              Theme={Theme}
              OpenThePopup={OpenThePopup}
              mode={2}
              headBarMode={2}
              Data={FlightRelatedData}
            />
            {(FlightRelatedData.Connections[0].FareFamilies.FFList.length >
                  1 ||
                  FlightRelatedData.Connections[1].FareFamilies.FFList.length >
                    1) && (
                  <UpgradeFareBar
                    Theme={Theme}
                    OpenThePopup={OpenThePopup}
                    mode={0}
                  />
                )
          }

            {/* // in future  */}
            {/* <AddCancellationBar Theme={Theme} /> */}
          </div>
        </div>

        <div className="main-data-section">
          <InformationSection Theme={Theme} informationChecked={informationChecked} setInformationChecked={setInformationChecked} informationCheckedError={informationCheckedError} setinformationCheckedError={setinformationCheckedError} startShake={startShake}/>
        </div>

        {/* // in future  */}
        {/* <div className="main-data-section">
        <InsuranceSection Theme={Theme} />
      </div> */}

<div className={`continue-section continue-section-1 ${startButtonShaking == true && "shake"}` }>

{!!continueButtonError == true && (
            <ErrorMessageBar Theme={Theme} Message={continueButtonError} />
          )}

<div
            style={
              continueButtonLoading == true
                ? { opacity: "0.5"}
                : {}
            }
            className={`frame-259 ${Theme}`}
            onClick={() =>
              continueButtonLoading == true ? null : CheckandProceed()
            }
          >
            <div
              className={`continue valign-text-middle ${Theme}_semibold color_white font16`}
            >
              CONTINUE
              {continueButtonLoading == true && <div className="loader"></div>}
            </div>
          </div>
        </div>
        <div className={`others-section ${Theme}`}>
          <div
            className={`text ${Theme}_semibold color_${
              Theme == "proxima" ? "rhino" : "optera-galaxy"
            } font16`}
          >
            {Theme == "proxima" ? "ADD TRAVELLER DETAILS" : "ADD TRAVELLER(S)"}
          </div>
        </div>
        <div className={`others-section ${Theme}`}>
          <div
            className={`text ${Theme}_semibold color_${
              Theme == "proxima" ? "rhino" : "optera-galaxy"
            } font16`}
          >
            {Theme == "proxima" ? "SEATS, MEALS & BAGGAGE" : "ADD-ONS"}
          </div>
        </div>
      </div>

      <MainPopup
        Theme={Theme}
        Open={OpenPopup}
        onClose={CloseThePopup}
        topBarMode={2}
        setselectedSection={setselectedSection}
        selectedSection={selectedSection}
        data={FlightRelatedData}
        isRound={true}
        ResultType={2}
        // showSelect={true}
        // mode={searchBarData?.barModeSelected === 0 ? 0 : 1}
      />
    </>
  );
}

export default RoundwayDom;
