import React from "react";
import {
  setAncellarySectionActive,
  setCurrentAncellariesTab,
} from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import { useDispatch, useSelector } from "react-redux";

function NextButton({ Theme }) {
  const dispatch = useDispatch();

  const currentAncellariesTab = useSelector(
    (state) => state.ReviewPageFlight.currentAncellariesTab
  );

  const AncellarySectionActive = useSelector(
    (state) => state.ReviewPageFlight.AncellarySectionActive
  );

  const AncellariesTabs = useSelector(
    (state) => state.ReviewPageFlight.AncellariesTabs
  );

  const onClickNext = () => {
    if (AncellarySectionActive === "Seats") {
      let currentSeatsOpen = currentAncellariesTab[0];

      if (
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0]] &&
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0]][
          currentSeatsOpen[1] + 1
        ] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 0,
            value: [currentSeatsOpen[0], currentSeatsOpen[1] + 1],
          })
        );
      } else if (
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0] + 1] &&
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0] + 1][0] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 0,
            value: [currentSeatsOpen[0] + 1, 0],
          })
        );
      } else {
        dispatch(setAncellarySectionActive("Meals"));
        dispatch(setCurrentAncellariesTab({ index: 0, value: [0, 0] }));
      }
    } else if (AncellarySectionActive === "Meals") {
      let currentMealsOpen = currentAncellariesTab[1];

      if (
        AncellariesTabs["MealTabs"][currentMealsOpen[0]] &&
        AncellariesTabs["MealTabs"][currentMealsOpen[0]][
          currentMealsOpen[1] + 1
        ] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 1,
            value: [currentMealsOpen[0], currentMealsOpen[1] + 1],
          })
        );
      } else if (
        AncellariesTabs["MealTabs"][currentMealsOpen[0] + 1] &&
        AncellariesTabs["MealTabs"][currentMealsOpen[0] + 1][0] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 1,
            value: [currentMealsOpen[0] + 1, 0],
          })
        );
      } else {
        dispatch(setAncellarySectionActive("Baggage"));
        dispatch(setCurrentAncellariesTab({ index: 1, value: [0, 0] }));
      }
    } else {
      let currentBagsOpen = currentAncellariesTab[2];

      if (
        AncellariesTabs["BagTabs"][currentBagsOpen[0]] &&
        AncellariesTabs["BagTabs"][currentBagsOpen[0]][
          currentBagsOpen[1] + 1
        ] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 2,
            value: [currentBagsOpen[0], currentBagsOpen[1] + 1],
          })
        );
      } else if (
        AncellariesTabs["BagTabs"][currentBagsOpen[0] + 1] &&
        AncellariesTabs["BagTabs"][currentBagsOpen[0] + 1][0] !== undefined
      ) {
        dispatch(
          setCurrentAncellariesTab({
            index: 2,
            value: [currentBagsOpen[0] + 1, 0],
          })
        );
      } else {
        dispatch(setAncellarySectionActive("Seats"));
        dispatch(setCurrentAncellariesTab({ index: 2, value: [0, 0] }));
      }
    }
  };

  const checkNextButtonVisibile = () => {
    if (AncellarySectionActive === "Seats") {
      let currentSeatsOpen = currentAncellariesTab[0];

      if (
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0]] &&
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0]][
          currentSeatsOpen[1] + 1
        ] !== undefined
      ) {
        return true;
      } else if (
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0] + 1] &&
        AncellariesTabs["SeatTabs"][currentSeatsOpen[0] + 1][0] !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    } else if (AncellarySectionActive === "Meals") {
      let currentMealsOpen = currentAncellariesTab[1];

      if (
        AncellariesTabs["MealTabs"][currentMealsOpen[0]] &&
        AncellariesTabs["MealTabs"][currentMealsOpen[0]][
          currentMealsOpen[1] + 1
        ] !== undefined
      ) {
        return true;
      } else if (
        AncellariesTabs["MealTabs"][currentMealsOpen[0] + 1] &&
        AncellariesTabs["MealTabs"][currentMealsOpen[0] + 1][0] !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      let currentBagsOpen = currentAncellariesTab[2];

      if (
        AncellariesTabs["BagTabs"][currentBagsOpen[0]] &&
        AncellariesTabs["BagTabs"][currentBagsOpen[0]][
          currentBagsOpen[1] + 1
        ] !== undefined
      ) {
        return true;
      } else if (
        AncellariesTabs["BagTabs"][currentBagsOpen[0] + 1] &&
        AncellariesTabs["BagTabs"][currentBagsOpen[0] + 1][0] !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  if (checkNextButtonVisibile() == false) {
    return null;
  }
  return (
    <div className="info-btn" onClick={onClickNext}>
      <div className={`${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "choclate"} font16`}>
        Next Flight
      </div>
    </div>
  );
}

export default NextButton;
