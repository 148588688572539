import React, { useRef, useEffect, useState, useMemo } from "react";
import "./index.css";
import FligthDetails from "./Components/FligthDetails";
import images from "../../../../../../Utils/importImages";
import FareFamilyContent from "./Components/FareFamilyContent";
import FFTripInfo from "./Components/FFTripInfo";
// import FFTripInfo from "./Components/FFTripInfo";

import TripChoosers from "./Components/TripChooser/TripChoosers";
import TagsContainer from "./Components/TagsContainer";
import ScrollBar from "./Components/ScrollBar";

function MainContent({
  Data,
  airlineLogos,
  ResultType,
  topBarMode,
  topBarSelected,
  settopBarSelected,
  FFSelected,
  setFFSelected,
  isRound,
  Theme,

}) {

  console.log("topBarSelected is : ", topBarSelected);

  // let [FFSelected , setFFSelected] = useState([0,0,0,0]);



  const SelectTheFF = (index, num) => {
    setFFSelected(prevState => 
      prevState.map((item, i) => i === index ? num : item)
    );
  };

  const resultsRef2 = useRef(null)
  const resultsRef3 = useRef(null)
  
  const [isScrollable, setIsScrollable] = useState(false);

  const checkScrollability = () => {
    if (resultsRef3.current) {
      const scrollable = resultsRef3.current.scrollWidth > resultsRef3.current.clientWidth;
      setIsScrollable(scrollable);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener("resize", checkScrollability);
    return () => {
      window.removeEventListener("resize", checkScrollability);
    };
  }, []);

  const scrollLeft = () => {
    
    if (resultsRef3.current) {

      resultsRef3.current.scrollLeft -= 200;
    }
  };
  
  const scrollRight = () => {
    if (resultsRef3.current) {
      resultsRef3.current.scrollLeft += 200;
    }
  };
  

  console.log("is round here insdie is  : ", isRound);
  return (
    <div className="main-content-popup-review">
      <div className={`frame-221 ${Theme}`}>
        <div className={`frame-241-3 ${Theme}`}>
          <div className="frame-2-5 frame-2-10">
            <div className="frame-241-4">
              <div className="frame-242-11">
                <div
                  className={`more-fare-options ${Theme}_semibold color_${
                    Theme == "proxima" ? "endeavour" : "burning-gold"
                  } font16`}
                >
                  MORE FARE OPTIONS
                </div>
                <div
                  className={`available-for-your-trip ${Theme}_semibold color_${
                    Theme == "proxima" ? "eerie-black" : "deep-ocean"
                  } font16`}
                >
                  available for your trip
                </div>
              </div>
           
              {isScrollable && (
                <div className="ff-btn-wrapper">
                  <img
                    src={images["ff-backward-blue.svg"]}
                    alt="Scroll Left"
                    onClick={scrollLeft}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={images["ff-forward-blue.svg"]}
                    alt="Scroll Right"
                    onClick={scrollRight}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
           
            </div>
          </div>
          {topBarMode == 1 && Data.Connections != undefined && (
            <FFTripInfo
              Theme={Theme}
              chooser={topBarMode == 1 && Data.Connections != undefined}
              Data={Data}
              onBarChange={settopBarSelected}
              selectedBar={topBarSelected}
            />
          )}
          {/* {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
            isRound !== "FromMulSplit" &&
            isRound == false && (
              <FFTripInfo
                Theme={Theme}
                chooser={false}
                Data={Data}
                onBarChange={settopBarSelected}
                selectedBar={topBarSelected}
              />
            )} */}

            <div className="ff-container">
          <div className={`frame-242-12 ${Theme}`} ref={resultsRef3}>
         
            
      
           
            <ScrollBar Theme={Theme} resultsRef2={resultsRef2}/>

              <div
                className={`frame-241-6 ${Theme}`}
                style={
                  ((topBarMode == 1 && Data.Connections != undefined) ||
                  ((topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
                  isRound !== "FromMulSplit" &&
                  isRound == false)) == true
                  ? { maxHeight: "390px" }
                  : {}
                }
             
              >
                {topBarMode == 1 &&
                  (Data.Segments == undefined
                    ? Data.Connections[topBarSelected].FareFamilies.FFList
                    : Data.FareFamilies.FFList
                  ).map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={topBarSelected}
                          data={item}
                          No={index}
                          selected={FFSelected[topBarSelected] == index}
                          onSelect={SelectTheFF}
                        />
 
                      </>
                    );
                  })}

                {(topBarMode == 1.5 || topBarMode == 2.5) &&
                  Data.FareFamilies.FFList.map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={0}
                          data={item}
                          No={index}
                          selected={FFSelected[0] == index}
                          onSelect={SelectTheFF}
                        />
 
                      </>
                    );
                  })}

                {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
                  (Data[topBarSelected] == undefined
                    ? Data.Connections[topBarSelected].FareFamilies.FFList
                    : Data[topBarSelected].FareFamilies.FFList
                  ).map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={topBarSelected}
                          data={item}
                          No={index}
                          selected={FFSelected[topBarSelected] == index}
                          onSelect={SelectTheFF}
                        />
   
                      </>
                    );
                  })}
              </div>
            </div>
          
            </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
