import React from "react";
import images from "../../../Utils/importImages";
import "./ErrorMessage.css";
export default function ErrorMessagePopup({ message , style , addClass , Theme , downArrow}) {
  // console.log("add class is : " , addClass);
  return (
    <div style={style} className={`${downArrow == undefined ? "flight-error-message" : "flight-error-message-100"} ${addClass === true && "flight-error-message-alert"}`}>
      <img className="alert-error-msg" src={images["alert.svg"]} alt="alert" />
      {/* <span className="flightsSprite fltErrorIcon appendRight10"></span> */}
      <span data-cy="sameCityError" className={`redText fltErrorMsgText ${Theme}_regular color_magic-potion font14`}>
        {message}
      </span>
    </div>
  );
}
