import React from "react";
import "./PlaneInfoRow.css";
import images from "../../../../../../Utils/importImages";

function PlaneInfoRow({ color, name, price, Theme }) {

  console.log("name is : " , name ,  " , price are : " , price);
  
  return (
    <div className="plane-info-row">
      <div
        className="identifier-box"
        style={{ borderTop: `5px solid ${color}` }}
      ></div>
      <div className="info-container">
        <div className={`${Theme}_regular font14 color_deep-ocean`}>{name}</div>
        <div className={`${Theme}_semibold font14 color_deep-ocean price`}>
          {price.length == 1 ? (
            <>
            <img src={images["dark-rupee-small.svg"]} alt="img" />
            <div>
            {price[0]}
            </div>
            
            </>
          ) : (
            <>
            <div>
            <img src={images["dark-rupee-small.svg"]} alt="img" />
            {price[0]}
            </div>
            <div>-</div>
            <div>
            <img src={images["dark-rupee-small.svg"]} alt="img" />
            {price[1]}
            </div>
            
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlaneInfoRow;
