import React from "react";
import "./SeatRow.css";
import Seat from "./Seat";
import { useSelector } from "react-redux";
import { getSeatInfo } from "../../../../../../Utils/Flight_review_func";

function SeatRow({
  Data,
  Theme,
  maxLength,
  currentPassenger,
  onChanegCurrentPassenger,
  nextUnselectedTraveller,
}) {
  let selectedAncellaries = useSelector(
    (state) => state.ReviewPageFlight.selectedAncellaries.Seats
  );
  let currentAncellariesTab = useSelector(
    (state) => state.ReviewPageFlight.currentAncellariesTab[0]
  );

  let getRowNo = (seats) => {
    let rowNo = null;
    seats.forEach((item) => {
      if (item.RowNo !== null) {
        rowNo = item.RowNo;
        return; // Exit the loop once a non-null RowNo is found
      }
    });
    return rowNo;
  };



  let RowNo = getRowNo(Data);

  if (Data.length > maxLength) {
    return (
      <div className="seat-row-wrapper">
        {Data.slice(0, maxLength).map((item, id) => (
          <div
            onClick={null}
            key={id}
            style={{ visibility: "hidden" }}
            className={`main-seat ${Theme}_regular font12 color_deep-ocean seat-wrapper`}
          >
            {item.SeatNo} {/* or any other field you want to display */}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="seat-row-wrapper">
        {Data?.map((item, id) => {

          // Generate the seat key
          let seatKey = `${item.Code}-${currentAncellariesTab[0]}-${currentAncellariesTab[1]}`;

          // Check if the seat is selected
          let isSeatSelected = selectedAncellaries.find(
            (seat) => seat.seatKey === seatKey
          );

          let toolTipData = getSeatInfo(item , true)  ;

          return (
            <Seat
              ActiveCode={isSeatSelected?.TrNo == currentPassenger}
              classNameOfSeat={seatKey}
              isSeatSelected={isSeatSelected !== undefined}
              nextUnselectedTraveller={nextUnselectedTraveller}
              selectiondata={isSeatSelected?.initials}
              currentPassenger={currentPassenger}
              onChanegCurrentPassenger={onChanegCurrentPassenger}
              item={item}
              id={id}
              Theme={Theme}
              RowNo={RowNo}
              ToolTiipData={toolTipData}
            />
          );
        })}
      </div>
    );
  }
}

export default SeatRow;
