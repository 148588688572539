import React, { useState } from "react";
import images from "../../../../../../Utils/importImages";
import { head } from "lodash";

function FareSection({
  hasBorder,
  Theme,
  heading,
  headingValue,
  coveredBarData,
  convenienceCharges
}) {
  let [openBar, setOpenBar] = useState(false);

  console.log("coveredBarData is : ", coveredBarData);

  return (
    <div
      className={`component ${Theme}`}
      style={hasBorder == true ? {} : { border: "none" }}
    >
      <div className="frame-24" onClick={() => setOpenBar(!openBar)}>
        <div className="frame-293-2 frame-293-10">
          <div
            className={`base-fare ${Theme}_semibold color_blue-zodiac font16`}
          >
            {heading}
          </div>
          <img
            className="frame-3"
            src={
              images[
                Theme == "proxima"
                  ? "fare-down-arrow-blue.svg"
                  : "fare-down-arrow-gold.svg"
              ]
            }
            style={openBar == true ? { transform: "rotate(180deg)" } : {}}
            alt="Frame 243"
          />
        </div>
        <div className="frame-173-1 frame-173-4">
          {heading == "Discounts" && (
            <div
              className={`text-65 valign-text-middle text-1 ${Theme}_semibold color_${
                Theme == "proxima" ? "pine-green" : "parrot"
              } font16`}
            >
              -
            </div>
          )}
          <img
            className="frame-3"
            src={
              images[
                heading !== "Discounts"
                  ? "frame-170.svg"
                  : Theme == "proxima"
                  ? "light-green-rupee.svg"
                  : "dark-green-rupee.svg"
              ]
            }
            alt="Frame 170"
          />
          <div
            className={`text-65 valign-text-middle text-1 ${Theme}_semibold color_${
              (heading !== "Discounts" || heading !== "Add-Ons")
                ? "eerie-black"
                : Theme == "proxima"
                ? "pine-green"
                : "parrot"
            } font16`}
          >
            {headingValue}
          </div>
        </div>
      </div>
      {openBar && (
        <div className="frame-5">
          {(heading == "Base Fare" || heading == "Tax & Other Charges") &&
          <>
          {coveredBarData.map((item, id) => {
            return (
              <div className="frame-24-1 frame-24-3">
                <div className="frame-24">
                  <div
                    className={`frame-243-5 ${Theme}_regular color_eerie-black font15`}
                  >
                    <div className="adult">{item.name} (</div>
                    <div className="x1-x">{item.multiplier} x</div>
                    <div className="frame-24-2 frame-24-3">
                      <img
                        src={images["small-rupee-icon.svg"]}
                        alt="Frame 169"
                      />
                      <div
                        className={`text-66 text-1 ${Theme}_regular color_eerie-black font15`}
                      >
                        {item.multiplierValue} )
                      </div>
                    </div>
                  </div>
                  <div className="frame-173-2 frame-173-4">
                    <img
                      className="frame-3"
                      src={images["frame-169-2.svg"]}
                      alt="Frame 169"
                    />
                    <div
                      className={`text-67 valign-text-middle text-1 ${Theme}_regular color_storm-dust font15`}
                    >
                      {item.endValue}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          
          {
           ( heading === "Tax & Other Charges" && convenienceCharges !== undefined && convenienceCharges !== 0 ) && 
           <div className="frame-24-1 frame-24-3">
                <div className="frame-24">
                  <div
                    className={`frame-243-5 ${Theme}_regular color_eerie-black font15`}
                  >
                    <div className="adult">Convenience Charges</div>
                    
                  </div>
                  <div className="frame-173-2 frame-173-4">
                    <img
                      className="frame-3"
                      src={images["frame-169-2.svg"]}
                      alt="Frame 169"
                    />
                    <div
                      className={`text-67 valign-text-middle text-1 ${Theme}_regular color_storm-dust font15`}
                    >
                      {convenienceCharges}
                    </div>
                  </div>
                </div>
              </div>
          }
          </>
            
            
            }


          {heading == "Discounts" &&

            coveredBarData.map((item, id) => {
              return (
                <div className="frame-24-1 frame-24-3">
                  <div className="frame-24">
                    <div
                      className={`frame-243-5 ${Theme}_regular color_eerie-black font15`}
                    >
                      <div className="adult">{item.Name} </div>
                    </div>
                    <div className="frame-173-2 frame-173-4">
                      <div
                        className={`text-67 valign-text-middle text-1 ${Theme}_regular color_${
                          Theme == "proxima" ? "pine-green" : "parrot"
                        } font15`}
                      >
                        -
                      </div>
                      <img
                        className="frame-3"
                        src={
                          images[
                            heading !== "Discounts"
                              ? "frame-169-2.svg"
                              : Theme == "proxima"
                              ? "light-green-rupee.svg"
                              : "dark-green-rupee.svg"
                          ]
                        }
                        alt="Frame 169"
                      />
                      <div
                        className={`text-67 valign-text-middle text-1 ${Theme}_regular color_${
                          Theme == "proxima" ? "pine-green" : "parrot"
                        } font15`}
                      >
                        {item.Value}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {heading == "Add-Ons" &&
            coveredBarData.map((item, id) => {
              if (item.Value === 0) return null; // Skip rendering if item.Value is 0
              
              return (
                <div className="frame-24-1 frame-24-3">
                  <div className="frame-24">
                    <div
                      className={`frame-243-5 ${Theme}_regular color_eerie-black font15`}
                    >
                      <div className="adult">{item.Name} </div>
                    </div>
                    <div className="frame-173-2 frame-173-4">
                      <img
                        className="frame-3"
                        src={
                          images[
                            heading !== "Discounts"
                              ? "frame-169-2.svg"
                              : Theme == "proxima"
                              ? "light-green-rupee.svg"
                              : "dark-green-rupee.svg"
                          ]
                        }
                        alt="Frame 169"
                      />
                      <div
                        className={`text-67 valign-text-middle text-1 ${Theme}_regular color_storm-dust font15`}
                      >
                        {item.Value}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default FareSection;
