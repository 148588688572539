import React, { useEffect, useState } from "react";
import "./MainPopup.css"; // Make sure to create this CSS file for styling
import MainContent from "./Components/MainContent";
import BottomBar from "./Components/BottomBar";
import CancellationContent from "./Components/Content/CancellationContent";
import { useSelector } from "react-redux";
import TopBar1 from "./Components/TopBar/TopBar1";
import TopBar2 from "./Components/TopBar/TopBar2";
import TopBar3 from "./Components/TopBar/TopBar3";
import TopBar4 from "./Components/TopBar/TopBar4";
import DateChangeContent from "./Components/Content/DateChangeContent";

function MainPopup({
  Open,
  onClose,
  topBarMode,
  topBarSelected,
  isRound,
  ResultType,
  data,
  showSelect,
  onSelect,
  mode,
  setselectedSection,
  selectedSection,

  Theme,
}) {

  // console.log("Data Here at the Main popup : ", data);

  let [FFSelected , setFFSelected] = useState([0,0,0,0]);

  console.log("FFSelected is  :  , : " , FFSelected);
  
  let [topBarSelectedd , settopBarSelectedd] = useState(0);
  if (!Open) return null; // If Open is false, don't render the popup

  return (
    <div className="popup-overlay-main-review" onClick={onClose}>
      <div
        className={`popup-content ${Theme}`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Top Bar Starts */}
        <div className="popup-content-layer">
          {(topBarMode == 1 || topBarMode == 1.5 || topBarMode == 2.5) && (
            <TopBar1
              Theme={Theme}
              setselectedSection={setselectedSection}
              selectedSection={selectedSection}
              Mode={topBarMode}
              isRound={isRound}
              ResultType={ResultType}
              data={data}
            />
          )}
          {topBarMode == 2 && (
            <TopBar2
              Theme={Theme}
              setselectedSection={setselectedSection}
              selectedSection={selectedSection}
              settopBarSelectedd={settopBarSelectedd}
              isTrip={isRound}
              ResultType={ResultType}
              data={data}
            />
          )}
          {topBarMode == 3 && (
            <TopBar3
              Theme={Theme}
              setselectedSection={setselectedSection}
              selectedSection={selectedSection}
              settopBarSelectedd={settopBarSelectedd}
              isTrip={isRound}
              ResultType={ResultType}
              data={data}
            />
          )}
          {topBarMode == 4 && (
            <TopBar4
              Theme={Theme}
              setselectedSection={setselectedSection}
              selectedSection={selectedSection}
              settopBarSelectedd={settopBarSelectedd}
              ResultType={ResultType}
              isTrip={isRound}
              data={data}
            />
          )}

          {/* Top Bar ends  */}

          {/* Main Content Starts  */}

          {(topBarMode == 1 || topBarMode == 1.5 || topBarMode == 2.5) &&
            selectedSection == 0 && (
              <MainContent
                Theme={Theme}
                FFSelected={FFSelected}
                setFFSelected={setFFSelected}
                topBarSelected={topBarSelectedd}
                settopBarSelected={settopBarSelectedd}
                topBarMode={topBarMode}
                Data={data}
                
                ResultType={ResultType}
              />
            )}
          {topBarMode == 2 && selectedSection < 2 && (
            <MainContent
              Theme={Theme}
              FFSelected={FFSelected}
              setFFSelected={setFFSelected}
              topBarSelected={topBarSelectedd}
              settopBarSelected={settopBarSelectedd}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              isRound={isRound}
            />
          )}
          {topBarMode == 3 && selectedSection < 3 && (
            <MainContent
              Theme={Theme}
              FFSelected={FFSelected}
              setFFSelected={setFFSelected}
              topBarSelected={topBarSelectedd}
              settopBarSelected={settopBarSelectedd}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              isRound={isRound}
            />
          )}
          {topBarMode == 4 && selectedSection < 4 && (
            <MainContent
              Theme={Theme}
              FFSelected={FFSelected}
              setFFSelected={setFFSelected}
              topBarSelected={topBarSelectedd}
              settopBarSelected={settopBarSelectedd}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              isRound={isRound}
            />
          )}

          {/* Main Content Ends   */}

          {(topBarMode == 1 || topBarMode == 1.5 || topBarMode == 2.5) &&
            selectedSection == 1 && (
              <CancellationContent
                Theme={Theme}
                topBarMode={topBarMode}
                Data={data}
                
                ResultType={ResultType}
                topBarSelected={topBarSelectedd}
                FFSelected={FFSelected}
              />
            )}
          {topBarMode == 2 && selectedSection == 2 && (
            <CancellationContent
              Theme={Theme}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              topBarSelected={topBarSelectedd}
              FFSelected={FFSelected}
            />
          )}
          {topBarMode == 3 && selectedSection == 3 && (
            <>
              <CancellationContent
                Theme={Theme}
                topBarMode={topBarMode}
                Data={data}
                
                ResultType={ResultType}
                topBarSelected={topBarSelectedd}
                FFSelected={FFSelected}
              />
            </>
          )}
          {topBarMode == 4 && selectedSection == 4 && (
            <CancellationContent
              Theme={Theme}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              topBarSelected={topBarSelectedd}
              FFSelected={FFSelected}
            />
          )}

          {/* Cancellation Content Ends   */}

          {/* DateChange Content Starts  */}

        {(topBarMode == 1 || topBarMode == 1.5 || topBarMode == 2.5) &&
            selectedSection == 2 && (
              <DateChangeContent
                Theme={Theme}
                topBarMode={topBarMode}
                Data={data}
                
                ResultType={ResultType}
                topBarSelected={topBarSelectedd}
              />
            )}
          {topBarMode == 2 && selectedSection == 3 && (
            <DateChangeContent
              Theme={Theme}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              topBarSelected={topBarSelectedd}
            />
          )}
          {topBarMode == 3 && selectedSection == 4 && (
            <DateChangeContent
              Theme={Theme}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              topBarSelected={topBarSelectedd}
            />
          )}
          {topBarMode == 4 && selectedSection == 5 && (
            <DateChangeContent
              Theme={Theme}
              topBarMode={topBarMode}
              Data={data}
              
              ResultType={ResultType}
              topBarSelected={topBarSelectedd}
            />
          )}

          {/* Datechange Content Ends   */}

          <BottomBar
            Theme={Theme}
            FFSelected={FFSelected}
            topBarSelected={topBarSelectedd}
            settopBarSelectedd={settopBarSelectedd}
            selectedSection={selectedSection}
            setselectedSection={setselectedSection}
            topBarMode={topBarMode}
            Data={data}
            showSelect={showSelect}
            onSelect={onSelect}
            
            mode={mode}
          />
        </div>
      </div>
    </div>
  );
}

export default MainPopup;
