import React, { useEffect, useRef, useState } from "react";
import images from "../../../../../../../Utils/importImages";

function ScrollBar({ resultsRef2, Theme }) {
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [scrollThumbHeight, setScrollThumbHeight] = useState(0);
  const thumbRef = useRef(null);
  const isDragging = useRef(false);
  const startY = useRef(0);
  const startScrollTop = useRef(0);

  const scrollUp2 = () => {
    if (resultsRef2.current) {
      resultsRef2.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown2 = () => {
    if (resultsRef2.current) {
      resultsRef2.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  const updateScrollThumb = () => {
    const scrollContainer = resultsRef2.current;
    const scrollRatio = scrollContainer.clientHeight / scrollContainer.scrollHeight;
    const thumbHeight = scrollContainer.clientHeight * scrollRatio;
    const thumbPosition = scrollContainer.scrollTop * scrollRatio;

    setScrollThumbHeight(thumbHeight);
    setScrollThumbPosition(thumbPosition);
    setIsScrollable(scrollContainer.scrollHeight > scrollContainer.clientHeight);
  };

  const onThumbMouseDown = (e) => {
    isDragging.current = true;
    startY.current = e.clientY;
    startScrollTop.current = resultsRef2.current.scrollTop;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;

    const scrollContainer = resultsRef2.current;
    const scrollRatio = scrollContainer.scrollHeight / scrollContainer.clientHeight;
    const deltaY = (e.clientY - startY.current) * scrollRatio;

    scrollContainer.scrollTop = startScrollTop.current + deltaY;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    const scrollContainer = resultsRef2.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollThumb);
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);

      updateScrollThumb();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", updateScrollThumb);
      }
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return (
    <>
      {isScrollable && (
        <>
          <button onClick={scrollUp2} className="scroll-button up-button-2-2">
            <img src={images["scroll-btn-img.svg"]} alt="img" />
          </button>
          <button onClick={scrollDown2} className="scroll-button down-button-2-2">
            <img src={images["scroll-btn-img.svg"]} alt="img" />
          </button>

          <div className={`custom-scrollbar ${Theme}`}>
            <div
              ref={thumbRef}
              className={`scroll-thumb ${Theme}`}
              style={{
                height: `${scrollThumbHeight - 30}px`,
                top: `${scrollThumbPosition}px`,
              }}
              onMouseDown={onThumbMouseDown}
            ></div>
          </div>
        </>
      )}
    </>
  );
}

export default ScrollBar;
