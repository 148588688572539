import React, { useEffect, useState } from "react";
import "./MealsContentSection.css";
import TravellerRow from "../Components/TravellerRow";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import { setnextAncellaryTraveller, setnextAncellaryTravellerOnLoad, setSelectTheMeal } from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import ErrorComponent from "../Components/ErrorComponent";

function MealsContentSection({ Theme, currentSelectedTab, currentMealsData }) {

  let dispatch  = useDispatch()

  let TravellersData = useSelector(
    (state) => state.ReviewPageFlight.TravellersDataFilledUp
  );

  const scrollToSection = (name) => {
    const wrapper = document.querySelector("." + name);
    if (wrapper) {
      wrapper.scrollIntoView({ behavior: "smooth", block: "center" }); // You can use 'auto' instead of 'smooth' for instant scrolling
    }
  };

  // auto scroll color  : #f6f6f6 

  let nextAncellaryTraveller = useSelector(
    (state) => state.ReviewPageFlight.nextAncellaryTraveller
  );

  let selectedtheTraveller = (curTraveller) => {
    dispatch(setnextAncellaryTraveller(curTraveller))
  };
  
  useEffect(() => {
    // set the current unselected traveller on load of screen 
  dispatch(setnextAncellaryTravellerOnLoad(1))
  
  } , [currentSelectedTab])

  useEffect(() => {
    scrollToSection("isActiveMeal");
  } , [nextAncellaryTraveller])

  console.log("currentMealsData is : ", currentMealsData);

  let onClickMealBtn = (item , action , mealIndex) => {
    dispatch(setSelectTheMeal({data : item , travellersIndex : nextAncellaryTraveller , Keyaction : action , mealIndex , keyname : currentMealsData?.keyname}));
  }

  return (
      currentMealsData.error == true ? (
        <ErrorComponent Theme={Theme} Message={currentMealsData.errorMessage.Message} Heading={currentMealsData.errorMessage.heading} type="Meal" />
      ) : (
       
    <div className="meal-section">
          <div className="data-section">
            <div className="travellers-content">
              {TravellersData?.map((item, id) => {
                   if(item.type === "INF") return null;
                return (
                  <TravellerRow
                    onTravellerSelection={selectedtheTraveller}
                    id={id}
                    item={item}
                    RequestText={"Please Select Your Meal"}
                    isActive={nextAncellaryTraveller == id}
                    name={`${item.firstName} ${item.lastName}`}
                    Theme={Theme}
                    isSelected={
                      item.ancellaries.meals[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].mealRequested !== "" &&
                      item.ancellaries.meals[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].mealRequested !== null
                    }
                    Data={
                      (item.ancellaries.meals[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].mealRequested !== "" &&
                        item.ancellaries.meals[currentSelectedTab[0]][
                          currentSelectedTab[1]
                        ].mealRequested !== null) == true &&
                      item.ancellaries.meals[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].mealRequested.Name
                    }
                    price={
                      (item.ancellaries.meals[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].mealRequested !== "" &&
                        item.ancellaries.meals[currentSelectedTab[0]][
                          currentSelectedTab[1]
                        ].mealRequested !== null) == true
                        ? item.ancellaries.meals[currentSelectedTab[0]][
                            currentSelectedTab[1]
                          ].mealRequested.Price
                        : 0
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className={`meals-section ${Theme}`}>
            <div className="meals-wrapper">
              {currentMealsData.SeactionTabData.map((item, id) => {

                let ActiveCode = item.Traveller == undefined ? false : item.Traveller.includes(nextAncellaryTraveller);
                return (
                  <div className={`meal-item ${ActiveCode && "isActiveMeal"}`}>
                    <div className="meal-info-container">
                      <div>
                        <img src={images["meal-icon-main.svg"]} alt="meal" />
                      </div>
                      <div className="meal-info">
                        <div
                          className={`${Theme}_regular font16 color_gray-black meal-name`}
                        >
                          {item.Name}
                        </div>

                        <div
                          className={`${Theme}_semibold color_${
                            item.Price == 0 ? "pine-green" : "deep-ocean"
                          } font14`}
                        >
                          {item.Price == 0 ? "FREE" : item.Price}
                        </div>
                      </div>
                    </div>

                    <div className="meal-controllers-container">
                      {item.Quantity > 0 ?  <img src={images[Theme == "proxima" ? "ancellary-minus-glow.png" : "ancellary-minus-glow-gold.png"]} alt="" onClick={() => onClickMealBtn(item , '-' , id)}/> : <img src={images["ancellary-minus-dull.png"]} alt="" />}

                      <div
                        className={`${Theme}_regular font16 color_deep-ocean`}
                      >
                        {item.Quantity}
                      </div>
                      {item.Quantity == TravellersData.length ? <img src={images["ancellary-plus-dull.png"]} alt="" /> : <img onClick={() => onClickMealBtn(item , '+' , id)} src={images[Theme == "proxima" ? 'ancellary-plus-glow.png' : 'ancellary-plus-glow-gold.png']} alt="" /> }
          
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
       
        </div>
        )
  );
}

export default MealsContentSection;
