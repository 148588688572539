import React from 'react'
import "./AlphabetRow.css"

function AlphabetRow({Theme  ,  RowData}) {
  return (
    <div className="alphabet-row-wrapper">

    {RowData?.map((item, id) => {
      return (
        <div
          className="heading"
          style={item == "0" ? { visibility: "hidden" } : {}}
        >
          <div
            className={`${Theme}_regular color_eerie-black font10`}
          >
            {item}
          </div>
        </div>
      );
    })}
  </div>
  )
}

export default AlphabetRow
