import React from 'react'
import images from '../../../Utils/importImages'
import "./errorBar.css";

function ErrorBar({Theme , Message}) {
  return (
    <div className="flight-error-message-2">
    <img
      className="alert-error-msg"
      src={images["alert.svg"]}
      alt="alert"
    />
    <span
      data-cy="sameCityError"
      className={`redText fltErrorMsgText ${Theme}_regular color_magic-potion font14`}
    >
      {Message}
    </span>
  </div>
  )
}

export default ErrorBar
