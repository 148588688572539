import React, { useEffect, useState } from 'react';
import images from '../../../Utils/importImages'; // Adjust the path according to your folder structure
import { Link, useLocation } from 'react-router-dom';
import "./index.css"

function Header() {

  const location = useLocation();
  const [activeOption, setActiveOption] = useState(1);

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setActiveOption(1);
        break;
      case '/flights':
        setActiveOption(1);
        break;
      case '/flights/results':
        setActiveOption(1);
        break;
      case '/hotels':
        setActiveOption(2);
        break;
      case '/packages':
        setActiveOption(3);
        break;
      default:
        setActiveOption(0);
    }
  }, [location.pathname]);

  return (
    <>

    <div className="topbar-navbar-navigation">
      <div className="frame-98">
        <div className="frame-99">
          <div className="frame-99-1">
            <Link to="/">
              <div className="frame-100">
                <div className="group-168">
                  <div className="overlap-group">
                    {/* <div className="group-149"></div> */}
                    <img
                    height={30}
                    width={35}
                      // className="vector-68"
                      src={images['mainlogo.png']}
                      alt="Vector 68"
                    />
    
                  </div>
                </div>
                <div className="stfares">STFARES</div>
              </div>
            </Link>
            <div className="frame-9">
              <div className="frame-19">
                <Link to="/flights">
                <div className="frame-99-4">
                  <div className="group-108">
                    <div className="overlap-group4">
                      <div className="group-107">
                        <div className="overlap-group3">
                          <img
                            className="vector-2"
                            src={images['vector-2-6.svg']}
                            alt="Vector 2"
                          />
                          <img
                            className="vector-3"
                            src={images['vector-3-6.svg']}
                            alt="Vector 3"
                          />
                          <div className="group-105">
                            <div className="overlap-group2">
                              <img
                                className="line-6"
                                src={images['line-6-13.svg']}
                                alt="Line 6"
                              />
                              <div className="line-container">
                                <img
                                  className="line-7"
                                  src={images['line-7-13.svg']}
                                  alt="Line 7"
                                />
                                <img
                                  className="line-8"
                                  src={images['line-8-13.svg']}
                                  alt="Line 8"
                                />
                              </div>
                            </div>
                          </div>
                          <img
                            className="vector-4"
                            src={images['vector-4-6.svg']}
                            alt="Vector 4"
                          />
                        </div>
                        <div className="group-104">
                          <img
                            className="line-6-1"
                            src={images['line-6-12.svg']}
                            alt="Line 6"
                          />
                          <div className="line-container-1">
                            <img
                              className="line-7-1"
                              src={images['line-7-12.svg']}
                              alt="Line 7"
                            />
                            <img
                              className="line-8-1"
                              src={images['line-8-12.svg']}
                              alt="Line 8"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="vector-8"
                        src={images['vector-8-6.svg']}
                        alt="Vector 8"
                      />
                    </div>
                  </div>
                  <div className={`flights valign-text-middle ${activeOption === 1 ? "proxima_semibold color_magic-potion font16" : "proxima_regular color_eerie-black font16"}`}>
                    FLIGHTS
                  </div>
                </div>
                </Link>
              </div>
              <div className="frame-27">
              <Link to="/hotels">
                <div className="frame-99-4">
                  <img
                    className="frame-128"
                    src={images['frame-128-6.svg']}
                    alt="Frame 128"
                  />
                  <div className={`hotels valign-text-middle ${activeOption === 2 ? "proxima_semibold color_magic-potion font16" : "proxima_regular color_eerie-black font16"}`}>
                    HOTELS
                  </div>
                </div>
                </Link>
              </div>
              <div className="frame-23">
                <Link to="/packages">
                <div className="frame-99-4">
                  <img
                    className="hotel_building_stars"
                    src={images['hotel-building-stars-holiday-vacation-icon-196736-1-3.svg']}
                    alt="hotel_building_stars_holiday_vacation_icon_196736 1"
                  />
                  <div className={`packages valign-text-middle ${activeOption === 3 ? "proxima_semibold color_magic-potion font16" : "proxima_regular color_eerie-black font16"}`}>
                    PACKAGES
                  </div>
                </div>   
                </Link>
              </div>
            </div>
          </div>
          <div className="frame-100-1 frame-100-4">
            {/* <div className="frame-8">
              <div className="business-travel proxima_regular color_black font16">
                BUSINESS TRAVEL
              </div>
            </div> */}
            <div className="frame-9-2">
              <div className="login-signup">Login / Signup</div>
            </div>
            <div className="frame-8-1">
              <div className="frame-266">
                <div className="en-inr proxima_regular color_black font16">
                  EN / INR
                </div>
                <img className="vector" src={images['vector-14.svg']} alt="Vector" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    </>
  );
}

export default Header;
