import React, { useEffect, useState } from "react";
import {
  PopularAirprotsFetcher,
  SearchTheFlights,
  getUrlParams,
  setopenMainPopup,
} from "../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import { useDispatch, useSelector } from "react-redux";
import ResultPageSearchBar from "./SearchBar/ResultPageSearchBar";

import "./ResultPage.css";
import ResultPageLoading from "./LoadingPages/ResultPageLoading";
import ResultPageData from "./ResultSection/ResultPageData";
import images from "../../../Utils/importImages";
import ErrorResultPage from "../../ErrorPages/ErrorResultPages/ErrorResultPage";
import ResultBarSection from "./ResultSection/ResultBarSection/ResultBarSection";
import MainPopup from "./ResultSection/MainPopup/MainPopup";

export default function ResultPage() {
  let dispatch = useDispatch();

  let userLocationData = useSelector((state) => state.user);

  useEffect(() => {
    if (userLocationData?.status === "idle") {
      // Send the Url params to the Function
      dispatch(getUrlParams(userLocationData?.UserCurrentLocationData));
      dispatch(PopularAirprotsFetcher());
    }
  }, [userLocationData]);

  let resultPageData = useSelector((state) => state.ResultPageFlightsFlight);

  useEffect(() => {
    if (resultPageData?.StartTheSearch === true) {
      dispatch(SearchTheFlights(resultPageData?.SearchParameters));
    }
  }, [resultPageData?.StartTheSearch]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let [PopupBarMode, setPopupBarMode] = useState(0);

  let [OpenPopup, setOpenPopup] = useState(false);

  let OpenThePopup = (val) => {
    setOpenPopup(val);
  };
  let CloseThePopup = () => {
    setOpenPopup(false);
  };

  console.log("resultPageData : " , resultPageData);

  let FontTheme = (resultPageData.SearchResultType === 3 && resultPageData.FilteredData !== null) == true ? "proxima" : (resultPageData?.PageTheme == 1 ? "proxima" : "averta");

  return (
    <div className={`result-page-flights-section ${FontTheme} ${((resultPageData?.SearchResultType == 1 || resultPageData?.SearchResultType == 2 || resultPageData?.SearchResultType == 3 || resultPageData?.SearchResultType == 5 || resultPageData?.SearchResultType == 7 || resultPageData?.SearchResultType == 9) && FontTheme == "averta") == true ? "noPad" : "yesPad"}`}>
      
      <ResultPageSearchBar
         Theme={FontTheme}
          isSticky={isSticky}
          loadingPorgessBar={
            resultPageData.SearchResultsStatus === "Loading" && FontTheme && (
              <div className="progressbar-aKn7aU">
                <div className={`value-WUZgUu ${FontTheme}`}></div>
                <div>
                  <img src={images["progess-bar-plane.svg"]} />
                </div>
              </div>
            )
          }
      />


      {resultPageData.SearchResultsStatus === "Loading" && (
        <ResultPageLoading Theme={FontTheme}/>
      )}

      {resultPageData.SearchResultsStatus === "idle" &&
        resultPageData.ErrorOnresultPage === true && (
          <ErrorResultPage
          Theme={FontTheme}
            mainmessage={resultPageData.ErrorOnresultPageMessageType}
            descriptionmessage={resultPageData.ErrorOnresultPageMessageDetailed}
          />
        )}

      {resultPageData.SearchResultsStatus === "idle" &&
        resultPageData.ErrorOnresultPage === false &&
        (resultPageData.SearchResultType === 3 ||
          (resultPageData.SearchResultType === 4 &&
            resultPageData.ResultView === 2) ||
          resultPageData.SearchResultType === 5 ||
          resultPageData.SearchResultType === 7 ||
          resultPageData.SearchResultType === 9 ||
          (resultPageData.SearchResultType === 6 &&
            resultPageData.ResultView === 2) ||
          (resultPageData.SearchResultType === 8 &&
            resultPageData.ResultView === 2) ||
          (resultPageData.SearchResultType === 10 &&
            resultPageData.ResultView === 2)) && (
          <div
            className={`flights-section-results-page-1 ${FontTheme}`}
            style={{ top: isSticky === true ? "118px" : null }}
          >
            <div className="result-main-section-1">
              <ResultBarSection
                Theme={FontTheme}
                mode={
                  resultPageData.SearchResultType === 3 ||
                  resultPageData.SearchResultType === 4 ||
                  resultPageData.SearchResultType === 5 ||
                  resultPageData.SearchResultType === 6
                    ? 2
                    : resultPageData.SearchResultType === 7 ||
                      resultPageData.SearchResultType === 8
                    ? 3
                    : 4
                }
                startValue={
                  resultPageData.SearchResultType === 3 ||
                  resultPageData.SearchResultType === 5 ||
                  resultPageData.SearchResultType === 7 ||
                  resultPageData.SearchResultType === 9
                    ? 0
                    : resultPageData.SearchResultType === 4 ||
                      resultPageData.SearchResultType === 6
                    ? 2
                    : resultPageData.SearchResultType === 8
                    ? 3
                    : 4
                }
                setPopupBarMode={setPopupBarMode}
                setMainOpenPopup={OpenThePopup}
              />
            </div>
          </div>
        )}

      {resultPageData.SearchResultsStatus === "idle" &&
        resultPageData.ErrorOnresultPage === false && <ResultPageData Theme={FontTheme}/>}

      <MainPopup
        Open={OpenPopup}
        Theme={FontTheme}
        onClose={CloseThePopup}
        topBarMode={
          (resultPageData?.SearchResultType == 3 ||
            resultPageData?.SearchResultType == 4 ||
            resultPageData?.SearchResultType == 5 ||
            resultPageData?.SearchResultType == 6) == true
            ? 2
            : (resultPageData?.SearchResultType == 7 ||
                resultPageData?.SearchResultType == 8) == true
            ? 3
            : 4
        }
        topBarSelected={PopupBarMode}
        isRound={
          (resultPageData.SearchResultType === 3 ||
            resultPageData.SearchResultType === 4) == true
            ? true
            : ((resultPageData.SearchResultType === 6 &&
                resultPageData.ResultView === 2) ||
                (resultPageData.SearchResultType === 8 &&
                  resultPageData.ResultView === 2) ||
                (resultPageData.SearchResultType === 10 &&
                  resultPageData.ResultView === 2)) == true
            ? "FromMulSplit"
            : false
        }
      />
    </div>
  );
}
