import React from "react";
import "./AddonsContentHeader.css";
import images from "../../../../../Utils/importImages";
import { useSelector } from "react-redux";
import NextButton from "./NextButton";
import LogoContainer from "./LogoContainer";
import { colors } from "@mui/material";

function AddonsContentHeader({
  Theme,
  logo,
  Airline,
  Code_Number,
  CraftType,
  TripName,
  TripCoordinators,
}) {

  console.log("the lgog is  :  " ,  logo);
  

  return (
    <div className={`content-header ${Theme}`}>
      <div className="header-content">
        <div className="info">
          {
            !Array.isArray(logo) ? (
          <div className="img-container">
            <img
              src={images[logo == "" ? "default-airline.png" : ""]}
              alt="logo"
            />
          </div>) : <LogoContainer allAirlines={logo}/>
          }

          <div className="info-container">
            <div
              className={`${Theme}_${
                Theme == "proxima" ? "semibold" : "semibold"
              } font16 color_deep-ocean`}
            >
              {Airline}
            </div>
            <div className="info-codes">
              <div className={`${Theme}_light font13 color_deep-ocean`}>
                {Code_Number}
              </div>
              {CraftType !== "" && (
                <div className={`${Theme}_regular font13 color_deep-ocean`}>
                  {" "}
                   {`| ${CraftType}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="trip">
          <div className={`${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "choclate"} font12`}>
            {TripName}
          </div>
          <div
            className={`${Theme}_regular font16 color_deep-ocean coordinates`}
          >
            {TripCoordinators}
          </div>
        </div>
      </div>
 <NextButton Theme={Theme}/>
    </div>
  );
}

export default AddonsContentHeader;
