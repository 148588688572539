import React, { useState } from "react";
import "./AddonsSelectorBar.css";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import { setAncellarySectionActive } from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function AddonsSelectorBar({Theme}) {

  let AncellarySectionActive = useSelector(
    (state) => state.ReviewPageFlight.AncellarySectionActive
  );

  let dispatch = useDispatch()

  let hasSeatsData = useSelector(
    (state) => state.ReviewPageFlight.hasSeatsData
  );
  let hasMealsData = useSelector(
    (state) => state.ReviewPageFlight.hasMealsData
  );
  let hasBagsData = useSelector(
    (state) => state.ReviewPageFlight.hasBagsData
  );

  let Bars = [
    hasSeatsData && { name: "Seats", icon: "seat" },
    hasMealsData && { name: "Meals", icon: "meal" },
    hasBagsData && { name: "Baggage", icon: "baggage" },
  ];
  return (
    <div className="addons-head">
      {Bars.map((item, id) => {
        return <div onClick={() => dispatch(setAncellarySectionActive(item.name))} className={`item  ${Theme}_semibold color_${item.name == AncellarySectionActive ? "white" : "deep-ocean"} ${item.name == AncellarySectionActive ? "selected" : ""}`}>
          <img src={images[`${item.icon}-icon-${item.name == AncellarySectionActive ? "light" : "dark"}.svg`]} alt="" />
          {item.name}
        </div>
      })}
    </div>
  );
}

export default AddonsSelectorBar;
