import React from "react";
import images from "../../../../../../../Utils/importImages";

function DataBar({bbn}) {
  return (
    <div className="frame-24-1-cc frame-24-3-cc">
      <div className="frame-242-10-cc" style={bbn == true ? { borderBottom: "none" } : {}}>
        <p className="address-3-cc proxima_regular color_eerie-black font16">
          0 Hours To 4 Hours
        </p>
      </div>
      <div className="frame-242-11-cc">
        <div className="frame-24-2-cc frame-24-3-cc">
          <div className="adult-cc proxima_regular color_gravel font16">
            ADULT :
          </div>
          <div className="non-refundable-cc proxima_regular color_crimson font16">
            Non-Refundable
          </div>
        </div>
        <div className="frame-24-2-cc frame-24-3-cc">
          <div className="child-cc proxima_regular color_gravel font16">
            CHILD :
          </div>
          <div className="frame-24-cc">
            <div className="frame-17-cc">
              <img
                className="frame-169-cc"
                src={images["frame-169-26.svg"]}
                alt="Frame 169"
              />
              <div className="text-363-cc valign-text-middle-cc proxima_regular color_eerie-black font14">
                28,885
              </div>
            </div>
            <div className="frame-17-cc">
              <div className="text-364-cc valign-text-middle-cc proxima_regular color_eerie-black font16">
                +
              </div>
            </div>
            <div className="frame-17-cc">
              <img
                className="frame-169-cc"
                src={images["frame-169-36.svg"]}
                alt="Frame 169"
              />
              <div className="number-cc valign-text-middle-cc proxima_regular color_eerie-black font14">
                100
              </div>
            </div>
          </div>
        </div>
        <div className="frame-24-2-cc frame-24-3-cc">
          <div className="infant-cc proxima_regular color_gravel font16">
            INFANT :
          </div>
          <div className="frame-24-cc">
            <div className="frame-17-cc">
              <img
                className="frame-169-cc"
                src={images["frame-169-26.svg"]}
                alt="Frame 169"
              />
              <div className="text-365-cc valign-text-middle-cc proxima_regular color_eerie-black font14">
                28,885
              </div>
            </div>
            <div className="frame-17-cc">
              <div className="text-366-cc valign-text-middle-cc proxima_regular color_eerie-black font16">
                +
              </div>
            </div>
            <div className="frame-17-cc">
              <img
                className="frame-169-cc"
                src={images["frame-169-36.svg"]}
                alt="Frame 169"
              />
              <div className="number-5-cc valign-text-middle-cc proxima_regular color_eerie-black font14">
                100
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataBar;
