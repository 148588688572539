import React, { useState } from "react";
import "./StatusCounter.css";
import images from "../../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import { setcurrentSection, setstartButtonShaking } from "../../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function StatusCounter({ Theme}) {

  let dispatch = useDispatch();

  let selectedOption = useSelector((state) => state.ReviewPageFlight.currentSection);

  let ScrollToButton = (name) => {
    const contactWrapper = document.querySelector("."+name);
    if (contactWrapper) {
      contactWrapper.scrollIntoView({ behavior: 'smooth' , block: 'center'}); // You can use 'auto' instead of 'smooth' for instant scrolling
      dispatch(setstartButtonShaking(true))
    }
  }

  return (
    <div className="status-counter">
      <div className="frame-293-4">
        <div className="frame-293-5" onClick={() => dispatch(setcurrentSection(1))}>
          <div className={`frame-293-6 ${Theme}`}>
            <div className={`number-18 ${Theme}_semibold color_white font16`}>
              {selectedOption <= 1 ? (
                1
              ) : (
                <img src={images["white-tick.svg"]} alt="" />
              )}
            </div>
          </div>
          <div
            className={`review-itinerary ${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "choclate"
            } font15`}
          >
            Review Itinerary
          </div>
        </div>
        <div className={`progress-bar-1 ${Theme} ${selectedOption > 1 ? "selected" : ""}`}></div>
        <div className="frame-293-5" onClick={() => { selectedOption == 1 ? ScrollToButton("continue-section-1") : dispatch(setcurrentSection(2))}}>
          <div className={`${selectedOption > 1 ? "frame-293-6" : "frame-293-7"} ${Theme}` }>
            <div
              className={`number-18 proxima_semibold color_${
                selectedOption != 2 ? (Theme == "proxima" ? "magic-potion" : "choclate") : "white"
              } font16`}
            >
              {selectedOption <= 2 ? (
                2
              ) : (
                <img src={images["white-tick.svg"]} alt="" />
              )}
            </div>
          </div>
          <div
            className={`add-traveller-details-1 ${Theme}_regular color_${
              Theme == "proxima" ? "dark-purple" : "choclate"
            } font15`}
          >
            Add Traveller Details
          </div>
        </div>
        <div className={`progress-bar-2 ${Theme} ${selectedOption > 2 ? "selected" : ""}`}></div>
        <div className="frame-293-5" onClick={() => { selectedOption == 1 ? ScrollToButton("continue-section-1") : selectedOption == 2 ? ScrollToButton("continue-section-2") : dispatch(setcurrentSection(3))}}>
          <div className={`${selectedOption > 2 ? "frame-293-6" : "frame-293-7"} ${Theme}`}>
            <div
              className={`number-18 proxima_semibold color_${
                selectedOption != 3 ? (Theme == "proxima" ? "magic-potion" : "choclate") : "white"
              } font16`}
            >
              {selectedOption <= 3 ? (
                3
              ) : (
                <img src={images["white-tick.svg"]} alt="" />
              )}
            </div>
          </div>
          <div
            className={`seats-meals-baggage-1 ${Theme}_regular color_${
              Theme == "proxima" ? "dark-purple" : "choclate"
            } font15`}
          >
            Seats, Meals &amp; Baggage
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusCounter;
