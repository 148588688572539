import React from 'react'
import "./EnsureBar.css"
import images from '../../../../../Utils/importImages'

function EnsureBar({insureContinue  , setInsureContinue , hasError , Theme}) {
  return (
    <div className={`ensure-details ${hasError == true && "shake"}`}>
    <div className="ensure-details-bar">
      {" "}
      <img
        className="ensure-check"
        src={
          images[
            insureContinue == true
              ? "travellers-checkbox-checked-blue.svg"
              : "travellers-checkbox-unchecked-blue.svg"
          ]
        }
        alt=""
        onClick={() => setInsureContinue(!insureContinue)}
      />
      <div
        className={`${Theme}_semibold color_rhino font16`}
        onClick={() => setInsureContinue(!insureContinue)}
      >
   I ensure that the spelling of your name and other details match with your passport, as these cannot be changed later. Errors might lead to cancellation penalties.
      
      </div>
    </div>
 
  </div>
  )
}

export default EnsureBar
