import React, { useEffect, useState } from "react";
import "./TravellersAccordian.css";
import images from "../../../../../Utils/importImages";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SvgIcon from "@mui/material/SvgIcon";
import { Countrieslist } from "../../../../../Constants/CountriesAndCodes";
import CountrySelector from "./CountrySelector";
import { useDispatch } from "react-redux";
import {
  setTravellerCountryDetails,
  setTravellerDob,
  setTravellerFirstName,
  setTravellerfrequentFlyerAirline,
  setTravellerfrequentFlyerAvailability,
  setTravellerfrequentFlyerNumber,
  setTravellerGender,
  setTravellerLastName,
  setTravellerPassportExpiryDate,
  setTravellerPassportIssueDate,
  setTravellerPassportIssuingCountry,
  setTravellerPassportNumber,
  setTravellerTitle,
  setTravellerWheelChairRequested,
} from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import { isPassportNumber } from "validator";
import DateSelector from "./DateSelector";
import { generateDropdownYears } from "../../../../../Utils/Flight_review_func";
import TitleTooltip from "./TitleTooltip";

function TravellersAccordian({
  fareType,
  classNameTop,
  isActiveError,
  Theme,
  Item,
  Type,
  icon,
  name,
  isOpen,
  showAddNextTraveller,
  firstNameLimit,
  lastNameLimit,
  DateOfTravel,
  IsPassportRequired,
  TitlesProp,
  Index,
  userDC,
}) {
  let dispatch = useDispatch();

  const [expandSection, setExpandSection] = useState(isOpen);

  function ExpandIcon(props) {
    return (
      <SvgIcon
        {...props}
        style={{ color: props.color, fontSize: props.fontSize }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </SvgIcon>
    );
  }

  // city: ""
  // countryCode: ""
  // countryName: ""
  // hasError: false
  // nationality: ""

  const [showTooltip1, setShowTooltip1] = useState(false); // Tooltip visibility state
  const [showTooltip2, setShowTooltip2] = useState(false); // Tooltip visibility state

  const handleFocus1 = () => {
      setShowTooltip1(true);
  };
  const handleFocus2 = () => {
      setShowTooltip2(true);
  };

  const handleBlur1 = () => {
      setShowTooltip1(false);
  };
  const handleBlur2 = () => {
      setShowTooltip2(false);
  };

  // blur for passport image

  // create the setters fucnion of the field sinr redux

  let [title, setTitle] = useState(Item.title);
  let [titleError, setTitleError] = useState("");

  let onChangeTitle = (info) => {
    setTitleError("");
    setTitle(info.target.value);
    dispatch(setTravellerTitle({ index: Index, data: info.target.value }));
    dispatch(
      setTravellerGender({
        index: Index,
        data:
          info.target.value == "Mr" || info.target.value == "Mstr"
            ? "Male"
            : "Female",
      })
    );
  };

  let validateTitle = () => {
    if (title === "") {
      setTitleError("Title is required");
    } else {
      setTitleError("");
    }
  };

  let [firstName, setFirstName] = useState(Item.firstName);
  let [firstNameError, setFirstNameError] = useState("");

  let onChangeFirstName = (info) => {
    setFirstNameError("");
    setFirstName(info.target.value);
    dispatch(setTravellerFirstName({ index: Index, data: info.target.value }));
  };

  const validateFirstName = () => {

    const nameRegex = /^[A-Za-z]+$/;

    if (!firstName) {
      setFirstNameError("First Name is required");
    } else if (!nameRegex.test(firstName)) {
      setFirstNameError("Invalid First Name");
    } else if (firstName.length > firstNameLimit.val) {
      setFirstNameError(
        `Sorry, ${firstNameLimit.airline} allows only ${firstNameLimit.val} characters in the First/Given Name field.`
      );
    } else {
      setFirstNameError("");
    }
  };

  let [lastName, setLastName] = useState(Item.lastName);

  let [lastNameError, setlastNameError] = useState("");

  let onChangeLastName = (info) => {
    setlastNameError("");
    setLastName(info.target.value);
    dispatch(setTravellerLastName({ index: Index, data: info.target.value }));
  };

  const validateLasttName = () => {
    const nameRegex = /^[A-Za-z]+$/;

    if (lastName !== "") {
      if (!nameRegex.test(lastName)) {
        setlastNameError("Invalid Last Name");
      } else if (lastName.length > lastNameLimit.val) {
        setlastNameError(
          `Sorry, ${lastNameLimit.airline} allows only ${lastNameLimit.val} characters in the First/Given Name field.`
        );
      } else {
        setlastNameError("");
      }
    }
  };

  // let dobinfo = Item.dob;

  let [dob, setDob] = useState(Item.dob);
  let [dobError, setdobError] = useState("");
  let [clickedInsideDob, setClickedInsideDob] = useState(false);
  let [callDobValidator, setcallDobValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideDob == true && callDobValidator == true) {
      validateDob(Item.dob);
    }
  }, [clickedInsideDob, callDobValidator]);

  let onChangedob = (info) => {
    setDob(info);
    setdobError("");
    dispatch(setTravellerDob({ index: Index, data: info }));
    // dobinfo = info;
  };

  // console.log("dobinfo is : ", dobinfo);

  const validateDob = (dob) => {
    const [day, month, year] = dob.split("-");
    console.log("day, month, year : ", day, month, year);
  
    if (dob === "Date-Month-Year") {
      setdobError("Date Of Birth is required");
    } else if (day === "Date") {
      setdobError("Please Select Date");
    } else if (month === "Month") {
      setdobError("Please Select Month");
    } else if (year === "Year") {
      setdobError("Please Select Year");
    } else {
      try {
        const selectedDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();
  
        let minYear, maxYear;
  
        if (fareType === "Senior Citizen") {
          // Calculate age based on selected date
          const age = today.getFullYear() - selectedDate.getFullYear();
          const isSenior = age > 60 || (age === 60 && today >= selectedDate);
  
          if (!isSenior) {
            setdobError("Senior Citizens must be at least 60 years old.");
          } else {
            setdobError(""); // Valid senior citizen
          }
        } else if (Item.type === "ADT") {
          // Adults: 12 years or older
          minYear = today.getFullYear() - 100; // 100 years ago (e.g., 1900)
          maxYear = today.getFullYear() - 12; // 12 years ago
        } else if (Item.type === "CHD") {
          // Children: 2 to 12 years old
          minYear = today.getFullYear() - 12; // 12 years ago
          maxYear = today.getFullYear() - 2; // 2 years ago
        } else if (Item.type === "INF") {
          // Infants: Less than 2 years old
          minYear = today.getFullYear() - 2; // 2 years ago
          maxYear = today.getFullYear(); // Current year
        }
  
        // For non-senior citizen cases, check if the selected date is valid
        if (fareType !== "Senior Citizen") {
          if (isNaN(selectedDate.getTime())) {
            setdobError("Invalid Date");
          } else if (
            selectedDate.getFullYear() < minYear ||
            selectedDate.getFullYear() > maxYear
          ) {
            // Validate that the selected date is within the allowed range
            if (Item.type === "ADT") {
              setdobError("Adults must be at least 12 years old.");
            } else if (Item.type === "CHD") {
              setdobError("Children must be between 2 and 12 years old.");
            } else if (Item.type === "INF") {
              setdobError("Infants must be less than 2 years old.");
            }
          } else {
            setdobError("");
          }
        }
      } catch (e) {
        setdobError("Invalid Date");
      }
    }
  
    setClickedInsideDob(false);
    setcallDobValidator(false);
  };
  

  const defaultCountrySelected1 = Countrieslist?.find((country) =>
    Item.countryName == ""
      ? country.phone === userDC
      : country.name === Item.countryName
  );

  let [countryName, setcountryName] = useState(defaultCountrySelected1);

  let onChangeCountry = (info) => {
    if (info == null) {
      let data = {
        city: defaultCountrySelected1.name,
        countryCode: defaultCountrySelected1.code,
        countryName: defaultCountrySelected1.name,
        nationality: defaultCountrySelected1.nationality,
      };

      // setcountryError("");
      setcountryName(defaultCountrySelected1);
      dispatch(setTravellerCountryDetails({ index: Index, data: data }));
    } else {
      let data = {
        city: info.name,
        countryCode: info.code,
        countryName: info.name,
        nationality: info.nationality,
      };
      // setcountryError("");
      setcountryName(info);
      dispatch(setTravellerCountryDetails({ index: Index, data: data }));
    }
  };

  let [PassportNo, setPassportNo] = useState(Item.passportDetails.PassportNo);

  let [PassportNoError, setPassportNoError] = useState("");

  let onChangePassportNo = (info) => {
    setPassportNoError("");
    setPassportNo(info.target.value);
    dispatch(
      setTravellerPassportNumber({ index: Index, data: info.target.value })
    );
  };

  const validatePassportNumber = () => {
    if (!PassportNo) {
      setPassportNoError("Passport Number is required");
    } else {
      setPassportNoError("");
    }
  };

  // console.log('Item.passportDetails.PassportIssuingCountry == "" is : ' , Item.passportDetails.PassportIssuingCountry == "" , Item.passportDetails.PassportIssuingCountry);

  const defaultCountrySelected2 = Countrieslist?.find((country) =>
    Item.passportDetails.PassportIssuingCountry == ""
      ? country.phone === userDC
      : country.code === Item.passportDetails.PassportIssuingCountry
  );

  console.log("defaultCountrySelected2 is : ", defaultCountrySelected2);

  // console.log("defaultCountrySelected1 is : " , defaultCountrySelected1);
  // console.log("defaultCountrySelected2 is : " , defaultCountrySelected2);

  let [PassportIssuingCountry, setPassportIssuingCountry] = useState(
    defaultCountrySelected2
  );

  let onChangePassportIssueCountry = (info) => {
    if (info == null) {
      setPassportIssuingCountry(defaultCountrySelected2);
      dispatch(
        setTravellerPassportIssuingCountry({
          index: Index,
          data: defaultCountrySelected2.code,
        })
      );
    } else {
      setPassportIssuingCountry(info);
      dispatch(
        setTravellerPassportIssuingCountry({
          index: Index,
          data: info.countryCode,
        })
      );
    }
  };

  let [PassportIssueDate, setPassportIssueDate] = useState(
    Item.passportDetails.PassportIssueDate
  );
  let [PassportIssueDateError, setPassportIssueDateError] = useState("");

  let [clickedInsidePassportIssueDate, setClickedInsidePassportIssueDate] =
    useState(false);
  let [callPassportIssueDateValidator, setcallPassportIssueDateValidator] =
    useState(false);

  useEffect(() => {
    if (
      clickedInsidePassportIssueDate == true &&
      callPassportIssueDateValidator == true
    ) {
      validatePassportIssueDate(Item.passportDetails.PassportIssueDate);
    }
  }, [clickedInsidePassportIssueDate, callPassportIssueDateValidator]);

  let onChangePassportIssueDate = (info) => {
    setPassportIssueDate(info);
    setPassportIssueDateError("");
    dispatch(setTravellerPassportIssueDate({ index: Index, data: info }));
    // PassportIssueDateInfo = info;
  };

  const validatePassportIssueDate = (PPIssue) => {
    const [day, month, year] = PPIssue.split("-");
    console.log("day, month, year : ", day, month, year);

    if (PPIssue === "Date-Month-Year") {
      setPassportIssueDateError("Passport Issue Date is required");
    } else if (day === "Date") {
      setPassportIssueDateError("Please Select Date");
    } else if (month === "Month") {
      setPassportIssueDateError("Please Select Month");
    } else if (year === "Year") {
      setPassportIssueDateError("Please Select Year");
    } else {
      try {
        const selectedDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();

        // Check if the date is valid
        if (isNaN(selectedDate.getTime())) {
          setPassportIssueDateError("Invalid Date");
        }
        // Check if the date is in the future
        else if (selectedDate > today) {
          setPassportIssueDateError(
            "Passport Issue Date cannot be a future date"
          );
        } else {
          setPassportIssueDateError("");
        }
      } catch (e) {
        setPassportIssueDateError("Invalid Date");
      }
    }

    setClickedInsidePassportIssueDate(false);
    setcallPassportIssueDateValidator(false);
  };

  let [PassportExpiry, setPassportExpiry] = useState(
    Item.passportDetails.PassportExpiry
  );

  let [PassportExpiryDateError, setPassportExpiryDateError] = useState("");

  let [clickedInsidePassportExpiryDate, setClickedInsidePassportExpiryDate] =
    useState(false);
  let [callPassportExpiryDateValidator, setcallPassportExpiryDateValidator] =
    useState(false);

  useEffect(() => {
    if (
      clickedInsidePassportExpiryDate == true &&
      callPassportExpiryDateValidator == true
    ) {
      validatePassportExpiryDate(Item.passportDetails.PassportExpiry);
    }
  }, [clickedInsidePassportExpiryDate, callPassportExpiryDateValidator]);

  let onChangePassportExpiryDate = (info) => {
    setPassportExpiry(info);
    setPassportExpiryDateError("");
    dispatch(setTravellerPassportExpiryDate({ index: Index, data: info }));
  };

  const validatePassportExpiryDate = (PPExpiryDate) => {
    const [day, month, year] = PPExpiryDate.split("-");
    console.log("day, month, year : ", day, month, year);

    if (PPExpiryDate === "Date-Month-Year") {
      setPassportExpiryDateError("Passport Expiry Date is required");
    } else if (day === "Date") {
      setPassportExpiryDateError("Please Select Date");
    } else if (month === "Month") {
      setPassportExpiryDateError("Please Select Month");
    } else if (year === "Year") {
      setPassportExpiryDateError("Please Select Year");
    } else {
      try {
        const selectedDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();

        // Check if the date is valid
        if (isNaN(selectedDate.getTime())) {
          setPassportExpiryDateError("Invalid Date");
        }
        // Check if the date is in the future
        else if (selectedDate <= today) {
          setPassportExpiryDateError(
            "Passport Expiry Date cannot be a Past date"
          );
        } else {

        // Parse DateOfTravel
        const [travelDay, travelMonth, travelYear] = DateOfTravel.split("-");
        const travelDate = new Date(`${travelYear}-${travelMonth}-${travelDay}`);

        // Add 6 months to the travel date
        const sixMonthsAfterTravel = new Date(travelDate);
        sixMonthsAfterTravel.setMonth(sixMonthsAfterTravel.getMonth() + 6);

        // Check if the passport expiry is before six months from the date of travel
        if (selectedDate < sixMonthsAfterTravel) {
          setPassportExpiryDateError(
            "Passport should be valid for at least 6 months from the Date of Travel"
          );
        } else {
          setPassportExpiryDateError(""); // No error
        }

        }
      } catch (e) {
        setPassportExpiryDateError("Invalid Date");
      }
    }

    setClickedInsidePassportExpiryDate(false);
    setcallPassportExpiryDateValidator(false);
  };

  let [wheelChairRequested, setwheelChairRequested] = useState(
    Item.wheelChairRequested
  );

  let handleChangeWheelChair = (state) => {
    setwheelChairRequested(state);
    dispatch(setTravellerWheelChairRequested({ index: Index, data: state }));
  };

  //available

  let [frequentFlyerDetailsNumber, setfrequentFlyerDetailsNumber] = useState(
    Item.frequentFlyerDetails.number
  );

  let [frequentFlyerDetailsNumberError, setfrequentFlyerDetailsNumberError] =
    useState("");

  let onChangefrequentFlyerDetailsNumber = (info) => {
    setfrequentFlyerDetailsNumberError("");
    setfrequentFlyerDetailsNumber(info.target.value);
    dispatch(
      setTravellerfrequentFlyerNumber({ index: Index, data: info.target.value })
    );
  };

  const validatefrequentFlyerDetailsNumber = () => {
    if (
      Item.frequentFlyerDetails.available == true &&
      !frequentFlyerDetailsNumber
    ) {
      setfrequentFlyerDetailsNumberError("FreQuent Flyer No Can't be Empty");
    } else if (frequentFlyerDetailsNumber.length > 50) {
      setfrequentFlyerDetailsNumberError(
        "This Number Can't Exceed 50 Characters"
      );
    } else {
      setfrequentFlyerDetailsNumberError("");
    }
  };

  let [frequentFlyerDetailsAirline, setfrequentFlyerDetailsAirline] = useState(
    Item.frequentFlyerDetails.airline
  );

  let [frequentFlyerDetailAirlineError, setfrequentFlyerDetailAirlineError] =
    useState("");

  let onChangefrequentFlyerDetailsAirline = (info) => {
    setfrequentFlyerDetailAirlineError("");
    setfrequentFlyerDetailsAirline(info.target.value);
    dispatch(
      setTravellerfrequentFlyerAirline({
        index: Index,
        data: info.target.value,
      })
    );
  };

  const validatefrequentFlyerDetailsAirline = () => {
    if (
      Item.frequentFlyerDetails.available == true &&
      !frequentFlyerDetailsAirline
    ) {
      setfrequentFlyerDetailAirlineError("Airline Code Can't be Empty");
    } else if (frequentFlyerDetailsAirline.length > 2) {
      setfrequentFlyerDetailAirlineError(
        "Airline Code Can't Exceed Two Letters"
      );
    } else {
      setfrequentFlyerDetailAirlineError("");
    }
  };

  useEffect(() => {
    if (frequentFlyerDetailsAirline == "" && frequentFlyerDetailsNumber == "") {
      dispatch(
        setTravellerfrequentFlyerAvailability({ index: Index, data: false })
      );
    } else {
      dispatch(
        setTravellerfrequentFlyerAvailability({ index: Index, data: true })
      );
    }
  }, [frequentFlyerDetailsNumber, frequentFlyerDetailsAirline]);

  // default cc selector

  useEffect(() => {
    // console.log("isActiveError is : ", isActiveError);

    if (isActiveError == true) {
      validateTitle();
      validateFirstName();
      validateLasttName();
      validateDob(Item.dob);
      if (IsPassportRequired == true) {
        validatePassportNumber();
        validatePassportIssueDate(Item.passportDetails.PassportIssueDate);
        validatePassportExpiryDate(Item.passportDetails.PassportExpiry);
      }
      if (Item.frequentFlyerDetails.available == true) {
        validatefrequentFlyerDetailsNumber();
        validatefrequentFlyerDetailsAirline();
      }
    }
  }, [isActiveError]);

  let onClickNextTraveller = () => {

    const travellerAccordian1 = document.querySelector(`.${classNameTop}_${Index}`);
    const travellerAccordian2 = document.querySelector(`.Traveller${Index+1}_${Index+1}`);
    
    if (travellerAccordian1) {
      travellerAccordian1.click()
    }
    if (travellerAccordian2) {
      travellerAccordian2.click()
    }
  }

  return (
    <div
      className={`travllerAccordian-wrapper ${Theme} ${classNameTop} ${
        isActiveError == true && "shake"
      }`}
    >
      <div
        className={`travllerAccordian-bar ${Theme} ${classNameTop}_${Index} ${
          expandSection == true ? "bn" : ""
        }`}
        onClick={() => setExpandSection(!expandSection)}
      >
        <div className="info-container">
          <img src={icon} alt="icon" />
          <div className="traveller-info">
            <div className={`${Theme}_semibold color_dark-purple font16`}>
              {name}
            </div>
            <div className={`${Theme}_semibold color_dark-purple font16`}>
              {" "}
              -{" "}
            </div>
            <div className={`light-text ${Theme}_regular color_gravel font16`}>
              {Type}
            </div>
          </div>
        </div>
        {isActiveError == true ? (
          <div className="error-container">
            <div className="info-container">
              <div className="traveller-info">
                <div className={`${Theme}_semibold color_error-red font16`}>
                  Invalid Traveller Data
                </div>
              </div>
            </div>
            <div className="expand-icon">
              <img
                src={images["expand-icon-blue.svg"]}
                alt="icon"
                className={`expand-icon-img ${
                  expandSection ? "expanded" : "open"
                }`}
              />
            </div>
          </div>
        ) : (
          <div className="expand-icon">
            <img
              src={
                images[
                  `expand-icon-${Theme == "proxima" ? "blue" : "gold"}.svg`
                ]
              }
              alt="icon"
              className={`expand-icon-img ${
                expandSection ? "expanded" : "open"
              }`}
            />
          </div>
        )}
      </div>
      <div className={`expandable-content ${expandSection ? "open" : ""}`}>
        <div className="nameSection">
          <FormControl
            sx={{
              width: "10%",
            }}
            error={!!titleError} 
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                // color: Theme == "averta" ? "#C2A07C" : "inherit",
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima"
                    ? `${Theme}_nova-regular`
                    : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontWeight: "400",
                "&.Mui-focused": {
                  color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Change color when focused
                },
              }}
            >
              Title
            </InputLabel>
            <Select
              sx={{
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    color: Theme == "averta" ? "#C2A07C !important" : "#0057B7",
                  },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={title}
              onBlur={validateTitle}
              label="Title"
              onChange={onChangeTitle}
              IconComponent={(props) => (
                <ExpandIcon
                  {...props}
                  color={Theme == "proxima" ? "#0057B7" : "#976c41"}
                  fontSize="medium"
                />
              )}
            >
              {TitlesProp?.map((item, id) => {
                return (
                  <MenuItem value={item} key={id}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            {!!titleError && <FormHelperText>Required*</FormHelperText>}
          </FormControl>


          <Tooltip
         componentsProps={{
          tooltip: {
            sx: {
  maxWidth:'none',
              backgroundColor: "#293A64", // Background color for the tooltip
            },
          },
          arrow: {
            sx: {
              color: "#293A64", // Same background color for the arrow
            },
          },
        }}
      open={showTooltip1 && IsPassportRequired == true} // Control visibility of the tooltip
      title={<TitleTooltip type="FName" Theme={Theme}/>}
      placement="top" // Position the tooltip
      arrow
    >

          <TextField
            autoComplete='off'
            onFocus={handleFocus1} // Show tooltip on focus
            onBlur={() => {validateFirstName(); handleBlur1();}} // Validate on blur
            error={!!firstNameError}
            helperText={firstNameError}
            value={firstName}
            sx={{
              width: "45%",
              "& .MuiInputLabel-root": {
     
                fontFamily: `var(--font-family-${
                  Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                })`,
                "&.Mui-focused": {
                  color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
              },
            }}
            onChange={onChangeFirstName}
            id="outlined-basic"
            label="First / Given Name"
            variant="outlined"

          />
          </Tooltip>


          <Tooltip
         componentsProps={{
          tooltip: {
            sx: {
              maxWidth:'none',
              backgroundColor: "#293A64", // Background color for the tooltip
            },
          },
          arrow: {
            sx: {
              color: "#293A64", // Same background color for the arrow
            },
          },
        }}
      open={showTooltip2 && IsPassportRequired == true} // Control visibility of the tooltip
      title={<TitleTooltip type="LName" Theme={Theme}/>}
      placement="top" // Position the tooltip
      arrow
    >

          <TextField
            autoComplete='off'
              onFocus={handleFocus2} // Show tooltip on focus
            onBlur={() => {validateLasttName(); handleBlur2();}} // Validate on blur
            error={!!lastNameError}
            helperText={lastNameError}
            value={lastName}
            onChange={onChangeLastName}
            id="outlined-basic"
            label="Last / Family Name"
            variant="outlined"
            sx={{
              width: "45%",
              "& .MuiInputLabel-root": {
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontWeight: "400",
                "&.Mui-focused": {
                  color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
              },
            }}
          />
             </Tooltip>

        </div>
        <div className="name-dataSection">
          
          <DateSelector
            clickedInside={clickedInsideDob}
            setClickedInside={setClickedInsideDob}
            SetCallValidator={setcallDobValidator}
            DropDownYears={generateDropdownYears(Item.type , fareType)}
            Theme={Theme}
            Heading={"Date Of Birth"}
            width="50%"
            onBlur={validateDob} // Validate on blur
            error={!!dobError}
            helperText={dobError}
            value={dob.split("-")}
            onChange={onChangedob}
          />

          <CountrySelector
            width="50%"
            userDc={userDC}
            onChange={onChangeCountry}
            value={countryName}
            Theme={Theme}
            name={"Country"}
            IconComponent={<ExpandIcon             color={Theme == "proxima" ? "#0057B7" : "#976c41"} fontSize="medium" />}
          />
        </div>
        {IsPassportRequired == true && (
          <>
            <div className="passport-dataSection">
              <TextField
                autoComplete='off'
                onBlur={validatePassportNumber} // Validate on blur
                error={!!PassportNoError}
                helperText={PassportNoError}
                value={PassportNo}
                onChange={onChangePassportNo}
                id="outlined-basic"
                label="Passport Number"
                variant="outlined"
                sx={{
                  width: "34%",
                  "& .MuiInputLabel-root": {
                    fontSize: "16px",
                    fontFamily: `var(--font-family-${
                      Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                    })`,
                    fontStyle: "normal",
                    fontWeight: "400",
                    "&.Mui-focused": {
                      color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                    },
                  },
                }}
              />

              <CountrySelector
                width="33%"
                userDc={userDC}
                onChange={onChangePassportIssueCountry}
                value={PassportIssuingCountry}
                Theme={Theme}
                name={"Passport Issuing Country"}
                IconComponent={<ExpandIcon             color={Theme == "proxima" ? "#0057B7" : "#976c41"} fontSize="medium" />}
              />

              <DateSelector
                DropDownYears={Array.from(
                  {
                    length:
                      new Date().getFullYear() +
                      1 -
                      (new Date().getFullYear() - 200),
                  },
                  (_, i) => new Date().getFullYear() - 200 + i
                ).reverse()}
                clickedInside={clickedInsidePassportIssueDate}
                setClickedInside={setClickedInsidePassportIssueDate}
                SetCallValidator={setcallPassportIssueDateValidator}
                Theme={Theme}
                Heading={"Passport Issue Date"}
                width="33%"
                onBlur={validatePassportIssueDate} // Validate on blur
                error={!!PassportIssueDateError}
                helperText={PassportIssueDateError}
                value={PassportIssueDate.split("-")}
                onChange={onChangePassportIssueDate}
              />
            </div>
            <div className="passport-dataSection">
              <DateSelector
                clickedInside={clickedInsidePassportExpiryDate}
                setClickedInside={setClickedInsidePassportExpiryDate}
                SetCallValidator={setcallPassportExpiryDateValidator}
                DropDownYears={Array.from(
                  { length: 101 },
                  (_, i) => new Date().getFullYear() + i
                )}
                Theme={Theme}
                Heading={"Passport Expiry Date"}
                width="33%"
                onBlur={validatePassportExpiryDate} // Validate on blur
                error={!!PassportExpiryDateError}
                helperText={PassportExpiryDateError}
                value={PassportExpiry.split("-")}
                onChange={onChangePassportExpiryDate}
              />
            </div>
          </>
        )}

        <div className="wheelchair-wrapper">
          <img
            className="wheelchair-check"
            src={
              images[
                wheelChairRequested == true
                  ? "travellers-checkbox-checked-blue.svg"
                  : "travellers-checkbox-unchecked-blue.svg"
              ]
            }
            alt=""
            onClick={() => handleChangeWheelChair(!wheelChairRequested)}
          />
          <div
            className={`${Theme}_semibold color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} font16`}
            onClick={() => handleChangeWheelChair(!wheelChairRequested)}
          >
            i require wheelchair
          </div>
          <div className={`${Theme}_regular color_light-gray-black font14`}>
            ( Optional )
          </div>
        </div>

        <div className="frequent-flyer-wrapper">
          <div className={`${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "choclate"} font16`}>
            Add Frequent Flyer Number (Optional)
          </div>

          <div className="nameSection">
            <TextField
              autoComplete='off'
              onBlur={validatefrequentFlyerDetailsAirline} // Validate on blur
              error={!!frequentFlyerDetailAirlineError}
              helperText={frequentFlyerDetailAirlineError}
              value={frequentFlyerDetailsAirline}
              onChange={onChangefrequentFlyerDetailsAirline}
              id="outlined-basic"
              label="Frequent Flyer Airline Code"
              variant="outlined"
              sx={{
                width: "50%",
                "& .MuiInputLabel-root": {
                  fontSize: "16px",
                  fontFamily: `var(--font-family-${
                    Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                  })`,
                  fontStyle: "normal",
                  fontWeight: "400",
                  "&.Mui-focused": {
                    color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                },
              }}
            />
            <TextField
              autoComplete='off'
              onBlur={validatefrequentFlyerDetailsNumber} // Validate on blur
              error={!!frequentFlyerDetailsNumberError}
              helperText={frequentFlyerDetailsNumberError}
              value={frequentFlyerDetailsNumber}
              onChange={onChangefrequentFlyerDetailsNumber}
              id="outlined-basic"
              label="Frequent Flyer No"
              variant="outlined"
              sx={{
                width: "50%",
                "& .MuiInputLabel-root": {
                  fontSize: "16px",
                  fontFamily: `var(--font-family-${
                    Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                  })`,
                  fontStyle: "normal",
                  fontWeight: "400",
                  "&.Mui-focused": {
                    color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                },
              }}
            />
          </div>
          {showAddNextTraveller && (
            <div className="addmoretraveller">
              <div onClick={() => onClickNextTraveller()} className={`${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "choclate"} font16`}>
                ADD NEXT TRAVELLER
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TravellersAccordian;
