import React, { useEffect, useState } from "react";
import "./AddonsSection.css";
import ErrorMessageBar from "../Components/ErrorMessageBar";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../../Utils/importImages";
import AddonsSelectorBar from "./AddonsSelectorBar/AddonsSelectorBar";
import AddonsTripSelectionBar from "./AddonsTripSelectionBar/AddonsTripSelectionBar";
import AddonsContentHeader from "./AddonsContentHeader/AddonsContentHeader";
import SeatsContentSection from "./SeatsContentSection/SeatsContentSection";
import MealsContentSection from "./MealsContentSection/MealsContentSection";
import BagsContentSection from "./BagsContentSection/BagsContentSection";
import {
  Call_Continue1,
  Call_Continue3,
  CallTheAncellaries,
  setAncellarySectionActive,
  setCurrentAncellariesTab,
} from "../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import { getTheSelectionBarData } from "../../../../Utils/Flight_review_func";

function AddonsSection({ Theme, startButtonShaking }) {
  let reviewPageData = useSelector((state) => state.ReviewPageFlight);
  let AncellariesLoading = useSelector(
    (state) => state.ReviewPageFlight.AncellariesLoading
  );
  let continueButtonLoading = useSelector(
    (state) => state.ReviewPageFlight.continueButtonLoading
  );

  let continueButtonError = useSelector(
    (state) => state.ReviewPageFlight.continueButtonError
  );

  let AncellariesDownloaded = useSelector(
    (state) => state.ReviewPageFlight.AncellariesDownloaded
  );
  let AncellariesApiData = useSelector(
    (state) => state.ReviewPageFlight.AncellariesApiData
  );

  let AncellariesHeadings = useSelector(
    (state) => state.ReviewPageFlight.AncellariesTabsHeadings
  );
  let AncellariesTabs = useSelector(
    (state) => state.ReviewPageFlight.AncellariesTabs
  );
  let currentAncellariesTab = useSelector(
    (state) => state.ReviewPageFlight.currentAncellariesTab
  );
  let hasSeatsData = useSelector(
    (state) => state.ReviewPageFlight.hasSeatsData
  );
  let hasMealsData = useSelector(
    (state) => state.ReviewPageFlight.hasMealsData
  );
  let hasBagsData = useSelector(
    (state) => state.ReviewPageFlight.hasBagsData
  );

  let GoToPaymentPage = () => {
    dispatch(Call_Continue3())
  };

  const scrollToSection = (name) => {
    const wrapper = document.querySelector("." + name);
    if (wrapper) {
      wrapper.scrollIntoView({ behavior: "smooth", block: "center" }); // You can use 'auto' instead of 'smooth' for instant scrolling
    }
  };

  let GoToNextTab = () => {
    let tabsList = [];

    if(hasSeatsData == true){
      tabsList.push("Seats")
    }
    if(hasMealsData == true){
      tabsList.push("Meals")
    }
    if(hasBagsData == true){
      tabsList.push("Baggage")
    }
    
    // Find the next tab based on the currently selected one
    let currentTabIndex = tabsList.indexOf(AncellarySectionActive);  // Find the current active tab index

    // If there is a next tab, go to that one; otherwise, go to the first tab
    let TabName = currentTabIndex !== -1 && currentTabIndex < tabsList.length - 1 
      ? tabsList[currentTabIndex + 1] // Go to the next tab
      : tabsList[0]; // Otherwise, go to the 0th indexed tab

    // Dispatch the action to set the new active tab
    dispatch(setAncellarySectionActive(TabName));

    scrollToSection("addons-head");
  };

  let AncellarySectionActive = useSelector(
    (state) => state.ReviewPageFlight.AncellarySectionActive
  );

  let dispatch = useDispatch();

  let onChangeDataBar = (id, selection) => {
    if (AncellarySectionActive == "Seats") {
      dispatch(setCurrentAncellariesTab({ index: 0, value: [id, selection] }));
    } else if (AncellarySectionActive == "Meals") {
      dispatch(setCurrentAncellariesTab({ index: 1, value: [id, selection] }));
    } else {
      dispatch(setCurrentAncellariesTab({ index: 2, value: [id, selection] }));
    }
  };

  useEffect(() => {
    scrollToSection("top-bar-section");
    if (AncellariesDownloaded == false) {
      // call the ancellaries api when page loads
      dispatch(CallTheAncellaries());
    }
    //
  }, []);


  let getlastTab = () => {

    let lastTab = "Seats";

    if(hasSeatsData == true){
      lastTab = "Seats"
    }
    if(hasMealsData == true){
      lastTab = "Meals"
    }
    if(hasBagsData == true){
      lastTab = "Baggage"

    }

    return lastTab;
  }

  // useEffect(() => {

  // },[currentAncellariesTab])
  return (
    <div className="addons-section-wrapper">
      {AncellariesLoading == true && AncellariesApiData.Message == null && (
        <div className={`ancellary-loading ${Theme}_semibold font18`}>
          <div>Loading ... Please Wait</div>
          <div className="loader-big"> </div>
        </div>
      )}
      {AncellariesLoading == false && AncellariesApiData.Message == null && (
        <div className={`ancellary-loading ${Theme}_semibold font18`}>
          <div>Sorry ! , No Ancellaries Data Available for this Flight</div>
        </div>
      )}
      {AncellariesLoading == false && (hasSeatsData == false && hasMealsData == false && hasBagsData == false) && (
        <div className={`ancellary-loading ${Theme}_semibold font18`}>
          <div>Sorry ! , No Ancellaries Data Available for this Flight</div>
        </div>
      )}
      {AncellariesLoading == false && AncellariesApiData.Message != null && (
        <div className="addons-main">
          <AddonsSelectorBar Theme={Theme} />

          {AncellarySectionActive !== "Baggage" && (
            <>
              {(reviewPageData?.SearchResultType === 1 ||
                reviewPageData?.SearchResultType === 2) &&
                (reviewPageData?.FlightDetail?.Connections === undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      1
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 3 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    2
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={1}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 4 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 5 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    2
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={1}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 7 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    3
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={1}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 9 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    4
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={1}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 6 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 8 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      3
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      3
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 10 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      4
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      4
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={1}
                    Theme={Theme}
                  />
                ))}
            </>
          )}

          {AncellarySectionActive === "Baggage" && (
            <>
              {(reviewPageData?.SearchResultType === 1 ||
                reviewPageData?.SearchResultType === 2) &&
                (reviewPageData?.FlightDetail?.Connections === undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      1
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 3 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    2
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={2}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 4 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 5 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    2
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={2}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 7 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    3
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={2}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 9 && (
                <AddonsTripSelectionBar
                  SelectionBarData={getTheSelectionBarData(
                    AncellarySectionActive,
                    AncellariesTabs,
                    4
                  )}
                  headings={AncellariesHeadings}
                  Selected={
                    currentAncellariesTab[
                      AncellarySectionActive == "Seats"
                        ? 0
                        : AncellarySectionActive == "Meals"
                        ? 1
                        : 2
                    ]
                  }
                  onChange={onChangeDataBar}
                  Type={2}
                  Theme={Theme}
                />
              )}
              {reviewPageData?.SearchResultType === 6 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      2
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 8 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      3
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      3
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ))}
              {reviewPageData?.SearchResultType === 10 &&
                (reviewPageData?.FlightDetail[0] == undefined ? (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      4
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ) : (
                  <AddonsTripSelectionBar
                    SelectionBarData={getTheSelectionBarData(
                      AncellarySectionActive,
                      AncellariesTabs,
                      4
                    )}
                    headings={AncellariesHeadings}
                    Selected={
                      currentAncellariesTab[
                        AncellarySectionActive == "Seats"
                          ? 0
                          : AncellarySectionActive == "Meals"
                          ? 1
                          : 2
                      ]
                    }
                    onChange={onChangeDataBar}
                    Type={2}
                    Theme={Theme}
                  />
                ))}
            </>
          )}

          <div className="main-addon-content">
            
            {AncellarySectionActive === "Seats" && (
              <>
                <AddonsContentHeader
              
                  Theme={Theme}
                  logo={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.AirlineLogo}
                  Airline={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.AirlineName}
                  Code_Number={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.AirlineCode_Number}
                  CraftType={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.AirlineCraft}
                  TripName={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.headingVal}
                  TripCoordinators={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]].TabValue.Cities}
                />

                <SeatsContentSection Theme={Theme} currentSelectedTab={currentAncellariesTab[0]} currentSeatData={AncellariesTabs.SeatTabs[currentAncellariesTab[0][0]][currentAncellariesTab[0][1]]}/>
              </>
            )}

            {AncellarySectionActive === "Meals" && (
              <>
                <AddonsContentHeader
                  Theme={Theme}
                  logo={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.AirlineLogo}
                  Airline={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.AirlineName}
                  Code_Number={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.AirlineCode_Number}
                  CraftType={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.AirlineCraft}
                  TripName={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.headingVal}
                  TripCoordinators={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]].TabValue.Cities}
                />

                <MealsContentSection Theme={Theme} currentSelectedTab={currentAncellariesTab[1]} currentMealsData={AncellariesTabs.MealTabs[currentAncellariesTab[1][0]][currentAncellariesTab[1][1]]}/>
              </>
            )}

            {AncellarySectionActive === "Baggage" && (
              <>
                <AddonsContentHeader
                  Theme={Theme}
                  logo={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]].TabValue.AirlineLogo}
                  Airline={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]].TabValue.AirlineName}
                  Code_Number={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]].TabValue.AirlineCode_Number}
                  CraftType={""}
                  TripName={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]].TabValue.headingVal}
                  TripCoordinators={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]].TabValue.Cities}
                />

                <BagsContentSection Theme={Theme} currentSelectedTab={currentAncellariesTab[2]} currentBagsData={AncellariesTabs.BagTabs[currentAncellariesTab[2][0]][currentAncellariesTab[2][1]]}/>
              </>
            )}
          </div>
        </div>
      )}

      <div className={`continue-section continue-section-3`}>
        {!!continueButtonError == true && (
          <ErrorMessageBar Theme={Theme} Message={continueButtonError} />
        )}

        <div                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
          style={continueButtonLoading == true ? { opacity: "0.5" } : {}}
          className={`frame-259 ${Theme} btn-ctn-click ${
            startButtonShaking == true && "shake"
          }`}
          onClick={() =>
            continueButtonLoading == true                                        
              ? null
              : AncellarySectionActive !==  getlastTab()
              ? GoToNextTab()
              : GoToPaymentPage()
          }
        >
          <div
            className={`continue valign-text-middle ${Theme}_semibold color_white font16`}
          >
            {AncellarySectionActive !== getlastTab()
              ? "Proceed"
              : "Proceed To Pay"}
            {continueButtonLoading == true && <div className="loader"></div>}
          </div>
        </div>

        <div className="info-btn" onClick={() => GoToPaymentPage()}>
        <div className={`${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font16 skiptoPay`}>
            Skip To Payment 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddonsSection;
