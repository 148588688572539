import React, { useState, useEffect, useRef } from "react";
import "./DateSelector.css";
import images from "../../../../../Utils/importImages";

function DateSelector({
  Theme,
  Heading,
  width,
  onBlur,
  error,
  helperText,
  value,
  onChange,
  DropDownYears,
  clickedInside,
  setClickedInside,
  SetCallValidator
}) {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedDate, setSelectedDate] = useState(value[0]);
  const [selectedMonth, setSelectedMonth] = useState(value[1]);
  const [selectedYear, setSelectedYear] = useState(value[2]);

  const ref = useRef(null);

  const handleClickInside = (dropdown) => {
    setIsActive(true);
    setClickedInside(true);
    setActiveDropdown(dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        SetCallValidator(true);
        setIsActive(false);
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickedInside]);

  // Automatically open the month dropdown after selecting a date
  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  // Automatically open the year dropdown after selecting a month
  const handleSelectMonth = (month) => {
    setSelectedMonth(month.val);
  };

  // Close the dropdown after selecting a year
  const handleSelectYear = (year) => {
    setSelectedYear(year);
    setIsActive(false);
  };

  // Open the month dropdown once the date is selected
  useEffect(() => {

    if (selectedDate && isActive == true && activeDropdown === "date") {
      setActiveDropdown("month");
    }
    else if (selectedMonth && isActive == true && activeDropdown === "month") {

      setActiveDropdown("year");
    } else if (selectedYear && isActive == true && activeDropdown === "year") {

      setActiveDropdown(null);
    }
  }
    , [selectedDate, selectedMonth, selectedYear]);

  useEffect(() => {
    if (clickedInside === true) {

      const formattedDob = `${selectedDate || "Date"}-${selectedMonth || "Month"
        }-${selectedYear || "Year"}`;
      onChange(formattedDob);
    }
  }, [selectedDate, selectedMonth, selectedYear, onChange]);

  let getMonthName = (key) => {
    switch (key) {
      case '01':
        return "January";
      case '02':
        return "February";
      case '03':
        return "March";
      case '04':
        return "April";
      case '05':
        return "May";
      case '06':
        return "June";
      case '07':
        return "July";
      case '08':
        return "August";
      case '09':
        return "September";
      case '10':
        return "October";
      case '11':
        return "November";
      case '12':
        return "December";
    
     
  }
}
  return (
    <div className="dateselector-wrapper" style={{ width: width }}>
      <div
        ref={ref}
        className={`dateselector ${isActive ? "active" : ""} ${error && "error"
          } ${Theme}`}
        onClick={() => setIsActive(true)}
      >
        <div className={`${Theme}_regular font13 headbar ${Theme} ${error && "error"}`}>
          {Heading}
        </div>

        <div className="databar">
          {/* Date Section */}
          <div onClick={() => handleClickInside("date")} className="section">
            <div className={`${Theme}_regular color_light-cement font16 d-head`}>
              {selectedDate || "Date"}
            </div>
            {activeDropdown === "date" && (
              <div className="dropdown dropdown-date">
                {[...Array(31).keys()].map((day) => (
                  <div
                    key={day + 1}
                    onClick={() =>
                      handleSelectDate(day + 1 < 10 ? `0${day + 1}` : day + 1)
                    }
                    className={`${Theme}_${selectedDate == day + 1 ? "semibold" : "regular"
                      } color_light-cement font16 dropdown-item`}
                  >
                    {day + 1 < 10 ? `0${day + 1}` : day + 1}
                  </div>
                ))}
              </div>
            )}
            <img
              src={images[`dmy-arrow-${Theme === "proxima" ? "blue" : "gold"}.svg`]}
              alt="arrow"
              className={`dd-arrow ${activeDropdown === "date" && "open"}`}
            />
          </div>

          {/* Month Section */}
          <div onClick={() => handleClickInside("month")} className="section">
            <div className={`${Theme}_regular color_light-cement font16 d-head`}>
              {getMonthName(selectedMonth) || "Month"}
            </div>
            {activeDropdown === "month" && (
              <div className="dropdown dropdown-month">
                {[
                  {name : "January" ,  val : '01'},
                  {name : "February" ,  val : '02'},
                  {name : "March" ,  val : '03'},
                  {name : "April" ,  val : '04'},
                  {name : "May" ,  val : '05'},
                  {name : "June" ,  val : '06'},
                  {name : "July" ,  val : '07'},
                  {name : "August" ,  val : '08'},
                  {name : "September" ,  val : '09'},
                  {name : "October" ,  val : '10'},
                  {name : "November" ,  val : '11'},
                  {name : "December" ,  val : '12'},
                 
                ].map((month) => (
                  <div
                    key={month}
                    onClick={() => handleSelectMonth(month)}
                    className={`${Theme}_${selectedMonth == month.val ? "semibold" : "regular"
                      } color_light-cement font16 dropdown-item`}
                  >
                    {month.name}
                  </div>
                ))}
              </div>
            )}
            <img
              src={images[`dmy-arrow-${Theme === "proxima" ? "blue" : "gold"}.svg`]}
              alt="arrow"
              className={`dd-arrow ${activeDropdown === "month" && "open"}`}
            />
          </div>

          {/* Year Section */}
          <div onClick={() => handleClickInside("year")} className="section">
            <div className={`${Theme}_regular color_light-cement font16 d-head`}>
              {selectedYear || "Year"}
            </div>
            {activeDropdown === "year" && (
              <div className="dropdown dropdown-year">
                {DropDownYears.map((i) => (
                  <div
                    key={i}
                    onClick={() => handleSelectYear(i)}
                    className={`${Theme}_${selectedYear == i ? "semibold" : "regular"
                      } color_light-cement font16 dropdown-item`}
                  >
                    {i}
                  </div>
                ))}
              </div>
            )}
            <img
              src={images[`dmy-arrow-${Theme === "proxima" ? "blue" : "gold"}.svg`]}
              alt="arrow"
              className={`dd-arrow ${activeDropdown === "year" && "open"}`}
            />
          </div>
        </div>
      </div>

      {error && (
        <div className={`${Theme}_regular color_error-red errorbar`}>
          {helperText}
        </div>
      )}
    </div>
  );
}

export default DateSelector;
