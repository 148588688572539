import React, { useEffect, useState } from "react";
import "./BagsContentSection.css";
import TravellerRow from "../Components/TravellerRow";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import { setnextAncellaryTraveller, setnextAncellaryTravellerOnLoad, setSelectTheBag } from "../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import ErrorComponent from "../Components/ErrorComponent";

function BagsContentSection({ Theme , currentSelectedTab , currentBagsData}) {

  let dispatch  = useDispatch()

  let TravellersData = useSelector(
    (state) => state.ReviewPageFlight.TravellersDataFilledUp
  );

  const scrollToSection = (name) => {
    const wrapper = document.querySelector("." + name);
    if (wrapper) {
      wrapper.scrollIntoView({ behavior: "smooth", block: "center" }); // You can use 'auto' instead of 'smooth' for instant scrolling
    }
  };

  // auto scroll color  : #f6f6f6 

  let nextAncellaryTraveller = useSelector(
    (state) => state.ReviewPageFlight.nextAncellaryTraveller
  );

  let selectedtheTraveller = (curTraveller) => {
    dispatch(setnextAncellaryTraveller(curTraveller))
  };
  
  useEffect(() => {
    // set the current unselected traveller on load of screen 
  dispatch(setnextAncellaryTravellerOnLoad(1))
  
  } , [currentSelectedTab])

  useEffect(() => {
    scrollToSection("isActiveBag");
  } , [nextAncellaryTraveller])

  console.log("currentBagsData IS : " , currentBagsData);
  
  
  let onClickBagBtn = (item , action , bagIndex) => {
    dispatch(setSelectTheBag({data : item , travellersIndex : nextAncellaryTraveller , Keyaction : action , bagIndex}));
  }

  return (
    currentBagsData.error == true ? (
      <ErrorComponent Theme={Theme} Message={currentBagsData.errorMessage.Message} Heading={currentBagsData.errorMessage.heading} type="Bag" />
    ) : (

    <div className="bag-section">

      <div className="data-section">
        <div className="travellers-content">

        {TravellersData?.map((item, id) => {
             if(item.type === "INF") return null;
            return (
              <TravellerRow
                onTravellerSelection={selectedtheTraveller}
                id={id}
                item={item}
                RequestText={"Please Select Your Bag"}
                isActive={nextAncellaryTraveller == id}
                name={`${item.firstName} ${item.lastName}`}
                Theme={Theme}
                isSelected={
                  item.ancellaries.bags[currentSelectedTab[0]][
                    currentSelectedTab[1]
                  ].bagRequested !== "" &&
                  item.ancellaries.bags[currentSelectedTab[0]][
                    currentSelectedTab[1]
                  ].bagRequested !== null
                }
                Data={
                  (item.ancellaries.bags[currentSelectedTab[0]][
                    currentSelectedTab[1]
                  ].bagRequested !== "" &&
                    item.ancellaries.bags[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].bagRequested !== null) == true && 
                    `Bag of ${item.ancellaries.bags[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].bagRequested.Weight} ${item.ancellaries.bags[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].bagRequested.Weight_type}`
                  
                }
                price={
                  (item.ancellaries.bags[currentSelectedTab[0]][
                    currentSelectedTab[1]
                  ].bagRequested !== "" &&
                    item.ancellaries.bags[currentSelectedTab[0]][
                      currentSelectedTab[1]
                    ].bagRequested !== null) == true
                    ? item.ancellaries.bags[currentSelectedTab[0]][
                        currentSelectedTab[1]
                      ].bagRequested.Price
                    : 0
                }
              />
            );
          })}

        </div>
      </div>
      <div className={`bags-section ${Theme}`}>
        <div className="bags-wrapper">
          {currentBagsData.SeactionTabData.Data.map((item, id) => {

let ActiveCode = item.Traveller == undefined ? false : item.Traveller.includes(nextAncellaryTraveller);

            return (
              <div className={`bag-item ${ActiveCode && "isActiveBag"}`}>
                <div className="bag-info-container">
                  <div>
                    <img src={images["bag-icon-main.svg"]} alt="bag" />
                  </div>
                  <div className="bag-info">
                    <div className={`${Theme}_regular font16 color_gray-black`}>
                      Add Bag of {item.Weight} {item.Weight_type}
                    </div>

                    <div
                      className={`${Theme}_semibold color_${
                        item.Price == 0 ? "pine-green" : "deep-ocean"
                      } font14`}
                    >
          {
            item.Price == 0 ? "FREE" : <>
            <img src={images['dark-rupee-small.svg']} alt="" /> {item.Price}
            </>
          }
                  
                    </div>
                  </div>
                </div>

                <div className="bag-controllers-container">
                {item.Quantity > 0 ?  <img src={images[Theme == "proxima" ? "ancellary-minus-glow.png" : "ancellary-minus-glow-gold.png"]} alt="" onClick={() => onClickBagBtn(item , '-' , id)}/> : <img src={images["ancellary-minus-dull.png"]} alt="" />}
                  <div className={`${Theme}_regular font16 color_deep-ocean`}>
                  {item.Quantity}
                  </div>

                  {item.Quantity == TravellersData.length ? <img src={images[Theme == "proxima" ? 'ancellary-plus-glow.png' : 'ancellary-plus-glow-gold.png']} alt="" /> : <img onClick={() => onClickBagBtn(item , '+' , id)} src={images[Theme == "proxima" ? 'ancellary-plus-glow.png' : 'ancellary-plus-glow-gold.png']} alt="" /> }
          
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    )
  );
}

export default BagsContentSection;
