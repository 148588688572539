import React from 'react'
import "./UpgradefareBar.css";
import images from '../../../../../../Utils/importImages';

function UpgradeFareBar({Theme , OpenThePopup , mode}) {
  return (
    <div className="fare-upgrade-bar">
    <div className="frame-24">
      <div className="frame-24-2 frame-24-8">
        <img
          src={images[Theme == "proxima" ? "more-fares-icon-blue.svg" : "more-fares-icon-gold.svg"]}
          alt="Frame 169"
        />

        <div className="frame-2-5">
          <div className={`more-fares-available ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font18`}>
            More Fares Available
          </div>
          <div className={`with-flexible ${Theme}_semibold color_gravel font16`}>
            with flexible
          </div>
          <div className={`fare-rules-1 ${Theme}_semibold color_deep-ocean font16`}>
            Fare Rules
          </div>
        </div>
      </div>
      <div className={`frame-250-2 frame-250-3 ${Theme}`} onClick={() => OpenThePopup(mode)}>
        <div className={`upgrade-fare ${Theme}_semibold color_white font16`}>
          UPGRADE FARE
        </div>
      </div>
    </div>
  </div>
  )
}

export default UpgradeFareBar
