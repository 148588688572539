import React, { useEffect, useState } from "react";
import "./index.css";
import images from "../../../../../../Utils/importImages";
import FareSummaryDetailed from "../../../../../Components/Popups/Hoverers/FareSummaryDetailed";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorMessagePopup from "../../../../../Components/Popups/ErrorMessagePopup";
import { FareUpgrader } from "../../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function BottomBar({
  Data,
  showSelect,
  onSelect,
  topBarSelected,
  settopBarSelectedd,
  setselectedSection,
  FFSelected,
  topBarMode,
  airlineLogos,
  mode,
  Theme,
}) {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const upgradeLoading = useSelector(
    (state) => state.ReviewPageFlight.upgradeLoading
  );
  const upgradeLoadingError = useSelector(
    (state) => state.ReviewPageFlight.upgradeLoadingError
  );
  const searchKey = useSelector((state) => state.ReviewPageFlight.searchKey);
  const currentFarePrice = useSelector(
    (state) => state.ReviewPageFlight.currentFarePrice
  );

  const getTotalTravelers = () => {
    // Regular expression to match parameters a, i, and c in the searchKey string
    const regex = /[?&]a=(\d+)&c=(\d+)&i=(\d+)/;
    const match = searchKey.match(regex);

    if (match) {
      // Extract values of a, i, and c from the regex match
      const adults = parseInt(match[1], 10);
      const children = parseInt(match[2], 10);
      const infants = parseInt(match[3], 10);

      return adults + children + infants;
    } else {
      // Default to 0 if parameters are not found
      return 0;
    }
  };

  // const url = window.location.href;
  // const params = new URLSearchParams(new URL(url).search);
  // const adults = parseInt(params.get("a")) || 0;
  // const children = parseInt(params.get("c")) || 0;
  // const infants = parseInt(params.get("i")) || 0;

  const totalTravelers = getTotalTravelers();

  const getTotalBaseFare = (FFData) => {
    const { Travellers } = FFData;
    const adultFares = Travellers.Adults
      ? Travellers.Adults.map((adult) => +adult.Fare)
      : [0];
    const childFares = Travellers.Childs
      ? Travellers.Childs.map((child) => +child.Fare)
      : [0];
    const infantFares = Travellers.Infrants
      ? Travellers.Infrants.map((infant) => +infant.Fare)
      : [0];
    return [...adultFares, ...childFares, ...infantFares].reduce(
      (acc, fare) => acc + fare,
      0
    );
  };

  const getTotalTaxFare = (FFData) => {
    const { Travellers } = FFData;
    const adultFares = Travellers.Adults
      ? Travellers.Adults.map((adult) => +adult.Tax)
      : [0];
    const childFares = Travellers.Childs
      ? Travellers.Childs.map((child) => +child.Tax)
      : [0];
    const infantFares = Travellers.Infrants
      ? Travellers.Infrants.map((infant) => +infant.Tax)
      : [0];
    return [...adultFares, ...childFares, ...infantFares].reduce(
      (acc, fare) => acc + fare,
      0
    );
  };
  const baseFare = (() => {
    if (topBarMode === 1 || topBarMode === 1.5) {
      return Data.Segments !== undefined
        ? getTotalBaseFare(Data.FareFamilies.FFList[FFSelected[0]])
        : getTotalBaseFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalBaseFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            );
    } else if (topBarMode === 2) {
      return Data[0] && Data[1]
        ? getTotalBaseFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalBaseFare(Data[1].FareFamilies.FFList[FFSelected[1]])
        : getTotalBaseFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalBaseFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            );
    } else if (topBarMode === 3) {
      return Data[0] && Data[1] && Data[2]
        ? getTotalBaseFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalBaseFare(Data[1].FareFamilies.FFList[FFSelected[1]]) +
            getTotalBaseFare(Data[2].FareFamilies.FFList[FFSelected[2]])
        : getTotalBaseFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalBaseFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            ) +
            getTotalBaseFare(
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]]
            );
    } else if (topBarMode === 4) {
      return Data[0] && Data[1] && Data[2] && Data[3]
        ? getTotalBaseFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalBaseFare(Data[1].FareFamilies.FFList[FFSelected[1]]) +
            getTotalBaseFare(Data[2].FareFamilies.FFList[FFSelected[2]]) +
            getTotalBaseFare(Data[3].FareFamilies.FFList[FFSelected[3]])
        : getTotalBaseFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalBaseFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            ) +
            getTotalBaseFare(
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]]
            ) +
            getTotalBaseFare(
              Data.Connections[3].FareFamilies.FFList[FFSelected[3]]
            );
    } else if (topBarMode === 2.5) {
      return getTotalBaseFare(Data.FareFamilies.FFList[FFSelected[0]]);
    } else {
      // Handle other topBarMode cases here
      return 0;
    }
  })();

  const taxFare = (() => {
    if (topBarMode === 1 || topBarMode === 1.5) {
      return Data.Segments !== undefined
        ? getTotalTaxFare(Data.FareFamilies.FFList[FFSelected[0]])
        : getTotalTaxFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalTaxFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            );
    } else if (topBarMode === 2) {
      return Data[0] && Data[1]
        ? getTotalTaxFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalTaxFare(Data[1].FareFamilies.FFList[FFSelected[1]])
        : getTotalTaxFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalTaxFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            );
    } else if (topBarMode === 3) {
      return Data[0] && Data[1] && Data[2]
        ? getTotalTaxFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalTaxFare(Data[1].FareFamilies.FFList[FFSelected[1]]) +
            getTotalTaxFare(Data[2].FareFamilies.FFList[FFSelected[2]])
        : getTotalTaxFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalTaxFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            ) +
            getTotalTaxFare(
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]]
            );
    } else if (topBarMode === 4) {
      return Data[0] && Data[1] && Data[2] && Data[3]
        ? getTotalTaxFare(Data[0].FareFamilies.FFList[FFSelected[0]]) +
            getTotalTaxFare(Data[1].FareFamilies.FFList[FFSelected[1]]) +
            getTotalTaxFare(Data[2].FareFamilies.FFList[FFSelected[2]]) +
            getTotalTaxFare(Data[3].FareFamilies.FFList[FFSelected[3]])
        : getTotalTaxFare(
            Data.Connections[0].FareFamilies.FFList[FFSelected[0]]
          ) +
            getTotalTaxFare(
              Data.Connections[1].FareFamilies.FFList[FFSelected[1]]
            ) +
            getTotalTaxFare(
              Data.Connections[2].FareFamilies.FFList[FFSelected[2]]
            ) +
            getTotalTaxFare(
              Data.Connections[3].FareFamilies.FFList[FFSelected[3]]
            );
    } else if (topBarMode === 2.5) {
      return getTotalTaxFare(Data.FareFamilies.FFList[FFSelected[0]]);
    } else {
      // Handle other topBarMode cases here
      return 0;
    }
  })();

  let [mouseIn, setmouseIn] = useState(false);

  // useEffect(() => {

  //   let timeoutId;

  //   if (upgradeLoadingError === true) {
  //     // Set a timeout to dispatch the action after 3 seconds
  //     timeoutId = setTimeout(() => {
  //       dispatch(setupgradeLoadingError());
  //     }, 3000); // 3000 milliseconds = 3 seconds
  //   }

  //   // Clean up the timeout if the component unmounts or the condition changes
  //   return () => clearTimeout(timeoutId);
  // } , [upgradeLoadingError])

  return (
    <div className={`bottombar ${Theme}`}>
      <div className="frame-242-17">
        <div className="frame-242">
          <div className="frame-173-7">
            <div className="frame-241">
              <img
                className="frame-169-9"
                src={images["rupee-gray.svg"]}
                alt="Frame 169"
              />
              <div
                className={`text-348 valign-text-middle ${Theme}_semibold color_eerie-black font20`}
              >
                {Math.ceil(baseFare + taxFare)}
              </div>
            </div>
            <div
              className={`travellers-4 valign-text-middle ${Theme}_regular color_storm-dust font13`}
            >
              Travellers : {totalTravelers}
            </div>
          </div>
        </div>
        <div className="frame-242-18 info-icon-hov">
          <div className="frame-242-19">
            <div
              className={`fare valign-text-middle ${Theme}_regular color_${
                Theme == "proxima" ? "endeavour" : "burning-gold"
              } font14`}
            >
              Fare Summary
            </div>
          </div>
          <img
            src={
              images[
                Theme == "proxima" ? "fare-info-sign.svg" : "fare-info-gold.svg"
              ]
            }
            alt="fareInfo"
          />
          <div className={`fare-summary-tooltip ${Theme}`}>
            <FareSummaryDetailed
              base={baseFare}
              tax={taxFare}
              totalTravellers={totalTravelers}
              Theme={Theme}
            />
          </div>
        </div>
      </div>

      {(upgradeLoadingError == true ||
        currentFarePrice == baseFare + taxFare) &&
        mouseIn == true && (
          <ErrorMessagePopup
            Theme={Theme}
            style={{
              display: "flex",
              alignItems: "end",
              width: " max-content",
              zIndex: 1,
              backgroundColor: "#FFE0DA",
              position: "absolute",
              top: "-35%",
              left: "77%",
              padding: "4px 8px",
            }}
            downArrow={true}
            // addClass={searchBarData?.searchClicked}
            message={"Please Select Any Other Fare To Upgrade"}
          />
        )}

      {topBarMode == 1 && (
        <div
          onMouseOver={() => setmouseIn(true)}
          onMouseOut={() => setmouseIn(false)}
          className={`frame-242-22 ${Theme}`}
          style={
            upgradeLoadingError == true ||
            currentFarePrice == baseFare + taxFare
              ? { opacity: "0.5", cursor: "not-allowed" }
              : {}
          }
          onClick={() =>
            upgradeLoadingError == true ||
            currentFarePrice == baseFare + taxFare
              ? null
              : dispatch(FareUpgrader(Data, FFSelected, Theme))
          }
        >
          <div className="frame-103-1">
            <div className="book valign-text-middle">UPGRADE</div>
          </div>
          {upgradeLoading == true && <div className="loader"></div>}
        </div>
      )}
      {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
        (topBarSelected < topBarMode - 1 ? (
          <div className={`frame-242-22 ${Theme}`}>
            <div
              className="frame-103-1"
              onClick={() => {
                settopBarSelectedd(topBarSelected + 1);
                setselectedSection(topBarSelected + 1);
              }}
            >
              <div className="book valign-text-middle">Continue</div>
            </div>
          </div>
        ) : (
          <div
            className={`frame-242-22 ${Theme}`}
            onMouseOver={() => setmouseIn(true)}
            onMouseOut={() => setmouseIn(false)}
            style={
              upgradeLoadingError == true ||
              currentFarePrice == baseFare + taxFare
                ? { opacity: "0.5", cursor: "not-allowed" }
                : {}
            }
            onClick={() =>
              upgradeLoadingError == true ||
              currentFarePrice == baseFare + taxFare
                ? null
                : dispatch(FareUpgrader(Data, FFSelected, Theme))
            }
          >
            <div className="frame-103-1">
              <div className="book valign-text-middle">UPGRADE</div>
            </div>
            {upgradeLoading == true && <div className="loader"></div>}
          </div>
        ))}
      {(topBarMode == 2.5 || topBarMode == 1.5) && (
        <div
          onMouseOver={() => setmouseIn(true)}
          onMouseOut={() => setmouseIn(false)}
          className={`frame-242-22 ${Theme}`}
          style={
            upgradeLoadingError == true ||
            currentFarePrice == baseFare + taxFare
              ? { opacity: "0.5", cursor: "not-allowed" }
              : {}
          }
          onClick={() =>
            upgradeLoadingError == true ||
            currentFarePrice == baseFare + taxFare
              ? null
              : dispatch(FareUpgrader(Data, FFSelected, Theme))
          }
        >
          <div className="frame-103-1">
            <div className="book valign-text-middle">UPGRADE</div>
          </div>
          {upgradeLoading == true && <div className="loader"></div>}
        </div>
      )}
    </div>
  );
}

export default BottomBar;
