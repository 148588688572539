

const DEVELOPMENT_URL = "http://127.0.0.1:8000/api/";
const PRODUCTION_URL = "https://api.stfares.com/api/";

const PAYMENT_URL_DEVELOPMENT = "http://localhost:3001/";
const PAYMENT_URL_PRODUCTION = "https://www.payments.stfares.com/";

export const MAIN_URL = PRODUCTION_URL;

export const MAIN_PAYMENT_URL = PAYMENT_URL_PRODUCTION