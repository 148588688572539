import React from 'react'
import images from '../../../../../Utils/importImages'
import "./ErrorComponent.css"

function ErrorComponent({Theme, Message  ,  Heading , type}) {
  return (
    <div className='ancellary-error'>
      
      <div><img src={images[type == "Meal" ? "meal-error-logo.svg" : ""]} alt="icon"/></div>
      <div className={`${Theme}_semibold color_gray-black font26`}>{Heading}</div>
      <div className={`${Theme}_regular color_gravel font18`}>{Message}</div>
    </div>
  )
}

export default ErrorComponent
