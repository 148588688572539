export const fareOptions = [
  "Regular",
  "Armed Forces",
  "Student",
  "Senior Citizen",
];

export const Travellers = {
  adults: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  childs: [0, 1, 2, 3, 4, 5, 6],
  infants: [0, 1, 2, 3, 4, 5, 6],
};

export const TravellerClasses = [
  "Economy",
  "Premium Economy",
  "Business",
  "First",
];

export const DaysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const DefaultPopularCities = [
  {
    airport_name: "Indira Gandhi International",
    airport_code: "DEL",
    city_name: "Delhi",
    city_code: "DEL",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
  {
    airport_name: "Chhatrapati Shivaji International airport",
    airport_code: "BOM",
    city_name: "Mumbai",
    city_code: "BOM",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
  {
    airport_name: "Netaji Subhas Chandra",
    airport_code: "CCU",
    city_name: "Kolkata",
    city_code: "CCU",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
  {
    airport_name: "Sardar Vallabhbhai Patel International Airport",
    airport_code: "AMD",
    city_name: "Ahmedabad",
    city_code: "AMD",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
  {
    airport_name: "Chennai",
    airport_code: "MAA",
    city_name: "Chennai",
    city_code: "MAA",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
  {
    airport_name: "Amausi",
    airport_code: "LKO",
    city_name: "Lucknow",
    city_code: "LKO",
    country_code: "IN",
    country_name: "India",
    active: 1,
    has_nearest_airport: 0,
    nearest_airport: [],
    has_connecting_airport: 0,
    connecting_airport: [],
    has_via_airport: 0,
    via_airport: [],
  },
];

export const SearchBarErrorMessages = {
  From_To: "From & To airports cannot be the same",
  From: "Please Select Origin Airport",
  To: "Please Select Destination Airport",
  TravellersPopup: [
    "Adults + Childs Can't be More Than 9",
    "Infants Can't be More Than Adults",
  ],
};

export const TravellersFareMessage = (type) => {
  return `Only adult passengers can avail of ${type} fares`;
};

export const FlightsResultPageErrorMain = {
  DDNF: "Sorry, Flights Not Found",
  IVD: "Invalid Date",
  PEFD: "INVALID DATES",
  TE: "TECHNICAL ERROR",
  IC: "INTERNET IS SLOW",
  FNA: "FLIGHT NOT AVAILABLE",
  SRD: "SERVER DOWN",
  IVC: "INVALID COMBINATIONS",
  IVL: "INVALID LINK",
  FUS: "UNABLE TO SELL",
  FFNA: "FARE FAMILY NOT AVAILABLE",
  ANA: "ANCELLARIES NOT AVAILABLE",
  SE: "SESSION EXPIRED",
  NMA: "NO MEAL AVAILABLE",
  NSA: "NO Seat Map AVAILABLE",
  NBA: "NO BAG AVAILABLE",
  TV : "INVALID TRAVELLERS",
  TVM: "TRAVELLERS INVALID DATA",
  TVMA: "TRAVELLERS ANCELLARIES DATA",
  RBTC : "RE-BOOKING",
  ANCE : "ANCELLARIES ERROR",
  PC : "PRICE CHANGED",
};

export const FlightsResultPageErrorMainDescription = {
  DDNFD: [
    "We could not find flights for this search",
    "Please go back to make a different selection",
  ],
  PEFDD: [
    "We could not find flights for this search for these dates",
    "Please go back to make a different selection with Today/upcoming dates",
  ],
  TED: [
    "We are currently experiencing a technical problem.",
    "Please try again after a while",
  ],
  ICD: [
    "Your Internet connection speed is low",
    "Please try searching with a different connection",
  ],
  FNAD: ["No Flight Available for this Route", "Please try another Route"],
  SRDD: ["Sorry , Our Servers are Down as of Now", "Please try after SomeTime"],
  IVCD: [
    "The selected flight timings are not compatible. Kindly try a different time option",
  ],
  IVLD: [
    "Your Link is Invalid to start a Booking",
    "You can start your search again and complete your booking.",
  ],
  FUSD: [
    "The selected flight is not able to sell now , Please choose Another flight to continue",
  ],
  FFNAD: [
    "Your Choosen Fare Family is Not Available Now Go Back and Choose Any Other FareFamily",
  ],
  ANAD: ["No Ancellary Data is Available for This Flight"],
  SED: [
    "You Took So Long To Proceed . Session expired",
    "Go back and Start with new Booking",
  ],
  NMAD: ["No Meal Available For this Flight"],
  NSAD: ["No Seat Map Available For this Flight"],
  NBAD: ["No Extra Bag Available For this Flight"],
  TVD : ['Travellers are InValid'],
  TVMD : ['Travellers Data Provided is InValid'],
  TVMAD : ['Ancellaries Data Provided is InValid'],
  RBTCD : ['Dear traveller, you already have an active booking session with a different passenger details. Since changing the passenger data is not possible at this stage of the booking, we request you to end this session and begin a new one.'],
  ANCED : ["The Below Choosed Ancellaries are not Available"],
  PCD: ['Meanwhile were booking the flight the flight of this flight changed']
};
