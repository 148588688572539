import React, { useEffect, useRef, useState } from "react";
import "./AddonsTripSelectionBar.css";
import images from "../../../../../Utils/importImages";

function AddonsTripSelectionBar({
  Type,
  Mode,
  Theme,
  SelectionBarData,
  headings,
  Selected,
  onChange,
}) {
  let [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if(Type == 1){
      if (SelectionBarData.length == 3 || SelectionBarData.length == 4) {
        if (SelectionBarData.length == 3) {
          let isScrollAv =
            SelectionBarData[0].length +
              SelectionBarData[1].length +
              SelectionBarData[2].length >
            8;
          setIsScrollable(isScrollAv);
        } else if (SelectionBarData.length == 4) {
          let isScrollAv =
            SelectionBarData[0].length +
              SelectionBarData[1].length +
              SelectionBarData[2].length +
              SelectionBarData[3].length >
            8;
          setIsScrollable(isScrollAv);
        }
      }
    } else {
      if (Type === 2 && (SelectionBarData.length === 3 || SelectionBarData.length === 4)) {
        const totalCharacters = SelectionBarData.flat().reduce((acc, item) => acc + item.length, 0);
        setIsScrollable(totalCharacters > 65);
      }
    }
  }, [SelectionBarData]);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -200, // Adjust the value as per your requirement
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200, // Adjust the value as per your requirement
      behavior: "smooth",
    });
  };

  if (Type == 1) {
    if (SelectionBarData.length == 1) {
      return (
        <div className="main-trip-container" >

        <div className="selector-wrapper">
          <div className="selector-item">
            <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
              {headings[0]}
            </div>

            <div className="trippers">
              {SelectionBarData[0].map((item, id) => {
                return (
                  <div
                    key={id}
                    onClick={() => onChange(0 , id)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                      (Selected[0] == 0 && Selected[1] == id) && "selected"
                    } ${Theme}`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        </div>
      );
    } else if (SelectionBarData.length == 2) {
      return (
        <div className="main-trip-container">
          <div className="selector-wrapper">
            <div className="selector-item">
              <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
                {headings[0]}
              </div>

              <div className="trippers">
                {SelectionBarData[0].map((item, id) => {
                  return (
                    <div
                      key={id}
                      onClick={() => onChange(0 , id)}
                      className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                        (Selected[0] == 0 && Selected[1] == id) && "selected"
                      } ${Theme}`}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="selector-wrapper">
            <div className="selector-item">
              <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
                {headings[1]}
              </div>

              <div className="trippers">
                {SelectionBarData[1].map((item, id) => {
                  return (
                    <div
                      key={id}
                      onClick={() => onChange(1 , id)}
                      className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                      (Selected[0] == 1 && Selected[1] == id) && "selected"
                    
                      } ${Theme}`}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (SelectionBarData.length == 3) {
      if (isScrollable == true) {
        return (
          <div className="scrollable-container">
            <img
              src={images["backward-scroll-btn-blue.svg"]}
              className="scroll-btn-img back"
              alt="back"
              onClick={scrollLeft}
            />
            <div className="scroll-content" ref={scrollContainerRef}>
              {SelectionBarData.map((data, index) => (
                <div className="selector-wrapper" key={index}>
                  <div className="selector-item">
                    <div
                      className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                    >
                      {headings[index]}
                    </div>
                    <div className="trippers">
                      {data.map((item, id) => {
                        return (
                          <div
                            key={id}
                            onClick={() => onChange(index , id)}
                            className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                               (Selected[0] == index && Selected[1] == id) && "selected"
                            } ${Theme}`}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <img
              src={images["forward-scroll-btn-blue.svg"]}
              alt="forward"
              className="scroll-btn-img front"
              onClick={scrollRight}
            />
          </div>
        );
      } else {
        return (
          <div className="main-trip-container">
            {SelectionBarData.map((data, index) => (
              <div className="selector-wrapper" key={index}>
                <div className="selector-item">
                  <div
                    className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                  >
                    {headings[index]}
                  </div>
                  <div className="trippers">
                    {data.map((item, id) => {

                      return (
                        <div
                          key={id}
                          onClick={() => onChange(index , id)}
                          className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                           (Selected[0] == index && Selected[1] == id) && "selected"
                          } ${Theme}`}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    } else if (SelectionBarData.length == 4) {
      if (isScrollable == true) {
        return (
          <div className="scrollable-container">
            <img
              src={images["backward-scroll-btn-blue.svg"]}
              className="scroll-btn-img back"
              alt="back"
              onClick={scrollLeft}
            />
            <div className="scroll-content" ref={scrollContainerRef}>
              {SelectionBarData.map((data, index) => (
                <div className="selector-wrapper" key={index}>
                  <div className="selector-item">
                    <div
                      className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                    >
                      {headings[index]}
                    </div>
                    <div className="trippers">
                      {data.map((item, id) => {

                        return (
                          <div
                            key={id}
                            onClick={() => onChange(index , id)}
                            className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                           (Selected[0] == index && Selected[1] == id) && "selected"
                            } ${Theme}`}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <img
              src={images["forward-scroll-btn-blue.svg"]}
              alt="forward"
              className="scroll-btn-img front"
              onClick={scrollRight}
            />
          </div>
        );
      } else {
        return (
          <div className="main-trip-container">
            {SelectionBarData.map((data, index) => (
              <div className="selector-wrapper" key={index}>
                <div className="selector-item">
                  <div
                    className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                  >
                    {headings[index]}
                  </div>
                  <div className="trippers">
                    {data.map((item, id) => {
 
                      return (
                        <div
                          key={id}
                          onClick={() => onChange(index , id)}
                          className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                          (Selected[0] == index && Selected[1] == id) && "selected"
                          } ${Theme}`}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    }
  } else {
    if (SelectionBarData.length == 1) {
      return (
        <div className="main-trip-container" >

        <div className="selector-wrapper">
          <div className="selector-item">
            <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
              {headings[0]}
            </div>

            <div className="trippers">
                  <div
                
                    onClick={() => onChange(0 , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                   (Selected[0] == 0 && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {SelectionBarData[0][0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {SelectionBarData[0][1]} 
                  </div>
                      
                  </div>
          
            </div>
          </div>
        </div>
        </div>
      );
    } else if (SelectionBarData.length == 2) {
      return (
        <div className="main-trip-container">
          <div className="selector-wrapper">
            <div className="selector-item">
              <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
                {headings[0]}
              </div>

              <div className="trippers">
                  <div
                
                onClick={() => onChange(0 , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                     (Selected[0] == 0 && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {SelectionBarData[0][0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {SelectionBarData[0][1]} 
                  </div>
                      
                  </div>
          
            </div>
            </div>
          </div>
          <div className="selector-wrapper">
            <div className="selector-item">
              <div className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}>
                {headings[1]}
              </div>

              <div className="trippers">
                  <div
                
                onClick={() => onChange(1 , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                  (Selected[0] == 1 && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {SelectionBarData[1][0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {SelectionBarData[1][1]} 
                  </div>
                      
                  </div>
          
            </div>
            </div>
          </div>
        </div>
      );
    } else if (SelectionBarData.length == 3) {
      if (isScrollable == true) {
        return (
          <div className="scrollable-container">
            <img
              src={images["backward-scroll-btn-blue.svg"]}
              className="scroll-btn-img back"
              alt="back"
              onClick={scrollLeft}
            />
            <div className="scroll-content" ref={scrollContainerRef}>
              {SelectionBarData.map((data, index) => (
                <div className="selector-wrapper" key={index}>
                  <div className="selector-item">
                    <div
                      className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                    >
                      {headings[index]}
                    </div>
                    <div className="trippers">
                      {data.map((item, id) => {

                        return (
                          <div
                            key={id}
                            onClick={() => onChange(index , 0)}
                            className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                            (Selected[0] == index && Selected[1] == 0) && "selected"
                            } ${Theme}`}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <img
              src={images["forward-scroll-btn-blue.svg"]}
              alt="forward"
              className="scroll-btn-img front"
              onClick={scrollRight}
            />
          </div>
        );
      } else {
        return (
          <div className="main-trip-container">
            {SelectionBarData.map((data, index) => (
              <div className="selector-wrapper" key={index}>
                <div className="selector-item">
                  <div
                    className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                  >
                    {headings[index]}
                  </div>
                  <div className="trippers">
                  <div
                
                onClick={() => onChange(index , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                (Selected[0] == index && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {data[0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {data[1]} 
                  </div>
                      
                  </div>
          
            </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    } else if (SelectionBarData.length == 4) {
      if (isScrollable == true) {
        return (
          <div className="scrollable-container">
            <img
              src={images["backward-scroll-btn-blue.svg"]}
              className="scroll-btn-img back"
              alt="back"
              onClick={scrollLeft}
            />
            <div className="scroll-content" ref={scrollContainerRef}>
              {SelectionBarData.map((data, index) => (
                <div className="selector-wrapper" key={index}>
                  <div className="selector-item">
                    <div
                      className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                    >
                      {headings[index]}
                    </div>
                    <div className="trippers">
                  <div
                
                onClick={() => onChange(index , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                    (Selected[0] == index && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {data[0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {data[1]} 
                  </div>
                      
                  </div>
          
            </div>
                  </div>
                </div>
              ))}
            </div>
            <img
              src={images["forward-scroll-btn-blue.svg"]}
              alt="forward"
              className="scroll-btn-img front"
              onClick={scrollRight}
            />
          </div>
        );
      } else {
        return (
          <div className="main-trip-container">
            {SelectionBarData.map((data, index) => (
              <div className="selector-wrapper" key={index}>
                <div className="selector-item">
                  <div
                    className={`head ${Theme}_semibold font13 color_${Theme == "proxima" ? "endeavour" : "choclate"}`}
                  >
                    {headings[index]}
                  </div>
                  <div className="trippers">
                  <div
                
                onClick={() => onChange(index , 0)}
                    className={`item ${Theme}_regular font16 color_${Theme == "proxima" ? "rhino" : "dark-galaxy"} ${
                   (Selected[0] == index && Selected[1] == 0) && "selected"
                    } ${Theme}`}
                  >
                    <div className="info">
                    {data[0]} 
                    <img src={images[Theme == "proxima" ? 'tripper-arrow-blue.svg' : 'golden-arrow.svg']} alt="arrow" />
                    {data[1]} 
                  </div>
                      
                  </div>
          
            </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    }
  }
}

export default AddonsTripSelectionBar;
