import React from 'react'
import "./InformationSection.css";
import images from '../../../../../../Utils/importImages';

function InformationSection({Theme , informationChecked , setInformationChecked , informationCheckedError , setinformationCheckedError , startShake}) {
  return (
   <div className='information-wrapper'>
         <div className={`frame-177 ${Theme}`}>
                  <div className="frame-170-2 frame-170-3">
                    <div className={`important-information ${Theme}_semibold color_optera-galaxy font16`}>IMPORTANT INFORMATION</div>
                  </div>
                </div>
                <div className="frame-2-12">
                  <div className="frame-243-19">
                    <div className="frame-243-3">
                      <div className="frame-243-4">
                        <img className="frame-243-5" src={images[`frame-243-8.svg`]} alt="Frame 243" />
                        <div className={`custom-combination ${Theme}_semibold color_deep-ocean font16`}>Custom Combination</div>
                      </div>
                      <ul className="frame-243-6">
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        You may be required to transit visa for your flight. Please check with the airlines or stfares
                        helpline.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Follow standard practices like hand luggage, use of mask &amp; social
                        distancing to comfort COVID-19 pandemic.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Before making this booking, please do check
                          travel rules on country&#39;s regulating website. Country travel guidelines may change without
                          notice
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Remember to web check in before arriving at the airport.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        It is mendatory for
                          all passengers to carry a nagative RT-PLR test report with QR code for boarding international
                          flights to destinations where test reports are required as per the guidelines issued by the
                          destination countries.
                            </li>
                    
                        
                      </ul>
                    </div>
                  </div>
                  <div className="frame-244-4">
                    <div className="frame-243-3">
                      <div className="frame-243-4">
                        <img className="frame-243-5" src={images["frame-243-8.svg"]} alt="Frame 243" />
                        <div className={`custom-combination-1 ${Theme}_semibold color_deep-ocean font16`}>Custom Combination</div>
                      </div>
                      <ul className="frame-243-6">
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        You may be required to transit visa for your flight. Please check with the airlines or stfares
                        helpline.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Follow standard practices like hand luggage, use of mask &amp; social
                        distancing to comfort COVID-19 pandemic.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Before making this booking, please do check
                          travel rules on country&#39;s regulating website. Country travel guidelines may change without
                          notice
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        Remember to web check in before arriving at the airport.
                            </li>
                        <li className={`you-may-be-required ${Theme}_regular color_deep-ocean font14`}>
                        It is mendatory for
                          all passengers to carry a nagative RT-PLR test report with QR code for boarding international
                          flights to destinations where test reports are required as per the guidelines issued by the
                          destination countries.
                            </li>
                    
                        
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`frame-269 ${Theme} informationCheckError`}>
                  <div className="buttons">
                    <div className="frame-243-20">
                      <div className={`frame-246-4 ${startShake == true && "shaketheinfo"}`}>
                        <div className="frame-202-1" onClick={() => {setInformationChecked(!informationChecked); setinformationCheckedError(false)}}>
                           {informationChecked == true && <img src={images['small-tick.svg']} alt="smalltick" /> }
                        </div>
                
                        <div className={`i-have-read-and-unde ${Theme}_semibold color_deep-ocean font15`}>
                          I have read and understood all the above mentioned information
                        </div>
                      
                      </div>
                    </div>
                  </div>
                  {
                          informationCheckedError == true && 
                  <div className={`please-select-this-checkbox-to-proceed ${Theme}_regular color_carmine font14`}>
                    Please select this checkbox to proceed.
                  </div>
}
                </div>
   </div>
  )
}

export default InformationSection
