import React, { useEffect, useState } from "react";
import { Countrieslist } from "../../../../../Constants/CountriesAndCodes";
import { Autocomplete, Box, TextField } from "@mui/material";
import "./CountrySelector.css";
import { useSelector } from "react-redux";

function CountrySelector({Theme , IconComponent , name , onChange , value , width}) {
  
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{
        width: width,
        "& .MuiOutlinedInput-root": {
          borderColor: Theme == "averta" ? "#C2A07C" : "inherit",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
          },
        },
        "& .MuiInputLabel-root": {
          color: Theme == "averta" ? "#C2A07C" : "inherit",
          fontSize: "16px",
          fontFamily: `var(--font-family-${
            Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
          })`,
          fontStyle: "normal",
          fontWeight: "400",
          "&.Mui-focused": {
            color: Theme == "averta" ? "#C2A07C" : "#0057B7",
          },
        },
      }}
      value={value}
      options={Countrieslist}
      autoHighlight
      disableClearable 
      onChange={(event, newValue) => onChange(newValue)}
      popupIcon={IconComponent}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
          key={`${option.code}-${option.name}-${option.phone}-${option.nationality}`} 
            component="li"
            sx={{
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontweight: "400",
                "& > img": { mr: 2, flexShrink: 0 }
              }}
            // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.name} ({option.code})
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
        sx={{
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontweight: "400"
          }}
  
          {...params}
          label={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default CountrySelector;
