import React, { useState } from "react";
import "./farefamilycontent.css";
import images from "../../../../../../Utils/importImages";

function FareFamilyContent({ selected, No,topBarSelected, onSelect, data , Theme}) {
  let [HideFFDetails, setHideFFDetails] = useState(true);

  let showSignImgBag = (data) => {
    if (data == "No Data Available") {
      // failed
      return (
        <img className="check-6" src={images["check-31.svg"]} alt="check" />
      );
    } else {
      // success
      return (
        <img className="check-3" src={images["check-2.svg"]} alt="check" />
      );
    }
  };

  let showSignImg = (data) => {

    if(data == "To Be Made"){
      return (
        <img
        className="check-4"
        src={images["ff-non-confirm-icon.svg"]}
        alt="check"
      />
      )
    } else if(data == "Not Known"){
      // banned :
      return (
        <img
        className="check-3"
        src={images["ff-banned.svg"]}
        alt="check"
      />
      )

    } else {
      
      // success
      return ( <img
      className="check-3"
      src={images["check-2.svg"]}
      alt="check"
    />)

    }
    
  }

  return (
    <div className={`ff-content-hov ${Theme} ${selected == true ? "selected" : ""}`}>
      <div className="frame-238-8">
        <div
          className={selected == true ? `frame-241-7 ${Theme}` : `frame-241-7-2 ${Theme}`}
          style={HideFFDetails == false ? { borderBottom: "none" } : {}}
        >
          <img
            className="frame-169"
            src={
              images[selected == true ? (Theme == "proxima" ? "selected-ff.svg" : "selected-ff-gold.svg") : (Theme == "proxima" ?"ff-non-select.svg" : "non-selected-ff-gold.svg")]
            }
            alt="Frame 169"
          />
          <div className={`frame-24-1-1 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font13 flexi-economy`}>
            {data.Name.heading == undefined ? data.Name : data.Name.heading}
          </div>
        </div>
        {HideFFDetails && (
          <>
            <div className="frame-241-1">
              <div className={`frame-242-13 ${Theme}`}>
                <div className="frame-24-2">
                  <div className={`baggage ${Theme}_semibold color_eerie-black font16`}>
                    Baggage
                  </div>
                </div>
                <div className="frame-24-3">
                  <div className="frame-2">
                   {showSignImgBag(data.CheckInBag)}
                    <div className={`check-in-46-kgs-2-pieces-x-23-kgs ${Theme}_regular color_eerie-black font14`}>
                      Check-in: {data.CheckInBag}
                    </div>
                  </div>
                  <div className="frame-2">
                   {showSignImgBag(data.CabinBag)}
                    <div className={`cabin-7-kgs ${Theme}_regular color_eerie-black font14`}>
                      Cabin: {data.CabinBag}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`frame-241-8 ${Theme}`}>
                <div className="frame-24-2">
                  <div className={`fare ${Theme}_semibold color_eerie-black font16`}>
                    Fare Rules
                  </div>
                </div>
                <div className="frame-24-3">
                  <div className="frame-2">
                    {showSignImg(data.Cancellation)}
                    <div className="frame-2-7 frame-2-10">
                      <div className={`cancellation ${Theme}_regular color_eerie-black font14`}>
                      Cancellation : {data.Cancellation} 
                      </div>
                    </div>
                  </div>
                  <div className="frame-2">
                  {showSignImg(data.DateChange)}
                    <div className={`non-changeable-date ${Theme}_regular color_eerie-black font14`}>
                      Date Change : {data.DateChange}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`frame-243-1 frame-243-6 ${Theme}`}>
                <div className="frame-24-2">
                  <div className={`seats-meals-more ${Theme}_semibold color_eerie-black font16`}>
                    Seats, Meals &amp; More
                  </div>
                </div>
                <div className="frame-24-3">
                  <div className="frame-2">
                  {showSignImg(data.Seat)}
                    <div className="frame-2-7 frame-2-10">
                    <div className={data.Seat == "Not Known" ? `seats ${Theme}_regular color_eerie-black font14` : `complimentary-5 ${Theme}_regular color_stack font14`}>
                        Seats : {data.Seat}
                      </div>
                    </div>
                  </div>
                  <div className="frame-24-4">
                  {showSignImg(data.Meal)}
                    <div className={data.Meal == "Not Known" ? `seats ${Theme}_regular color_eerie-black font14` : `complimentary-5 ${Theme}_regular color_stack font14`}>
                      Meals : {data.Meal}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="frame-231">
              <div className={`frame-230-1 ${Theme}`}>
              <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                <div className="frame-209">
                  <div className="frame-211">
                    <div className="frame-203">
                      <div className={`use-code-stdeals-for ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font13`}>
                        Use code STDEALS for Rs. 899 off per person
                      </div>
                    </div>

                    <div className="frame-1">
                      <div className={`text-3-1 text-3-3 ${Theme}_semibold color_magic-potion font13`}>
                        +
                      </div>
                      <div className={`get-150-off-on-upi-payments ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font13`}>
                        Get&nbsp;&nbsp;150 off on UPI payments
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}

export default FareFamilyContent;
