export let createCouponsFlight = (ResultType, Data) => {
  if (Data[0] !== undefined) {
    if (Data[0].Fare.DiscountsAndLess.Coupon.Available == true) {
      return [
        {
          Code: "STFDEALS",
          Text1: "Use this code to get",
          Text2: "instant discount upto ",
          Val:
            Data[0].Fare.DiscountsAndLess.Coupon.Discount.Amount_in_Percentage >
            0
              ? (Data[0].Fare.BaseFare *
                  Data[0].Fare.DiscountsAndLess.Coupon.Discount
                    .Amount_in_Percentage) /
                100
              : Data[0].Fare.DiscountsAndLess.Coupon.Discount.Amount,
        },
      ];
    } else {
      return [];
    }
  } else {
    if (Data.Connections[0].Fare.DiscountsAndLess.Coupon.Available == true) {
      return [
        {
          Code: "STFDEALS",
          Text1: "Use this code to get",
          Text2: "instant discount upto ",
          Val:
            Data.Connections[0].Fare.DiscountsAndLess.Coupon.Discount
              .Amount_in_Percentage > 0
              ? (Data.Connections[0].Fare.BaseFare *
                  Data.Connections[0].Fare.DiscountsAndLess.Coupon.Discount
                    .Amount_in_Percentage) /
                100
              : Data.Connections[0].Fare.DiscountsAndLess.Coupon.Discount
                  .Amount,
        },
      ];
    } else {
      return [];
    }
  }
};

export let rulesMakerTravellers = (fligthData, noOfTime) => {
  let rules = {
    fnl: { val: Infinity, airline: "" },
    lnl: { val: Infinity, airline: "" },
    anf: [],
    pr: false,
  };

  for (let i = 0; i < noOfTime; i++) {
    if (fligthData[i].Requirements.Airline.firstname_limit.Available == true) {
      if (
        rules.fnl.val < fligthData[i].Requirements.Airline.firstname_limit.limit
      ) {
        rules.fnl.val =
          fligthData[i].Requirements.Airline.firstname_limit.limit;
        rules.fnl.airline = fligthData[i].ValidatingAirline;
      }
    }

    if (fligthData[i].Requirements.Airline.lastname_limit.Available == true) {
      if (
        rules.lnl.val < fligthData[i].Requirements.Airline.lastname_limit.limit
      ) {
        rules.lnl.val = fligthData[i].Requirements.Airline.lastname_limit.limit;
        rules.lnl.airline = fligthData[i].ValidatingAirline;
      }
    }

    if (fligthData[i].Requirements.Airline.nameformat.Available == true) {
      rules.anf.push({
        conditon: fligthData[i].Requirements.Airline.nameformat.condition,
        airline: fligthData[i].ValidatingAirline,
      });
    }

    if (fligthData[i].Requirements.Airline.nameformat.Available == false) {
      // default firstname as last name
      rules.anf.push({
        conditon: "",
        airline: fligthData[i].ValidatingAirline,
      });
    }

    if (rules.pr == true) {
      continue;
    }
    rules.pr = fligthData[i].Requirements.IsPassportRequiredAtBook;
  }

  if (rules.fnl.val == Infinity) {
    rules.fnl.val = 50;
    rules.fnl.airline = fligthData[0].ValidatingAirline;
  }
  if (rules.lnl.val == Infinity) {
    rules.lnl.val = 50;
    rules.lnl.airline = fligthData[0].ValidatingAirline;
  }

  return rules;
};

export let getBarData = (fligthData, noOfTime, mode, headingVals) => {
  let JourneyTabsVal = [];

  for (let i = 0; i < noOfTime; i++) {
    if (mode == 1) {
      let barVal = fligthData[i].Segments.map((item) => {
        return {
          BarValue: `${item.Origin.Airport.AirportCode} - ${item.Destination.Airport.AirportCode}`,
          TabValue: {
            headingVal: headingVals[i],
            AirlineName: item.Airline.AirlineName,
            AirlineLogo: item.Airline.AirlineLogo,
            AirlineCode_Number: `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}`,
            AirlineCraft: item.ItenaryDetails.Craft,
            Cities: `${item.Origin.Airport.CityName} - ${item.Destination.Airport.CityName}`,
          },
        };
      });

      JourneyTabsVal.push(barVal);
    } else {
      let j = 0;
      fligthData[i].Segments.forEach((element) => {
        let headingval = headingVals[j];
        let barVal = element.map((item) => {
          return {
            BarValue: `${item.Origin.Airport.AirportCode} - ${item.Destination.Airport.AirportCode}`,
            TabValue: {
              headingVal: headingval,
              AirlineName: item.Airline.AirlineName,
              AirlineLogo: item.Airline.AirlineLogo,
              AirlineCode_Number: `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}`,
              AirlineCraft: item.ItenaryDetails.Craft,
              Cities: `${item.Origin.Airport.CityName} - ${item.Destination.Airport.CityName}`,
            },
          };
        });
        j++;
        JourneyTabsVal.push(barVal);
      });
    }
  }

  return JourneyTabsVal;
};

export let getBagBarData = (
  fligthData,
  noOfTime,
  mode,
  headingVals,
  currenti
) => {
  console.log(
    "fligthData, noOfTime, mode , headingVals is : ",
    fligthData,
    noOfTime,
    mode,
    headingVals
  );

  let JourneyTabsVal = [];
  if (mode == 1) {
    for (let i = 0; i < noOfTime; i++) {
      JourneyTabsVal.push({
        BarValue: `${fligthData[i].Segments[0].Origin.Airport.CityName} - ${
          fligthData[i].Segments[fligthData[i].Segments.length - 1].Destination
            .Airport.CityName
        }`,
        TabValue: {
          headingVal: headingVals,
          AirlineName: fligthData[i].Segments.map((item, index, arr) => {
            return index !== arr.length - 1
              ? `${item.Airline.AirlineName}, `
              : item.Airline.AirlineName;
          }).join(""),
          AirlineLogo: fligthData[i].Segments.map((item) => {
            return {
              name: item.Airline.AirlineName,
              logo: item.Airline.AirlineLogo,
            };
          }),
          AirlineCode_Number: fligthData[i].Segments.map((item, index, arr) => {
            return index !== arr.length - 1
              ? `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}, ` // Add comma if not last
              : `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}`; // No comma for last one
          }).join(""),
          AirlineCraft: fligthData[i].Segments[0].ItenaryDetails.Craft,
          Cities: `${fligthData[i].Segments[0].Origin.Airport.CityName} - ${
            fligthData[i].Segments[fligthData[i].Segments.length - 1]
              .Destination.Airport.CityName
          }`,
        },
      });
    }
  } else {
    JourneyTabsVal.push({
      BarValue: `${
        fligthData.Segments[currenti][0].Origin.Airport.CityName
      } - ${
        fligthData.Segments[currenti][fligthData.Segments[currenti].length - 1]
          .Destination.Airport.CityName
      }`,
      TabValue: {
        headingVal: headingVals[currenti],
        AirlineName: fligthData.Segments[currenti]
          .map((item, index, arr) => {
            return index !== arr.length - 1
              ? `${item.Airline.AirlineName}, `
              : item.Airline.AirlineName;
          })
          .join(""),
        AirlineLogo: fligthData.Segments[currenti].map((item) => {
          return {
            name: item.Airline.AirlineName,
            logo: item.Airline.AirlineLogo,
          };
        }),
        AirlineCode_Number: fligthData.Segments[currenti]
          .map((item, index, arr) => {
            return index !== arr.length - 1
              ? `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}, ` // Add comma if not last
              : `${item.Airline.OperatingCarrier} ${item.Airline.FlightNumber}`; // No comma for last one
          })
          .join(""),
        AirlineCraft: fligthData.Segments[currenti][0].ItenaryDetails.Craft,
        Cities: `${
          fligthData.Segments[currenti][0].Origin.Airport.CityName
        } - ${
          fligthData.Segments[currenti][
            fligthData.Segments[currenti].length - 1
          ].Destination.Airport.CityName
        }`,
      },
    });
  }

  return JourneyTabsVal;
};

export let getSeatInformation = (data) => {
  let info = {
    Paid: { hasPaidSeat: false, Min: -1, Max: Infinity },
    Free: { hasFreeSeat: false },
    ExtraLegRoom: { hasExtraLegRoom: false, Min: -1, Max: Infinity },
    ExitRow: { hasExitRow: false, Min: -1, Max: Infinity },
    NonRecline: { hasNonRecline: false, Min: -1, Max: Infinity },
    Recline: { hasRecline: false, Min: -1, Max: Infinity },
  };

  data.forEach((element) => {
    element.Seats.forEach((element) => {
      // Check for free seat
      if (element.Price == 0) {
        if (info.Free.hasFreeSeat == false) {
          info.Free.hasFreeSeat = true;
        }
      }

      // Check for Paid seat
      if (element.Price > 0) {
        if (info.Paid.hasPaidSeat == false) {
          info.Paid.hasPaidSeat = true;
          info.Paid.Min = element.Price;
          info.Paid.Max = element.Price;
        } else {
          info.Paid.Min = Math.min(info.Paid.Min, element.Price);
          info.Paid.Max = Math.max(info.Paid.Max, element.Price);
        }
      }

      // check for extra leg room
      if (element.Type.length == 0) {
        return;
      }

      let types = element.Type;

      // Check if the types array contains "Extra Leg Room"
      if (types.includes("Extra Leg Room")) {
        if (info.ExtraLegRoom.hasExtraLegRoom == false) {
          info.ExtraLegRoom.hasExtraLegRoom = true;
          info.ExtraLegRoom.Min = element.Price;
          info.ExtraLegRoom.Max = element.Price;
        } else {
          info.ExtraLegRoom.Min = Math.min(
            info.ExtraLegRoom.Min,
            element.Price
          );
          info.ExtraLegRoom.Max = Math.max(
            info.ExtraLegRoom.Max,
            element.Price
          );
        }
      }

      // Check if the types array contains "Exit Row"
      if (
        types.includes("Exit Row") ||
        types.some((type) => type.toLowerCase().includes("exit row"))
      ) {
        if (info.ExitRow.hasExitRow == false) {
          info.ExitRow.hasExitRow = true;
          info.ExitRow.Min = element.Price;
          info.ExitRow.Max = element.Price;
        } else {
          info.ExitRow.Min = Math.min(info.ExitRow.Min, element.Price);
          info.ExitRow.Max = Math.max(info.ExitRow.Max, element.Price);
        }
      }

      // Check if the types array contains "Non Recline"
      if (types.includes("Non Recline")) {
        if (info.NonRecline.hasNonRecline == false) {
          info.NonRecline.hasNonRecline = true;
          info.NonRecline.Min = element.Price;
          info.NonRecline.Max = element.Price;
        } else {
          info.NonRecline.Min = Math.min(info.NonRecline.Min, element.Price);
          info.NonRecline.Max = Math.max(info.NonRecline.Max, element.Price);
        }
      }

      // Check if the types array contains "Recline"
      if (
        types.includes("Recline") ||
        types.some(
          (type) =>
            type.toLowerCase().includes("recline") &&
            !type.toLowerCase().includes("non")
        )
      ) {
        if (info.Recline.hasRecline == false) {
          info.Recline.hasRecline = true;
          info.Recline.Min = element.Price;
          info.Recline.Max = element.Price;
        } else {
          info.Recline.Min = Math.min(info.Recline.Min, element.Price);
          info.Recline.Max = Math.max(info.Recline.Max, element.Price);
        }
      }
    });
  });

  return info;
};

export let getSeatInfo = (seatInfo, needPrice) => {
  let SeatNo = seatInfo.Code;

  let SeatPosition = seatInfo.SeatPosition;
  if (
    seatInfo.AvailabilityStatus == "Occupied" ||
    seatInfo.AvailabilityStatus == "CheckdIn" ||
    seatInfo.AvailabilityStatus == "NotSet"
  ) {
    return [
      `${SeatNo} ${
        SeatPosition == undefined ? "" : `| ${SeatPosition} Seat`
      } - Occupied`,
    ];
  }

  // Determine the price label
  const Price = seatInfo.Price === 0 ? "Free" : seatInfo.Price;

  // Construct the string of types
  const Types =
    seatInfo.Type.length === 0 ? "" : ` - ${seatInfo.Type.join(" | ")}`; // Join the types with " | " separator

  // Return the formatted tooltip

  if (needPrice == true) {
    return [
      `${SeatNo} ${
        SeatPosition == undefined ? "" : `| ${SeatPosition} Seat`
      } ${Types} `,
      Price,
    ];
  } else {
    return `${SeatNo} ${
      SeatPosition == undefined ? "" : `| ${SeatPosition} Seat`
    } ${Types} `;
  }
};
export let getSeatsDataStruc = (AncellaryDataMain, NoOfTimes, BarData) => {
  console.log("AncellaryDataMain , BarDatais : ", AncellaryDataMain, BarData);

  let ldtr = [];

  for (
    let i = 0;
    i < (NoOfTimes == null ? AncellaryDataMain.length : NoOfTimes);
    i++
  ) {
    let SeatsData =
      NoOfTimes == null
        ? AncellaryDataMain[i].data.Seats.SeatsAvaialble == false
          ? []
          : AncellaryDataMain[i].data.Seats.data
        : AncellaryDataMain.data.Seats[i].SeatsAvaialble == false
        ? []
        : AncellaryDataMain.data.Seats[i].data;

    console.log("SeatsData is : ", SeatsData);

    let SeatTabData = BarData[i].map((item, id) => {
      console.log("item here is : ", item);

      if (SeatsData.length == 0) {
        return {
          BarData: item.BarValue,
          TabValue: item.TabValue,
          error: true,
          errorMessage: {
            heading: `Seat Selection is Not Available for ${item.BarValue}`,
            Message: "",
          },
        };
      } else {
        let FilteredData = SeatsData.filter((itemm, id) => {
          return itemm.JourneyName == item.BarValue;
        });

        if (
          FilteredData.length == 0 ||
          FilteredData[0].SegmentsSeatAvailable == false
        ) {
          return {
            BarData: item.BarValue,
            TabValue: item.TabValue,
            error: true,
            errorMessage: {
              heading: `Seat Selection is Not Available for ${item.BarValue}`,
              Message: "",
            },
          };
        } else {
          return {
            error: false,
            errorMessage: "",
            BarData: item.BarValue,
            TabValue: item.TabValue,
            SeactionTabData: {
              ...FilteredData[0],
              InformationSection: getSeatInformation(FilteredData[0].Data),
            },
          };
        }
      }
    });

    ldtr.push(SeatTabData);
  }

  return ldtr;
};

export let getMealsDataStruc = (
  AncellaryDataMain,
  noOftimes,
  BarData,
  selectedAncellaries
) => {
  let ldtr = [];

  for (
    let i = 0;
    i < (noOftimes == null ? AncellaryDataMain.length : noOftimes);
    i++
  ) {
    let MealsData =
      noOftimes == null
        ? AncellaryDataMain[i].data.Meals[0].MealsAvaialble == false
          ? []
          : AncellaryDataMain[i].data.Meals[0].data
        : AncellaryDataMain.data.Meals[i].MealsAvaialble == false
        ? []
        : AncellaryDataMain.data.Meals[i].data;

    // here get key name for tbo meals
    let keyname =
      noOftimes == null
        ? AncellaryDataMain[i].data.Meals[0].MealsAvaialble == false
          ? null
          : AncellaryDataMain[i].data.Meals[0].keyname
        : AncellaryDataMain.data.Meals[i].MealsAvaialble == false
        ? null
        : AncellaryDataMain.data.Meals[i].keyname;

    let MealTabData = BarData[i].map((item, id) => {
      if (MealsData.length == 0) {
        return {
          BarData: item.BarValue,
          TabValue: item.TabValue,
          error: true,
          errorMessage: {
            heading: `Meal Selection is Not Available for ${item.BarValue}`,
            Message:
              "You may still choose to purchase the meal during the flight",
          },
        };
      } else {
        let FilteredData = MealsData.filter((itemm, id) => {
          return itemm.JourneyName == item.BarValue;
        });

        if (
          FilteredData.length == 0 ||
          FilteredData[0].SegmentsMealAvailable == false
        ) {
          return {
            BarData: item.BarValue,
            TabValue: item.TabValue,
            error: true,
            errorMessage: {
              heading: `Meal Selection is Not Available for ${item.BarValue}`,
              Message:
                "You may still choose to purchase the meal during the flight",
            },
          };
        } else {
          if (keyname == null || keyname == undefined) {
            let seenNames = new Set();
            let SeactionTabData = FilteredData[0].Data.filter(
              (item) =>
                item.Code !== "NOML" &&
                item.Code !== "BNML" &&
                item.Code !== "CNML"
            ).filter((item) => {
              const lowercasedName = item.Name.toLowerCase();
              if (seenNames.has(lowercasedName)) {
                return false;
              }
              seenNames.add(lowercasedName);
              return true;
            });

            return {
              error: false,
              errorMessage: "",
              BarData: item.BarValue,
              TabValue: item.TabValue,
              SeactionTabData,
            };
          } else {
            let seenNames = new Set();
            let SeactionTabData = FilteredData[0].Data.filter(
              (item) =>
                item.Code !== "NOML" &&
                item.Code !== "BNML" &&
                item.Code !== "CNML"
            ).filter((item) => {
              const lowercasedName = item.Name.toLowerCase();
              if (seenNames.has(lowercasedName)) {
                return false;
              }
              seenNames.add(lowercasedName);
              return true;
            });

            return {
              keyname,
              error: false,
              errorMessage: "",
              BarData: item.BarValue,
              TabValue: item.TabValue,
              SeactionTabData,
            };
          }
        }
      }
    });

    ldtr.push(MealTabData);
  }

  // set the quantity and travellers of meals if they were selected earlier
  if (selectedAncellaries.Meals.length > 0) {
    selectedAncellaries.Meals.forEach((Mainelement) => {
      ldtr[Mainelement.id1][Mainelement.id2].SeactionTabData.forEach(
        (element) => {
          if (element.Code == Mainelement.Code) {
            let hasTraveller = element.Traveller !== undefined;

            if (hasTraveller == true) {
              element.Traveller.push(Mainelement.Traveller);
              element.Quantity++;
            } else {
              element.Traveller = [];
              element.Traveller.push(Mainelement.Traveller);
              element.Quantity = Mainelement.Quantity;
            }
          }
        }
      );
    });
  }

  return ldtr;
};

export let getBagsDataStruc = (
  AncellaryDataMain,
  BarData,
  noOfTimes,
  bagAvailableForAdd,
  selectedAncellaries
) => {
  console.log("BarData her ein bags is : ", BarData);

  let ldtr = [];

  for (
    let i = 0;
    i < (noOfTimes == null ? AncellaryDataMain.length : noOfTimes);
    i++
  ) {
    let BagsData =
      noOfTimes == null
        ? AncellaryDataMain[i].data.Bags[0].BagsAvailable == false
          ? []
          : AncellaryDataMain[i].data.Bags[0].data
        : AncellaryDataMain.data.Bags[i].BagsAvailable == false
        ? []
        : AncellaryDataMain.data.Bags[i].data;

    let BagsTabData = BarData[i].map((item, id) => {
      if (BagsData.length == 0 || bagAvailableForAdd[i] == false) {
        return {
          BarData: item.BarValue,
          TabValue: item.TabValue,
          error: true,
          errorMessage: {
            heading: `Bag Selection is Not Available for ${item.BarValue}`,
            Message:
              "You may still choose to purchase the extra bag from rthe airport",
          },
        };
      } else {
        let FilteredData = BagsData;

        if (
          FilteredData.length == 0 ||
          FilteredData[0].SegmentsBagAvailable == false
        ) {
          return {
            BarData: item.BarValue,
            TabValue: item.TabValue,
            error: true,
            errorMessage: {
              heading: `Bag Selection is Not Available for ${item.BarValue}`,
              Message:
                "You may still choose to purchase the extra bag from rthe airport",
            },
          };
        } else {
          return {
            error: false,
            errorMessage: "",
            BarData: item.BarValue,
            TabValue: item.TabValue,
            SeactionTabData: FilteredData[0],
          };
        }
      }
    });

    ldtr.push(BagsTabData);
  }

  // set the quantity and travellers of bags if they were selected earlier
  if (selectedAncellaries.Bags.length > 0) {
    selectedAncellaries.Bags.forEach((Mainelement) => {
      ldtr[Mainelement.id1][Mainelement.id2].SeactionTabData.Data.forEach(
        (element) => {
          if (element.Weight == Mainelement.Weight) {
            let hasTraveller = element.Traveller !== undefined;

            if (hasTraveller == true) {
              element.Traveller.push(Mainelement.Traveller);
              element.Quantity++;
            } else {
              element.Traveller = [];
              element.Traveller.push(Mainelement.Traveller);
              element.Quantity = Mainelement.Quantity;
            }
          }
        }
      );
    });
  }

  return ldtr;
};

let fillSegmentAncellaries = (type, no) => {
  if (no === 1) {
    return [
      {
        [`${type}Requested`]: null,
      },
    ];
  } else if (no === 2) {
    return [
      {
        [`${type}Requested`]: null,
      },
      {
        [`${type}Requested`]: null,
      },
    ];
  } else if (no === 3) {
    return [
      {
        [`${type}Requested`]: null,
      },
      {
        [`${type}Requested`]: null,
      },
      {
        [`${type}Requested`]: null,
      },
    ];
  }
};

export let getancellariesStructure = (typeOfData, noofsegment) => {
  console.log("typeOfData, noofsegment is : ", typeOfData, noofsegment);

  switch (typeOfData) {
    case 1:
      return {
        seats: [fillSegmentAncellaries("seat", noofsegment[0])],
        meals: [fillSegmentAncellaries("meal", noofsegment[0])],
        bags: [
          [
            {
              bagRequested: null,
            },
          ],
        ],
      };

    case 2:
      return {
        seats: [
          fillSegmentAncellaries("seat", noofsegment[0]),
          fillSegmentAncellaries("seat", noofsegment[1]),
        ],
        meals: [
          fillSegmentAncellaries("meal", noofsegment[0]),
          fillSegmentAncellaries("meal", noofsegment[1]),
        ],
        bags: [
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
        ],
      };

    case 3:
      return {
        seats: [
          fillSegmentAncellaries("seat", noofsegment[0]),
          fillSegmentAncellaries("seat", noofsegment[1]),
          fillSegmentAncellaries("seat", noofsegment[2]),
        ],
        meals: [
          fillSegmentAncellaries("meal", noofsegment[0]),
          fillSegmentAncellaries("meal", noofsegment[1]),
          fillSegmentAncellaries("meal", noofsegment[2]),
        ],
        bags: [
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
        ],
      };

    case 4:
      return {
        seats: [
          fillSegmentAncellaries("seat", noofsegment[0]),
          fillSegmentAncellaries("seat", noofsegment[1]),
          fillSegmentAncellaries("seat", noofsegment[2]),
          ,
          fillSegmentAncellaries("seat", noofsegment[3]),
        ],
        meals: [
          fillSegmentAncellaries("meal", noofsegment[0]),
          fillSegmentAncellaries("meal", noofsegment[1]),
          fillSegmentAncellaries("meal", noofsegment[2]),
          fillSegmentAncellaries("meal", noofsegment[3]),
        ],
        bags: [
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
          [
            {
              bagRequested: null,
            },
          ],
        ],
      };

    default:
      break;
  }
};

export function generateDropdownYears(type, fareType) {
  const currentYear = new Date().getFullYear();
  let minYear, maxYear;

  if (fareType === "Senior Citizen") {
    // For senior citizens, generate years starting from 60 years and older
    minYear = currentYear - 150; // Consider a wide range of ages (e.g., 150 years ago)
    maxYear = currentYear - 60; // Minimum age is 60 years
  } else if (type === "ADT") {
    minYear = currentYear - 150; // 150 years ago
    maxYear = currentYear - 12; // 12 years ago
  } else if (type === "CHD") {
    minYear = currentYear - 12; // 12 years ago
    maxYear = currentYear - 2; // 2 years ago
  } else if (type === "INF") {
    minYear = currentYear - 2; // 2 years ago
    maxYear = currentYear; // Current year
  }

  // Generate an array from minYear to maxYear and return
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => minYear + i
  );

  return years.reverse(); // Return years in descending order
}


// Validators

export let validateTitle = (title) => {
  if (title === "") {
    return true;
  } else {
    return false;
  }
};

export const validateFName = (name, nameLimit) => {
  const nameRegex = /^[A-Za-z]+$/;

  if (!name) {
    return true;
  } else if (!nameRegex.test(name)) {
    return true;
  } else if (name.length > nameLimit.val) {
    return true;
  } else {
    return false;
  }
};
export const validateLName = (name, nameLimit) => {
  const nameRegex = /^[A-Za-z]+$/;

  if (name !== "") {
    if (!nameRegex.test(name)) {
      return true;
    } else if (name.length > nameLimit.val) {
      return true;
    } else {
      return false;
    }
  }
};

export const validateDob = (dob, type) => {
  const [day, month, year] = dob.split("-");

  if (dob === "Date-Month-Year") {
    return true;
  } else if (day === "Date") {
    return true;
  } else if (month === "Month") {
    return true;
  } else if (year === "Year") {
    return true;
  } else {
    try {
      const selectedDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();

      let minYear, maxYear;

      if (type === "ADT") {
        // Adults: 12 years or older
        minYear = today.getFullYear() - 100; // 100 years ago (e.g., 1900)
        maxYear = today.getFullYear() - 12; // 12 years ago
      } else if (type === "CHD") {
        // Children: 2 to 12 years old
        minYear = today.getFullYear() - 12; // 12 years ago
        maxYear = today.getFullYear() - 2; // 2 years ago
      } else if (type === "INF") {
        // Infants: Less than 2 years old
        minYear = today.getFullYear() - 2; // 2 years ago
        maxYear = today.getFullYear(); // Current year
      }

      // Check if the selectedDate is valid
      if (isNaN(selectedDate.getTime())) {
        return true;
      } else if (
        selectedDate.getFullYear() < minYear ||
        selectedDate.getFullYear() > maxYear
      ) {
        // Validate that the selected date is within the allowed range
        if (type === "ADT") {
          return true;
        } else if (type === "CHD") {
          return true;
        } else if (type === "INF") {
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      return true;
    }
  }
};

export const validatePassportNumber = (ppNo) => {
  if (!ppNo) {
    return true;
  } else {
    return false;
  }
};

export const validatePassportIssueDate = (PPIssue) => {
  const [day, month, year] = PPIssue.split("-");

  if (PPIssue === "Date-Month-Year") {
    return true;
  } else if (day === "Date") {
    return true;
  } else if (month === "Month") {
    return true;
  } else if (year === "Year") {
    return true;
  } else {
    try {
      const selectedDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();

      // Check if the date is valid
      if (isNaN(selectedDate.getTime())) {
        return true;
      }
      // Check if the date is in the future
      else if (selectedDate > today) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return true;
    }
  }
};

export const validatePassportExpiryDate = (PPExpiryDate, DateOfTravel) => {
  const [day, month, year] = PPExpiryDate.split("-");

  if (PPExpiryDate === "Date-Month-Year") {
    return true;
  } else if (day === "Date") {
    return true;
  } else if (month === "Month") {
    return true;
  } else if (year === "Year") {
    return true;
  } else {
    try {
      const selectedDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();

      // Check if the date is valid
      if (isNaN(selectedDate.getTime())) {
        return true;
      }
      // Check if the date is in the future
      else if (selectedDate <= today) {
        return true;
      } else {
        // Parse DateOfTravel
        const [travelDay, travelMonth, travelYear] = DateOfTravel.split("-");
        const travelDate = new Date(
          `${travelYear}-${travelMonth}-${travelDay}`
        );

        // Add 6 months to the travel date
        const sixMonthsAfterTravel = new Date(travelDate);
        sixMonthsAfterTravel.setMonth(sixMonthsAfterTravel.getMonth() + 6);

        // Check if the passport expiry is before six months from the date of travel
        if (selectedDate < sixMonthsAfterTravel) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      return true;
    }
  }
};

export const validatefrequentFlyerDetailsNumber = (fn) => {
  if (!fn) {
    return true;
  } else if (fn.length > 50) {
    return true;
  } else {
    return false;
  }
};

export const validatefrequentFlyerDetailsAirline = (fa) => {
  if (!fa) {
    return true;
  } else if (fa.length > 2) {
    return true;
  } else {
    return false;
  }
};

export const validateEmail = (email) => {
  if (!email) {
    return true;
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  }
};

export const validatePhoneNumber = (phoneNo, CC, isValidphoneNo) => {
  if (!phoneNo) {
    return true;
  } else if (!isValidphoneNo(phoneNo, CC)) {
    return true;
  } else {
    return false;
  }
};

export const validateGSTCompanyName = (gstCompany) => {
  if (!gstCompany) {
    return true;
  } else if (gstCompany.length > 150) {
    return true;
  } else {
    return false;
  }
};

export const validateGSTNumber = (gstNumber) => {
  if (!gstNumber) {
    return true;
  } else if (gstNumber.length > 100) {
    return true;
  } else {
    return false;
  }
};

export const getNameFormatMessage = (airline, condition) => {
  switch (condition) {
    case "":
      return `As Per "${airline}'s Policy . Your Last name will be your first name`;
    case "fn/title":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / Title )`;
    case "fn/fnu":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / FNU )`;
    case "fn/fnu title":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / FNU Title )`;
    case "fn/fl title":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / FL Title )`;
    case "fn/fn":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / First Name )`;
    case "fn/UNKNOWN":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / UNKNOWN )`;

    case "fn/fn title":
      return `As Per "${airline}'s Policy . Your Last name will be (First name / First name Title )`;
    case "lnu/fn":
      return `As Per "${airline}'s Policy . Your Last name will be (LNU / First name )`;
    case "lnu/fn title":
      return `As Per "${airline}'s Policy . Your Last name will be (LNU / First name Title)`;
  }
};

export const convertNullsToEmptyStrings = (data) => {
  if (data === null) {
    return "";
  }

  if (Array.isArray(data)) {
    return data.map(convertNullsToEmptyStrings);
  }

  if (typeof data === "object" && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = convertNullsToEmptyStrings(data[key]);
      return acc;
    }, {});
  }

  return data;
};

export let getTheSelectionBarData = (activeSection, TabsData) => {
  let AncellariesTab =
    TabsData[
      activeSection == "Seats"
        ? "SeatTabs"
        : activeSection == "Meals"
        ? "MealTabs"
        : "BagTabs"
    ];

  let finalitr = [];

  AncellariesTab.forEach((element) => {
    let itr = [];
    element.forEach((element) => {
      if (activeSection == "Baggage") {
        let dd = [];

        let splitted = element.BarData.split(" - ");

        dd.push(splitted[0]);
        dd.push(splitted[1]);

        finalitr.push(dd);
      } else {
        itr.push(element.BarData);
      }
    });

    if (activeSection !== "Baggage") {
      finalitr.push(itr);
    }
  });

  console.log("finalitr is : ", finalitr);

  return finalitr;
};

export function extractFirstDate(queryString) {
  // Regular expression to match a date in the format dd-mm-yyyy
  const datePattern = /\d{2}-\d{2}-\d{4}/;

  // Find the 'itr' parameter
  const itrParam = queryString
    .split("&")
    .find((param) => param.startsWith("itr="));

  if (itrParam) {
    // Extract the first date from 'itr' value
    const match = itrParam.match(datePattern);
    return match ? match[0] : null;
  }

  return null; // Return null if no date found
}
