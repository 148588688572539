import React, { useState, useRef, useEffect } from "react";
import "./Plane.css";
import images from "../../../../../../Utils/importImages";
import SeatToolTip from "./SeatToolTip";
import Seat from "./Seat";
import SeatRow from "./SeatRow";
import AlphabetRow from "./AlphabetRow";
import { setnextAncellaryTravellerOnLoad } from "../../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import { useDispatch } from "react-redux";

function Plane({ Theme, TopRow, marginTop, Seats  , currentPassenger , onChanegCurrentPassenger , nextUnselectedTraveller}) {


  return (
    <div className="plane-layout" style={{ marginTop: marginTop }}>
      <div className="frame-268">
        <div className="frame-268-1">
          <div className="frame-268-2">
            <div className="frame-268-3">
              <div className="exit-row-default">
                <img src={images["plane-exit-arrow-left.svg"]} alt="" />
                <img src={images["plane-exit-arrow-right.svg"]} alt="" />
              </div>
          
              <AlphabetRow Theme={Theme} RowData={TopRow} />

              {
                Seats.map((item  ,  id) => {
                  
                  return <SeatRow currentPassenger={currentPassenger} onChanegCurrentPassenger={onChanegCurrentPassenger} Theme={Theme} Data={item.Seats} maxLength={TopRow.length} nextUnselectedTraveller={nextUnselectedTraveller}/>
                 
                })
              }
                

                <AlphabetRow Theme={Theme} RowData={TopRow} />

              <div className="exit-row-default">
                <img src={images["plane-exit-arrow-left.svg"]} alt="" />
                <img src={images["plane-exit-arrow-right.svg"]} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img
          className="frame-268-5"
          src={images["frame-268@2x.png"]}
          alt="Frame 268"
        />
        <img
          className="frame-269"
          src={images["frame-269@2x.png"]}
          alt="Frame 269"
        />
      </div>
    </div>
  );
}

export default Plane;
