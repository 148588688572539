import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Header from "../Pages/Components/Headers/Header";
import Footer from "../Pages/Components/Footers/Footer";
import ResultPage from "../Pages/ResultPages/Flights/ResultPage";
import HomePage from "../Pages/HomePage/HomePage";
import ReviewPage from "../Pages/ReviewPages/Flights/ReviewPage";

const router = createBrowserRouter([
  // Home Page
  {
    path: "/",
    exact: true,
    element: <HomePage />,
  },
  {
    path: "/flights",
    exact: true,
    element: <HomePage type={"flights"} />,
  },
  {
    path: "/hotels",
    exact: true,
    element: <HomePage type={"hotels"} />,
  },
  {
    path: "/packages",
    exact: true,
    element: <HomePage type={"packages"} />,
  },

  // Result Page
  {
    path: "/flights/results",
    exact: true,
    element: (
      <>
        {/* <Header /> */}
        <ResultPage />
        <Footer />
      </>
    ),
  },
  // Result Page
  {
    path: "/flights/review-details",
    exact: true,
    element: (
      <>
        <Header />
        <ReviewPage />
        <Footer />
      </>
    ),
  },
]);

export default router;
