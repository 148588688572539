import React from "react";
import "./LoadingScreen.css";

export default function LoadingScreen({Theme , Message}) {
  return (
    <>

      <div className="loading-container">
      <div className={`loading-message ${Theme}_regular font18`}>
        <p>{Message}</p>
      </div>
      <div className="loading-message">
      </div>
      <div className="loading-message">
      </div>
      </div>

    </>
  );
}
