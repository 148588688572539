import React from "react";
import "./Seat.css";
import images from "../../../../../../Utils/importImages";
import { useDispatch } from "react-redux";
import { setSelectTheSeat } from "../../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

function Seat({ item, id, Theme, RowNo, ToolTiipData , isSeatSelected , selectiondata ,  currentPassenger,
  onChanegCurrentPassenger , nextUnselectedTraveller , ActiveCode , classNameOfSeat}) {

  let dispatch = useDispatch();

  const getClass = (contentName) => {
    // Normalize the input to lower case and trim any extra spaces
    const normalizedContent = contentName.toLowerCase().trim();
  
    // Check if the content exactly matches "extra leg room"
    if (normalizedContent.includes("extra leg room")) {
      return "extraLegRoom";
    }
  
    // Check if the content includes "exit row"
    if (normalizedContent.includes("exit row")) {
      return "exitrow";
    }
  
    // Check if the content includes "non recline"
    if (normalizedContent.includes("non recline")) {
      return "nonrecline";
    }
  
    // Check for "recline" but ensure it doesn't include "non" before it
    if (normalizedContent.includes("recline") && !normalizedContent.includes("non")) {
      return "recline";
    }
  
    return null;

  };
  

  if (item.SeatNo === "0" && item.RowNo == null) {
    return (
      <div
        className={`seat-row-no ${Theme}_regular font12 color_deep-ocean`}
        onClick={null}
      >
        {RowNo}
      </div>
    );
  }

  let handleSeatClick = () => {
    
    dispatch(setSelectTheSeat({data : item , travellersIndex : currentPassenger}));

    // onChanegCurrentPassenger(nextUnselectedTraveller == null ? currentPassenger : currentPassenger)
    
  }

  return (

    <div
      key={id}
      style={
        item.AvailabilityStatus === "Space" ? { visibility: "hidden" } : {}
      }
      className={`main-seat ${Theme}_regular font12 color_deep-ocean seat-wrapper ${(item.AvailabilityStatus == "Occupied" ||
        item.AvailabilityStatus == "NotSet" ||
        item.AvailabilityStatus == "CheckdIn") && "Occupied"} ${ActiveCode && "isActiveSeat"}`}
      onClick={() => (item.AvailabilityStatus == "Occupied" ||
        item.AvailabilityStatus == "NotSet" ||
        item.AvailabilityStatus == "CheckdIn") ? null : handleSeatClick()}
    >
      {
      (item.AvailabilityStatus == "Occupied" ||
      item.AvailabilityStatus == "NotSet" ||
      item.AvailabilityStatus == "CheckdIn") ? (
        <img src={images["occupied-seat-sign.svg"]} alt="oc" />
      ) : isSeatSelected == true ?  <div className="selected">{selectiondata}</div> :  (
        <>
          {item.Price == 0 && <div className="free"></div>}
          {item.Price != 0 && <div className="paid"></div>}
          {item.Type.length == 0
            ? ""
            : item.Type.map((item, id) => {
                let classname = getClass(item);

                if (classname !== null) {
                  return <div className={classname}></div>;
                }
              })}
        </>
      ) }

      <div className="seat-tooltip">
          <div className="seat-tooltip-wrapp">
            <div className={`${Theme}_regular font13 color_white`}>
              {ToolTiipData[0]}
            </div>
            <div
              className={`${Theme}_regular font14 color_${
                ToolTiipData[1] == "Free" ? "parrot-color" : "white"
              }`}
            >
              {ToolTiipData[1]}
            </div>
          </div>
     
      </div>
    </div>
  );
}

export default Seat;
