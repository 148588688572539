import React from "react";
import "./TravellerRow.css";
import images from "../../../../../Utils/importImages";
function TravellerRow({
  name,
  Theme,
  isSelected,
  isActive,
  Data,
  price,
  onTravellerSelection,
  RequestText,
  id,
  dd,
}) {
  console.log("dd is : ", dd);

  return (
    <div className="traveller-row" onClick={() => onTravellerSelection(id)}>
      <div className={`selection-bar ${isActive == true && "selected"}`}></div>
      <div className="traveller-initials">
        <div
          className={`${Theme}_regular font14 color_magic-potion text-inital-content`}
        >
          {`${name.split(" ")[0][0]}${
            name.split(" ")[1] && name.split(" ")[1][0]
              ? " " + name.split(" ")[1][0]
              : ""
          }`}
        </div>
      </div>
      <div className="traveller-row-content">
        <div
          className={`traveller-row-name ${Theme}_regular font16 color_gray-black`}
        >
          {name}
        </div>
        {isSelected == false ? (
          <div
            className={`traveller-row-data ${Theme}_semibold font14 color_pine-green`}
          >
            {RequestText}
          </div>
        ) : (
          <div className={`traveller-row-data font14 color_deep-ocean`}>
            <div className={`${Theme}_regular`}>
              {Data} &nbsp;-&nbsp;{" "}
              <span className={`${Theme}_semibold price`}>
                <img src={images["dark-rupee-small.svg"]} alt="rupee" />
                {price}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TravellerRow;
