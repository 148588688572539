import React, { useEffect, useRef, useState } from "react";
import "./TravellersPopup.css";
import {
  TravellerClasses,
  Travellers,
} from "../../../Constants/ConstantsFlights";

import { useDispatch, useSelector } from "react-redux";

import images from "../../../Utils/importImages";
import ErrorBar from "../../ErrorPages/ErrorBar/ErrorBar";

function TravellersPopup({ open, onClose, position, onClickClose , travellersData , classData , timeOutFunc , setTravellersAndClassesFunc , Theme}) {
  let dispatch = useDispatch();

  let [adultsToDull, setadultsToDull] = useState(9);
  let [ChildsToDull, setChildsToDull] = useState(6);
  let [InfantsToDull, setInfantsToDull] = useState(6);

  useEffect(() => {
    setadultsToDull(9 - travellersData?.Childs);
    setChildsToDull(9 - travellersData?.Adults);

    setInfantsToDull(travellersData?.Adults);
  }, [
    travellersData?.Adults,
    travellersData?.Childs,
    travellersData?.Infrants,
  ]);

  useEffect(() => {
    let timeoutId;

    if (travellersData?.TravellersAndClassesErrorIn === true) {
      // Set a timeout to dispatch the action after 3 seconds
      timeoutId = setTimeout(() => {
        dispatch(timeOutFunc());
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [travellersData?.TravellersAndClassesErrorIn]);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) {
    return null;
  }

  // #6060603b

  return (
    <div className="custom-popup travellersPopup" ref={popupRef}>
      <div
        style={{
          position: "absolute",
          zIndex: 10000,
          top: "30px",
          minWidth:"606px",
          minHeight:"335.58px",
          right: "0px",
          backgroundColor: "#fff",
          // borderColor: "#c6c7c5",
          border: "1.2px solid rgb(230,230,230)",
          // borderColor: "#e6e6e6"
        }}
      >
        <div className="traveller-class-overlay-proxima2 travellersPopup-screen">
          <div className="travellersPopup-frame-100">
            <div className="travellersPopup-frame-105">
              <div className="travellersPopup-frame-102">
                <div className="travellersPopup-frame-58-1">
                  <div className="travellersPopup-frame-57">
                    <div className="travellersPopup-frame-109">
                      <div className={`travellersPopup-adults ${Theme}_regular color_magic-potion font18`}>
                        Adults
                      </div>
                      <div className={`travellersPopup-above-12-yrs ${Theme}_regular color_storm-dust font13`}>
                        above 12 yrs
                      </div>
                    </div>
                  </div>
                  <div className="travellersPopup-frame-48">
                    {Travellers.adults.map((item, index) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              let obj = {
                                adults: item,
                                childs: travellersData?.Childs,
                                infants: travellersData?.Infrants,
                                classs: classData?.class,
                              };

                              dispatch(setTravellersAndClassesFunc(obj));
                            }}
                            className={
                              item === travellersData?.Adults
                                ? `travellersPopup-frame-103 ${Theme}`
                                : `travellersPopup-frame-10 ${Theme}`
                            }
                          >
                            <div
                              style={
                                item > adultsToDull
                                  ? { color: "#6060603b" }
                                  : {}
                              }
                              className={
                                item === travellersData?.Adults
                                  ? `travellersPopup-number-22 ${Theme}_regular color_white font16`
                                  : `travellersPopup-number ${Theme}_regular color_light-grayish-water font16`
                              }
                            >
                              {item}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="travellersPopup-frame-110">
                  <div className="travellersPopup-frame">
                    <div className="travellersPopup-frame-58">
                      <div className="travellersPopup-frame-109-1 travellersPopup-frame-109-6">
                        <div className={`travellersPopup-children ${Theme}_regular color_magic-potion font18`}>
                          Children
                        </div>
                        <div className={`travellersPopup-above-12-yrs ${Theme}_regular color_storm-dust font13`}>
                          2-12 yrs
                        </div>
                      </div>
                    </div>
                    <div className="travellersPopup-frame-4">
                      {Travellers.childs.map((item, index) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                let obj = {
                                  adults: travellersData?.Adults,
                                  childs: item,
                                  infants: travellersData?.Infrants,
                                  classs: classData?.class,
                                };

                                dispatch(setTravellersAndClassesFunc(obj));
                              }}
                              className={
                                item === travellersData?.Childs
                                  ? `travellersPopup-frame-103 ${Theme}`
                                  : `travellersPopup-frame-10 ${Theme}`
                              }
                            >
                              <div
                                style={
                                  item > ChildsToDull
                                    ? { color: "#6060603b" }
                                    : {}
                                }
                                className={
                                  item === travellersData?.Childs
                                    ? `travellersPopup-number-22 ${Theme}_regular color_white font16`
                                    : `travellersPopup-number ${Theme}_regular color_light-grayish-water font16`
                                }
                              >
                                {item}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="travellersPopup-frame">
                    <div className="travellersPopup-frame-58">
                      <div className="travellersPopup-frame-109-2 travellersPopup-frame-109-6">
                        <div className={`travellersPopup-infants ${Theme}_regular color_magic-potion font18`}>
                          Infants
                        </div>
                        <div className={`travellersPopup-below-2-yrs ${Theme}_regular color_storm-dust font13`}>
                          below 2 yrs
                        </div>
                      </div>
                    </div>
                    <div className="travellersPopup-frame-4">
                      {Travellers.infants.map((item, index) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                let obj = {
                                  adults: travellersData?.Adults,
                                  childs: travellersData?.Childs,
                                  infants: item,
                                  classs: classData?.class,
                                };

                                dispatch(setTravellersAndClassesFunc(obj));
                              }}
                              className={
                                item === travellersData?.Infrants
                                  ? `travellersPopup-frame-103 ${Theme}`
                                  : `travellersPopup-frame-10 ${Theme}`
                              }
                            >
                              <div
                                style={
                                  item > InfantsToDull
                                    ? { color: "#6060603b" }
                                    : {}
                                }
                                className={
                                  item === travellersData?.Infrants
                                    ? `travellersPopup-number-22 ${Theme}_regular color_white font16`
                                    : `travellersPopup-number ${Theme}_regular color_light-grayish-water font16`
                                }
                              >
                                {item}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="travellersPopup-frame-40">
              <div className="travellersPopup-frame-104">
                <div className="travellersPopup-frame-109-3 travellersPopup-frame-109-6">
                  <div className={`travellersPopup-choose-travel-class ${Theme}_regular color_magic-potion font18`}>
                    Choose Travel Class
                  </div>
                </div>
                <div className="travellersPopup-frame-109-4 travellersPopup-frame-109-6">
                  <div className="travellersPopup-frame-103-2 travellersPopup-frame-103-3">
                    <div className="travellersPopup-frame-102-1">
                      {TravellerClasses.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              let obj = {
                                adults: travellersData?.Adults,
                                childs: travellersData?.Childs,
                                infants: travellersData?.Infrants,
                                classs: item,
                              };

                              dispatch(setTravellersAndClassesFunc(obj));
                            }}
                            className={
                              classData?.class === item
                                ? `travellersPopup-frame-51 ${Theme}`
                                : `travellersPopup-frame-5 ${Theme}`
                            }
                          >
                            <div
                              className={
                                classData?.class === item
                                  ? `travellersPopup-place-1 ${Theme}_regular color_white font16`
                                  : `travellersPopup-business ${Theme}_regular color_storm-dust font16`
                              }
                            >
                              {item}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="travellersPopup-frame-109-5 travellersPopup-frame-109-6">
                {travellersData?.TravellersAndClassesErrorIn === true && (
                <ErrorBar Theme={Theme} Message={travellersData?.TravellersAndClassesErrorInMessage}/>
                )}
                <div
                  className={`travellersPopup-frame-20 ${Theme}`}
                  onClick={() => onClickClose()}
                >
                  <div className={`travellersPopup-apply ${Theme}_semibold color_white font16`}>
                    Apply
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravellersPopup;
