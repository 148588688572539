import React from "react";
import images from "../../../../Utils/importImages";
import "./ErrorMessageBar.css";


function ErrorMessageBar({ Message, Theme }) {
  return (
    <div className="error-message-bar">
      <img className="alert-error-msg" src={images["alert.svg"]} alt="alert" />
      <span className={`${Theme}_regular color_magic-potion font14`}>
        {Message}
      </span>
    </div>
  );
}

export default ErrorMessageBar;
